import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Modal } from "@src/land_ui/modal/modal";
import { Typography } from "@src/land_ui/typography/typography";
import { LiteYoutubeEmbed } from "react-lite-yt-embed";

interface TutorialModalProps {
    title?: string;
    trigger?: React.ReactElement;
    youtubeId: string;
    autoPlay?: boolean;
    buttonTitle?: string;
}

export function TutorialButton({
    title = "Tutorial",
    buttonTitle = "Watch Video Tutorial",
    trigger,
    youtubeId,
    autoPlay,
}: TutorialModalProps) {
    return (
        <Modal
            trigger={
                trigger || (
                    <Button variant="base">
                        <Typography
                            variant="span"
                            color="primary-500"
                            className="lui-flex lui-gap-2 lui-items-center"
                            weight="medium"
                        >
                            <Icon name="VideoRecorder" color="inherit" />

                            <span>{buttonTitle}</span>
                        </Typography>
                    </Button>
                )
            }
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content className="lui-p-6">
                <div className="lui-w-[700px] lui-overflow-hidden lui-rounded-2xl">
                    <LiteYoutubeEmbed
                        defaultPlay={autoPlay}
                        mute={false}
                        id={youtubeId}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
}
