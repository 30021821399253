import { Typography } from "@src/land_ui/typography/typography";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";
import { Input } from "@src/land_ui/input/input";
import { type LandColumnDef, Table } from "@src/land_ui/table/table";
import { type ParcelExportReadOnly, PricingReport } from "@src/orval/gen/model";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { useExportsList, usePricingList } from "@src/orval/gen/api";
import { useEffect, useState } from "react";
import type { ColumnFiltersState } from "@tanstack/react-table";
import { useForm } from "react-hook-form";
import { formatDatetime } from "@src/functions";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { PRICING_TOOL_FEATURE_FLAG } from "@src/constants";

export default function PricingTool() {
    const pricingToolEnabled = useFeatureFlagEnabled(PRICING_TOOL_FEATURE_FLAG);

    const columns: LandColumnDef<PricingReport>[] = [
        {
            id: "name",
            header: "Export Name",
            accessor: "export_name",
            sortBy: "alphanumeric",
        },
        {
            header: "Offer Type",
            accessor: "offer_type",
        },
        {
            header: "Properties Count",
            accessor: "properties_count",
            sortBy: "alphanumeric",
        },
        {
            header: "Created",
            cell: (row) => {
                return <>{formatDatetime(row.created_at)}</>;
            },
            sortBy: "datetime",
        },
        {
            header: "Status",
            accessor: "status",
        },
        {
            header: "Actions",
            cell: (_rowData) => {
                return (
                    <>
                        <ButtonIcon icon="Trash" color="red-700" />
                        <ButtonIcon icon="Pencil" />
                    </>
                );
            },
        },
    ];

    const { register, watch } = useForm();
    const nameFilter = watch("nameFilter");

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    useEffect(() => {
        setColumnFilters((_prev) => [
            {
                id: "name",
                value: nameFilter,
            },
        ]);
    }, [nameFilter]);

    const { data, isLoading } = usePricingList();
    return pricingToolEnabled ? (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <div className="lui-flex lui-flex-row lui-gap-3 lui-items-center">
                <div className="lui-flex lui-flex-col lui-flex-grow lui-gap-2">
                    <Typography size="xl" weight="bold">
                        Pricing Tool
                    </Typography>
                    <Typography size="sm" color="gray-700">
                        {data?.length || 0} Results
                    </Typography>
                </div>
                <TutorialButton
                    //TODO: Update youtubeId to real video
                    youtubeId="mMe2RdChevU"
                    title="How To Price A Campaign"
                    buttonTitle="How To Price A Campaign"
                />
                <div className="lui-w-72">
                    <Input
                        icon="Search"
                        placeholder="Search by export name..."
                        {...register("nameFilter")}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                data={data || []}
                isLoading={isLoading}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
            />
        </div>
    ) : (
        <PricingToolPlaceholder />
    );
}

function PricingToolPlaceholder() {
    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Pricing Tool</h5>
            </div>
            <div className="card-body">
                <strong>Coming Soon</strong>
            </div>
        </div>
    );
}
