import { useQueryParam } from "./useQueryParam";

export function useJumpToMapCoordinate() {
    const {
        value,
        setValue: setParcelParamValue,
        removeValue,
    } = useQueryParam<number[]>("coordinate", null);

    function setValue(v: number[]) {
        setParcelParamValue(v);
    }

    return {
        coordinate: value,
        setCoordinate: setValue,
        removeCoordinate: removeValue,
    };
}
