import { Typography } from "@src/land_ui/typography/typography";

interface CompingSectionContainerProps {
    title: string;
    children: React.ReactNode;
}
export function CompingSectionContainer({
    title,
    children,
}: CompingSectionContainerProps) {
    return (
        <div>
            <Typography size="sm" weight="medium" color="gray-900">
                {title}
            </Typography>
            <div className="lui-grid lui-grid-cols-1 lg:lui-grid-cols-3 lui-gap-5 lui-mt-2.5 lui-flex-grow">
                {children}
            </div>
        </div>
    );
}
