import { UserContext } from "@src/context";
import { formatCredits, logout } from "@src/functions";
import { type LandUser, useCurrentUser } from "@src/hooks/useUser";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { Icon, type IconNameType } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";
import { PurchaseCreditsModal } from "components";
import { type ReactNode, useContext, useState } from "react";
import { AppMenu } from "./AppMenu";
import { Popover } from "@src/land_ui/popover/popover";

interface AppLayoutProps {
    children: ReactNode;
}
export function AppLayout({ children }: AppLayoutProps) {
    const { balance } = useContext(UserContext);

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="lui-bg-gray-50">
            <div className="lui-sticky lui-top-0 lui-z-50 lui-bg-white">
                <div className="lui-min-h-[64px] lui-flex lui-justify-between lui-px-5 lui-items-center">
                    <div>
                        <AppMenu />
                    </div>

                    <div>
                        {/* <Button  icon="LandInsights"/> */}
                        <Button variant="base" href="/">
                            <Icon name="LandInsights" size="lg" color="primary-500" />
                        </Button>
                    </div>
                    <div className="lui-flex lui-items-center lui-gap-2 ">
                        <Button variant="base" onClick={() => setShowModal(true)}>
                            <span className="lui-bg-primary-50 lui-p-2 lui-rounded-full lui-h-8 lui-flex lui-items-center">
                                <Icon name="CoinIcon" size="sm" color="primary-500" />
                                <Typography
                                    size="sm"
                                    weight="medium"
                                    className="lui-pl-1.5"
                                    variant="span"
                                >
                                    {formatCredits(balance)}
                                </Typography>
                            </span>
                        </Button>

                        {showModal && (
                            // TODO(KM): Use PurchaseCreditsModal instead
                            // @ts-ignore
                            <PurchaseCreditsModal
                                onConfirmed={() => setShowModal(false)}
                                onDismissed={() => setShowModal(false)}
                            />
                        )}
                        <UserAvatarMenu />
                    </div>
                </div>
                <Divider className="lui-h-[1px]" />
            </div>
            <div className="">{children}</div>
        </div>
    );
}

interface UserAvatarMenuOption {
    name: string;
    onClick?: () => void;
    href?: string;
    icon: IconNameType;
    staffOnly?: boolean;
}

function UserAvatarMenu() {
    const { user } = useCurrentUser();

    const options: UserAvatarMenuOption[] = [
        {
            name: "Settings",
            onClick: () => {},
            href: "/home/settings",
            icon: "Tool",
        },
        {
            name: "Admin",
            icon: "Lifebuoy",
            href: "/home/admin",
            staffOnly: true,
        },
        {
            name: "Logout",
            onClick: logout,
            icon: "LogoutIcon",
        },
    ];

    return (
        <Popover
            trigger={
                <Button variant="base">
                    <span className="lui-w-8 lui-h-8 lui-rounded-full lui-bg-gray-500 lui-text-white lui-flex lui-items-center lui-justify-center lui-text-sm lui-font-semibold lui-uppercase">
                        {getUsernameAbbrev(user)}
                    </span>
                </Button>
            }
            placement="bottom-end"
        >
            {user?.id && (
                <div className="lui-flex lui-flex-col lui-py-2 lui-bg-white lui-rounded-[18px] lui-shadow-lg lui-max-w-[460px] lui-overflow-hidden">
                    <div className="lui-flex lui-flex-col lui-w-full">
                        <Typography variant="b" className="lui-py-2 lui-px-4">
                            {user.email}
                        </Typography>
                        <Divider />
                        {options
                            .filter((opt) => (opt.staffOnly ? user.is_staff : true))
                            .map((option) => (
                                <div
                                    key={option.name}
                                    className="lui-px-4 lui-py-2 hover:lui-bg-gray-100"
                                >
                                    <Button
                                        variant="base"
                                        icon={option.icon}
                                        className="lui-w-full lui-text-left"
                                        onClick={option.onClick}
                                        href={option.href}
                                    >
                                        {option.name}
                                    </Button>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </Popover>
    );
}

function getUsernameAbbrev(user: LandUser) {
    if (!user) {
        return "";
    }

    if (user.first_name || user.last_name) {
        return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`.toUpperCase();
    }

    return user.email.charAt(0).toUpperCase();
}
