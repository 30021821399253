import type { CompingReport } from "@src/orval/gen/model";
import { useState } from "react";
import { useReportsPartialUpdate } from "@src/orval/gen/api";
import { useDebouncedCallback } from "use-debounce";
import { Typography } from "@src/land_ui/typography/typography";
import { Icon } from "@src/land_ui/icon/icon";
import { Editor } from "@src/land_ui/editor/editor";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";

export function CompingReportNotes({ report }: { report: CompingReport }) {
    const [notes, setNotes] = useState(report.notes);
    const [dirty, setDirty] = useState(false);
    const onChange = (notes: string) => {
        setNotes(notes);
        setDirty(true);
        debouncedSave(notes);
    };
    const { mutate: updateReport } = useReportsPartialUpdate();
    const debouncedSave = useDebouncedCallback((notes) => {
        updateReport(
            {
                id: report.id,
                data: {
                    notes,
                },
            },
            {
                onSuccess: () => {
                    setDirty(false);
                },
            },
        );
    }, 500);

    return (
        <div className="lui-flex lui-flex-col lui-rounded-2xl lui-p-5 lui-bg-white">
            <div className="lui-flex lui-flex-row lui-items-center lui-justify-between lui-mb-5">
                <Typography size="md" weight="bold">
                    Notes
                </Typography>
                <TutorialButton youtubeId="0dT1qMg4Ueg" />
            </div>
            <Editor
                value={notes}
                onChange={onChange}
                placeholder="Add notes about Buy Price, Estimated sell, Road Access, Topography, Wetlands, Floodzone, or anything..."
            />
            <div className="lui-flex lui-flex-row lui-gap-1 lui-items-center lui-mt-2.5">
                <Icon
                    size="sm"
                    name="CheckCircle"
                    color={!dirty ? "primary-500" : "orange-700"}
                />
                <Typography size="sm" color="gray-700">
                    {!dirty ? "Notes are automatically saved" : "Saving..."}
                </Typography>
            </div>
        </div>
    );
}
