import { createElement, forwardRef, memo } from "react";
import { getColorClassName, type LuiColors } from "@src/land_ui/color/color";
import { clsx } from "clsx";

import "./typography.css";

export type TypographyWhitespace = "normal" | "pre" | "pre-wrap";

export type TypographyProps = {
    id?: string;
    children: React.ReactNode;
    weight?: "regular" | "medium" | "bold"; // 400, 500, 600
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
    color?: LuiColors;
    className?: string;
    variant?:
        | "div"

        // Add text tags
        | "p"
        | "span"
        | "i"
        | "b"

        // Add form tags
        | "label"

        // Add heading tags
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6"
        //  list
        | "ul"
        | "li"
        | "ol";
    whitespace?: TypographyWhitespace;
    style?: object;
};

/**
 * Typography component
 *  - Renders text with specified variant, size, weight and color
 * @example
 * <Typography variant="h1" weight="bold" size="4xl" color="gray-700">
 *    Heading 1
 * </Typography>
 */
export const Typography = memo(
    forwardRef<HTMLDivElement, TypographyProps>(
        (
            {
                id,
                className,
                children,
                whitespace,
                variant = "div",
                size = "md",
                weight = "regular",
                color = "gray-1000",
                style = null,
            },
            ref,
        ) => {
            return createElement(
                variant,
                {
                    id,
                    className: clsx(
                        className,
                        "lui-typography",
                        `lui-typography-${size}`,
                        `lui-typography-${weight}`,
                        { [`lui-whitespace-${whitespace}`]: whitespace },
                        getColorClassName(color),
                    ),
                    style,
                    ref,
                },
                children,
            );
        },
    ),
);
