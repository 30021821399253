import { zodResolver } from "@hookform/resolvers/zod";
import { type QueryObserverResult, useQueryClient } from "@tanstack/react-query";
import { bbox, combine, featureCollection } from "@turf/turf";
import { first, get, isArray, isNil, toString, trim } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";
import * as z from "zod";
import {
    SKIP_TRACE_PRICE_PREMIUM,
    SKIP_TRACE_PRICE_PREMIUM_DNC,
    SKIP_TRACE_PRICE_STANDARD,
} from "@src/constants";
import { fetchMapboxFeature, formatNumber } from "@src/functions";
import {
    INTEGER_PERCENT_VALIDATOR,
    POSITIVE_INTEGER_VALIDATOR,
    POSITIVE_NUMBER_VALIDATOR,
    US_ZIP_CODE_VALIDATOR,
    VALID_YEAR_VALIDATOR,
} from "@src/functions/validation_utils";
import { Accordion } from "@src/land_ui/accordion/accordion";
import { Autocomplete, useAutocomplete } from "@src/land_ui/autocomplete/autocomplete";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Input } from "@src/land_ui/input/input";
import { SelectionGroupFormController } from "@src/land_ui/selection_group/selection_group";
import { Sidebar, useSidebar } from "@src/land_ui/sidebar/sidebar";
import { Toggle } from "@src/land_ui/toggle_button/Toggle";
import { Typography } from "@src/land_ui/typography/typography";
import {
    autocompleteLookupRetrieve,
    autocompleteRetrieve,
    useParcelsSearchRetrieve,
} from "@src/orval/gen/api";
import {
    type AutocompleteLookupRetrieveParams,
    type AutocompleteRetrieveParams,
    type ListItem,
    type ParcelExportFormState,
    type ParcelSearchResponse,
    type ParcelsListParams,
    ReportTypeEnum,
    SizePreferenceEnum,
} from "@src/orval/gen/model";
import {
    type SearchResultOptions,
    useParcelViewerContext,
} from "@src/pages/parcel_viewer/context";
import {
    findFeatureOnMap,
    searchMapbox,
} from "@src/pages/parcel_viewer/controls/search";
import {
    type CountyOption,
    type SaveFilterParcelsListParams,
} from "@src/pages/parcel_viewer/types";
import { type FeaturePolygon, usePolygon } from "@src/components/map_tools/map_polygon";
import { TagList } from "@src/components/tag_list/tag_list";
import { MapFilterParcel } from "./map_filter_parcel_list";
import { useCountdown } from "usehooks-ts";
import clsx from "clsx";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";

const LARGE_SEARCH_WARNING_DELAY_SECONDS = 10;

const ListItemSchema = z.object({ id: z.string(), label: z.string() });
type ListItemSchemaType = z.infer<typeof ListItemSchema>;

// Map inferred Zod schema to Orval generated class due to type mismatch.
// Ref: https://github.com/colinhacks/zod/issues/43
function toListItem(item?: ListItemSchemaType): ListItem {
    return {
        id: item?.id || "",
        label: item?.label || "",
    };
}

function toListItemArray(items?: ListItemSchemaType[]): ListItem[] {
    return items?.map(toListItem) || [];
}

const PolygonGeometryEnum = z.enum(["Polygon", "MultiPolygon"]);

const FormSchema = z
    .object({
        // Non-field errors
        countyOrPolygonRequiredError: z.string().optional().nullable(),

        // Area of interest
        county: ListItemSchema.nullable(),
        polygons: z
            .array(
                z.object({
                    id: z.string(),
                    type: z.enum(["Feature"]).optional(),
                    properties: z.record(z.string(), z.string()).or(z.null()),
                    geometry: z.object({
                        coordinates: z
                            .array(z.array(z.array(z.array(z.number()))))
                            .or(z.array(z.array(z.array(z.number())))),
                        type: PolygonGeometryEnum,
                    }),
                }),
            )
            .optional(),

        // Parcel fields
        acresFrom: POSITIVE_NUMBER_VALIDATOR,
        acresTo: POSITIVE_NUMBER_VALIDATOR,
        excludeZipCode: US_ZIP_CODE_VALIDATOR, // autocomplete input field
        excludeZipCodes: z.array(z.string()),
        improvementPercentage: INTEGER_PERCENT_VALIDATOR,
        includedZipCodes: z.array(z.string()),
        landUseCodeList: z.array(ListItemSchema).nullable(),
        showExcludeZip: z.boolean().optional(), // UI state
        subdivisionList: z.array(z.string()).nullable(),
        zipCode: US_ZIP_CODE_VALIDATOR, // autocomplete input field
        zoningTypeList: z.array(z.string()).nullable(),

        // Owner fields
        excludeCorpOwner: z.boolean().optional(),
        excludeKeyword: z.string().optional().nullable(), // autocomplete input field
        excludeKeywords: z.array(z.string()),
        includeKeyword: z.string().optional().nullable(), // autocomplete input field
        includeKeywords: z.array(z.string()),
        interFamilyFlag: z.boolean().optional(),
        isDelinquent: z.boolean().nullable(),
        maxOwnershipLengthInMonths: POSITIVE_INTEGER_VALIDATOR,
        minOwnershipLengthInMonths: POSITIVE_INTEGER_VALIDATOR,
        outOfCountyOwner: z.boolean().optional(),
        outOfStateOwner: z.boolean().optional(),
        outOfZipOwner: z.boolean().optional(),

        // Assessor fields
        assessedImprovementValueFrom: POSITIVE_INTEGER_VALIDATOR,
        assessedImprovementValueTo: POSITIVE_INTEGER_VALIDATOR,
        assessedLandValueFrom: POSITIVE_INTEGER_VALIDATOR,
        assessedLandValueTo: POSITIVE_INTEGER_VALIDATOR,
        assessedTotalValueFrom: POSITIVE_INTEGER_VALIDATOR,
        assessedTotalValueTo: POSITIVE_INTEGER_VALIDATOR,
        delinquentSinceYearsAgo: POSITIVE_INTEGER_VALIDATOR,
        marketImprovementValueFrom: POSITIVE_INTEGER_VALIDATOR,
        marketImprovementValueTo: POSITIVE_INTEGER_VALIDATOR,
        marketLandValueFrom: POSITIVE_INTEGER_VALIDATOR,
        marketLandValueTo: POSITIVE_INTEGER_VALIDATOR,
        marketTotalValueFrom: POSITIVE_INTEGER_VALIDATOR,
        marketTotalValueTo: POSITIVE_INTEGER_VALIDATOR,

        // Structure fields
        aiVacant: z.boolean().optional(),
        maxTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        maxTotalStructureCount: POSITIVE_INTEGER_VALIDATOR,
        minTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        minTotalStructureCount: POSITIVE_INTEGER_VALIDATOR,

        // Sale fields
        currentSalePriceMin: POSITIVE_INTEGER_VALIDATOR,
        currentSalePriceMax: POSITIVE_INTEGER_VALIDATOR,
        currentSalePriceCodeList: z.array(ListItemSchema).optional().nullable(),
        currentSaleRecordingDateMin: VALID_YEAR_VALIDATOR,
        currentSaleRecordingDateMax: VALID_YEAR_VALIDATOR,
        currentSaleSeller1FullName: z.string().optional().nullable(), // autocomplete input field
        currentSaleSeller1FullNameList: z.array(z.string()).optional(),
        currentSaleDocumentTypeList: z.array(ListItemSchema).optional().nullable(),

        // Open Lien fields
        concurrentMtg1LoanAmtMin: POSITIVE_INTEGER_VALIDATOR,
        concurrentMtg1LoanAmtMax: POSITIVE_INTEGER_VALIDATOR,
        concurrentMtg1RecordingDateMin: VALID_YEAR_VALIDATOR,
        concurrentMtg1RecordingDateMax: VALID_YEAR_VALIDATOR,
        concurrentMtg1TypeFinancing: z.string().optional().nullable(), // autocomplete input field
        concurrentMtg1TypeFinancingList: z.array(ListItemSchema).optional().nullable(),
        concurrentMtg1LoanType: z.string().optional().nullable(), // autocomplete input field
        concurrentMtg1LoanTypeList: z.array(ListItemSchema).optional().nullable(),
        concurrentMtg1InterestRateMin: POSITIVE_NUMBER_VALIDATOR,
        concurrentMtg1InterestRateMax: POSITIVE_NUMBER_VALIDATOR,

        // Scrubbing fields
        excludeHOA: z.boolean().optional(),
        maxFloodCoverage: INTEGER_PERCENT_VALIDATOR,
        maxRoadFrontage: POSITIVE_INTEGER_VALIDATOR,
        maxWetlandCoverage: INTEGER_PERCENT_VALIDATOR,
        minRoadFrontage: POSITIVE_INTEGER_VALIDATOR,
        removeDuplicateParcels: z.boolean().optional(),
        removeLandLockedParcels: z.boolean().optional(),
        roadFrontageIncludeHighways: z.boolean(),
        roadFrontageIncludeLocalRoads: z.boolean(),
        roadFrontageIncludeNormalRoads: z.boolean(),

        // Skip Tracing
        deduplicatingType: z
            .enum([SizePreferenceEnum.smaller, SizePreferenceEnum.larger])
            .optional(),
        skipTracingType: z
            .enum([ReportTypeEnum.standard, ReportTypeEnum.premium])
            .nullable()
            .optional(),
        isScrubDnc: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
        const countyActive = data.county != null;
        const polygonActive = data.polygons?.length > 0;

        // Require county or polygon, but not both (mutually exclusive)
        if (countyActive === polygonActive) {
            addCountyOrPolygonRequiredError(ctx);
            return;
        }

        const formValid =
            data.acresFrom != null ||
            data.acresTo != null ||
            data.subdivisionList?.length > 0 ||
            data.includedZipCodes?.length > 0 ||
            data.excludeZipCodes?.length > 0;

        if (!formValid) {
            addAtLeastOneFieldRequiredError(ctx, [
                "acresFrom",
                "acresTo",
                "subdivisionList",
                "includedZipCodes",
                "excludeZipCodes",
            ]);
        }
    });

function addCountyOrPolygonRequiredError(ctx: z.RefinementCtx) {
    ctx.addIssue({
        message: "County or Polygon is required",
        code: z.ZodIssueCode.custom,
        path: ["countyOrPolygonRequiredError"],
    });
    addRequiredFieldErrors(ctx, ["county", "polygons"]);
}

function addAtLeastOneFieldRequiredError(ctx: z.RefinementCtx, fields: string[]) {
    const titles: string[] = fields.map((name) => fieldKeyToTitle[name] || name);
    const message = `At least one of the following fields is required: ${titles.join(
        ", ",
    )}`;
    ctx.addIssue({
        message,
        code: z.ZodIssueCode.custom,
        path: ["atLeastOneFieldRequiredError"],
    });
    addRequiredFieldErrors(ctx, fields);
}

function addRequiredFieldErrors(ctx: z.RefinementCtx, fields: string[]) {
    for (const field of fields) {
        ctx.addIssue({
            message: "This field is required",
            code: z.ZodIssueCode.custom,
            path: [field],
        });
    }
}

type FormSchemaType = z.infer<typeof FormSchema>;

const DEFAULT_FORM_VALUES: FormSchemaType = {
    // Area of interest
    county: null,
    polygons: [],

    // Parcel fields
    acresFrom: null,
    acresTo: null,
    excludeZipCodes: [],
    improvementPercentage: null,
    includedZipCodes: [],
    landUseCodeList: [],
    showExcludeZip: false,
    subdivisionList: [],
    zoningTypeList: [],

    // Owner fields
    excludeCorpOwner: false,
    excludeKeywords: [],
    includeKeywords: [],
    interFamilyFlag: false,
    isDelinquent: null,
    maxOwnershipLengthInMonths: null,
    minOwnershipLengthInMonths: null,
    outOfCountyOwner: false,
    outOfStateOwner: false,
    outOfZipOwner: false,

    // Assessor fields
    assessedImprovementValueFrom: null,
    assessedImprovementValueTo: null,
    assessedLandValueFrom: null,
    assessedLandValueTo: null,
    assessedTotalValueFrom: null,
    assessedTotalValueTo: null,
    delinquentSinceYearsAgo: null,
    marketImprovementValueFrom: null,
    marketImprovementValueTo: null,
    marketLandValueFrom: null,
    marketLandValueTo: null,
    marketTotalValueFrom: null,
    marketTotalValueTo: null,

    // Sale fields
    currentSalePriceMin: null,
    currentSalePriceMax: null,
    currentSalePriceCodeList: [],
    currentSaleRecordingDateMin: null,
    currentSaleRecordingDateMax: null,
    currentSaleSeller1FullName: null,
    currentSaleSeller1FullNameList: [],
    currentSaleDocumentTypeList: [],

    // Open Lien fields
    concurrentMtg1LoanAmtMin: null,
    concurrentMtg1LoanAmtMax: null,
    concurrentMtg1RecordingDateMin: null,
    concurrentMtg1RecordingDateMax: null,
    concurrentMtg1TypeFinancing: null,
    concurrentMtg1TypeFinancingList: [],
    concurrentMtg1LoanType: null,
    concurrentMtg1LoanTypeList: [],
    concurrentMtg1InterestRateMin: null,
    concurrentMtg1InterestRateMax: null,

    // Structure fields
    aiVacant: false,
    maxTotalSqFt: null,
    maxTotalStructureCount: null,
    minTotalSqFt: null,
    minTotalStructureCount: null,

    // Scrubbing fields
    excludeHOA: false,
    maxFloodCoverage: null,
    maxWetlandCoverage: null,
    minRoadFrontage: null,
    maxRoadFrontage: null,
    removeDuplicateParcels: false,
    removeLandLockedParcels: false,
    roadFrontageIncludeHighways: true,
    roadFrontageIncludeLocalRoads: true,
    roadFrontageIncludeNormalRoads: true,

    // Skip Tracing fields
    skipTracingType: null,
    deduplicatingType: SizePreferenceEnum.larger,
};

interface MapFilterProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function formatFeature(feature: any) {
    return {
        id: toString(feature.id),
        type: feature.type,
        properties: feature.properties,
        geometry: {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
        },
    };
}

const countyOrPolygonFields: (keyof FormSchemaType)[] = [
    "county",
    "polygons",
    "countyOrPolygonRequiredError",
];

export function MapFilter({ isOpen, setIsOpen }: MapFilterProps) {
    const map = useMap();
    const queryClient = useQueryClient();
    const { setSearchResult, savedList, setSavedList, isPolygonActive } =
        useParcelViewerContext();
    const [isParcelList, setIsParcelListHandler] = useState<boolean>(false);

    const defaultValues = useMemo((): FormSchemaType => {
        const searchFilters = savedList?.search_filters as SaveFilterParcelsListParams;
        if (!searchFilters) {
            return DEFAULT_FORM_VALUES;
        }

        const result: Record<string, any> = { ...DEFAULT_FORM_VALUES };

        // Set default value if field is non-null. Use short non-descript name
        // to prevent agressive line wrapping.
        const init = (
            k1: keyof SaveFilterParcelsListParams,
            k2: keyof FormSchemaType,
        ) => {
            if (!isNullOrEmpty(searchFilters[k1])) {
                result[k2] = searchFilters[k1];
            }
        };

        // Parcel fields
        init("acres_min", "acresFrom");
        init("acres_max", "acresTo");
        init("countyOption", "county");
        init("zips_exclude", "excludeZipCodes");
        init("improvement_percentage_max", "improvementPercentage");
        init("zips_include", "includedZipCodes");
        init("landUseCodeList", "landUseCodeList");
        init("polygons", "polygons");
        init("subdivision", "subdivisionList");
        init("zoning", "zoningTypeList");

        // Owner fields
        init("exclude_corp_owner", "excludeCorpOwner");
        init("exclude_keywords", "excludeKeywords");
        init("include_keywords", "includeKeywords");
        init("inter_family_flag", "interFamilyFlag");
        init("is_delinquent", "isDelinquent");
        init("ownership_length_max", "maxOwnershipLengthInMonths");
        init("ownership_length_min", "minOwnershipLengthInMonths");
        init("out_of_county_owner", "outOfCountyOwner");
        init("out_of_state_owner", "outOfStateOwner");
        init("out_of_zip_owner", "outOfZipOwner");

        // Assessor fields
        init("assessed_improvement_value_min", "assessedImprovementValueFrom");
        init("assessed_improvement_value_max", "assessedImprovementValueTo");
        init("assessed_land_value_min", "assessedLandValueFrom");
        init("assessed_land_value_max", "assessedLandValueTo");
        init("assessed_total_value_min", "assessedTotalValueFrom");
        init("assessed_total_value_max", "assessedTotalValueTo");
        init("delinquent_since_years_ago", "delinquentSinceYearsAgo");
        init("market_improvement_value_min", "marketImprovementValueFrom");
        init("market_improvement_value_max", "marketImprovementValueTo");
        init("market_land_value_min", "marketLandValueFrom");
        init("market_land_value_max", "marketLandValueTo");
        init("market_total_value_min", "marketTotalValueFrom");
        init("market_total_value_max", "marketTotalValueTo");

        // Sale fields
        init("current_sale_price_min", "currentSalePriceMin");
        init("current_sale_price_max", "currentSalePriceMax");
        init("currentSalePriceCodeList", "currentSalePriceCodeList");
        init("current_sale_recording_date_min", "currentSaleRecordingDateMin");
        init("current_sale_recording_date_max", "currentSaleRecordingDateMax");
        init("current_sale_seller1_full_name", "currentSaleSeller1FullNameList");
        init("currentSaleDocumentTypeList", "currentSaleDocumentTypeList");

        // Open Lien fields
        init("concurrent_mtg1_loan_amt_min", "concurrentMtg1LoanAmtMin");
        init("concurrent_mtg1_loan_amt_max", "concurrentMtg1LoanAmtMax");
        init("concurrent_mtg1_recording_date_min", "concurrentMtg1RecordingDateMin");
        init("concurrent_mtg1_recording_date_max", "concurrentMtg1RecordingDateMax");
        init("concurrentMtg1TypeFinancingList", "concurrentMtg1TypeFinancingList");
        init("concurrentMtg1LoanTypeList", "concurrentMtg1LoanTypeList");
        init("concurrent_mtg1_interest_rate_min", "concurrentMtg1InterestRateMin");
        init("concurrent_mtg1_interest_rate_max", "concurrentMtg1InterestRateMax");

        // Structure fields
        init("ai_vacant", "aiVacant");
        init("exclude_hoa", "excludeHOA");
        init("sq_ft_max", "maxTotalSqFt");
        init("structure_count_max", "maxTotalStructureCount");
        init("sq_ft_min", "minTotalSqFt");
        init("structure_count_min", "minTotalStructureCount");

        // Scrubbing fields
        init("maxRoadFrontage", "maxRoadFrontage");
        init("minRoadFrontage", "minRoadFrontage");
        init("roadFrontageIncludeHighways", "roadFrontageIncludeHighways");
        init("roadFrontageIncludeLocalRoads", "roadFrontageIncludeLocalRoads");
        init("roadFrontageIncludeNormalRoads", "roadFrontageIncludeNormalRoads");

        return result;
    }, [savedList?.search_filters]);

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        mode: "onBlur",
        defaultValues,
    });

    const { watch, trigger } = methods;
    const formValues = watch();

    const { polygon, SettingTooltip, deletePolygon, syncPolygons, setPolygonActive } =
        usePolygon({
            onError: () => {
                syncPolygons(methods.getValues().polygons as FeaturePolygon[]);
            },
            onCreate: (events) => {
                const updatedPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    if (updatedPolygons.findIndex((e) => e.id === feature.id) > -1) {
                        return;
                    }

                    updatedPolygons.push(formatFeature(feature));
                });

                methods.setValue("polygons", updatedPolygons);

                // Reset the county when a new polygon is created
                methods.setValue("county", null);

                trigger(countyOrPolygonFields);
            },
            onUpdate(event) {
                let updatedPolygons = [...methods.getValues().polygons];
                event.features.forEach((feature) => {
                    updatedPolygons = updatedPolygons.map((f) =>
                        f.id === feature.id ? formatFeature(feature) : f,
                    );
                });
                methods.setValue("polygons", updatedPolygons);
            },
            onDelete: (events) => {
                let newPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    newPolygons = newPolygons.filter((f) => f.id !== feature?.id);
                });
                methods.setValue("polygons", newPolygons);

                if (newPolygons.length === 0) {
                    setSearchResult(null);
                }
            },
        });

    useEffect(() => {
        const searchFilters = savedList?.search_filters as SaveFilterParcelsListParams;
        if (!searchFilters) {
            return;
        }

        const hasPolygons =
            Boolean(searchFilters.polygons) && searchFilters.polygons?.length > 0;

        if (hasPolygons) {
            // @ts-ignore
            map.current?.fitBounds(bbox(first(searchFilters.polygons)), {
                maxZoom: 11,
                padding: 100,
            });
            syncPolygons(searchFilters.polygons);
        }
    }, [map, savedList?.search_filters, syncPolygons]);

    const setIsParcelList = useCallback(
        (value: boolean) => {
            setIsParcelListHandler(value);
            // Disable the polygon drawing when the parcel list is open
            setPolygonActive(!value);
        },
        [setPolygonActive],
    );

    // TODO: update signature after removing countyOption from search_filters
    // const searchFilters = useMemo((): SaveFilterParcelsListParams => {
    const searchFilters = useMemo<SaveFilterParcelsListParams>(() => {
        const parsedForm = FormSchema.safeParse(formValues);
        if (!parsedForm.success) {
            console.error("Error parsing form", parsedForm.error);
            return null;
        }
        const { data } = parsedForm;

        const countySelectedOption = data.county as CountyOption;

        // @ts-ignore
        const combined = combine(featureCollection(data.polygons));

        const geomIntersects =
            isArray(data?.polygons) && Boolean(combined)
                ? JSON.stringify(combined.features[0]?.geometry)
                : null;

        const landUse = isArray(data?.landUseCodeList)
            ? data.landUseCodeList.map((item) => item.id)
            : [];

        const currentSalePriceCodeList = isArray(data?.currentSalePriceCodeList)
            ? data.currentSalePriceCodeList.map((item) => item.id)
            : [];

        const currentSaleDocumentTypeList = isArray(data?.currentSaleDocumentTypeList)
            ? data.currentSaleDocumentTypeList.map((item) => item.id)
            : [];

        const concurrentMtg1TypeFinancingList = isArray(
            data?.concurrentMtg1TypeFinancingList,
        )
            ? data.concurrentMtg1TypeFinancingList.map((item) => item.id)
            : [];

        const concurrentMtg1LoanTypeList = isArray(data?.concurrentMtg1LoanTypeList)
            ? data.concurrentMtg1LoanTypeList.map((item) => item.id)
            : [];

        return {
            // Area of interest
            geom_intersects: geomIntersects,
            county: countySelectedOption?.id || "",

            // Parcel fields
            subdivision: data.subdivisionList,
            zips_include: data.includedZipCodes,
            zips_exclude: data.excludeZipCodes,
            zoning: data.zoningTypeList,
            land_use: landUse,
            acres_min: data.acresFrom,
            acres_max: data.acresTo,
            improvement_percentage_max: data.improvementPercentage,

            // Owner fields
            exclude_corp_owner: parseToggleField(data.excludeCorpOwner),
            exclude_keywords: data.excludeKeywords,
            include_keywords: data.includeKeywords,
            inter_family_flag: parseToggleField(data.interFamilyFlag),
            is_delinquent: parseToggleField(data.isDelinquent),
            out_of_county_owner: parseToggleField(data.outOfCountyOwner),
            out_of_state_owner: parseToggleField(data.outOfStateOwner),
            out_of_zip_owner: parseToggleField(data.outOfZipOwner),
            ownership_length_max: data.maxOwnershipLengthInMonths,
            ownership_length_min: data.minOwnershipLengthInMonths,

            // Assessor fields
            assessed_improvement_value_max: data.assessedImprovementValueTo,
            assessed_improvement_value_min: data.assessedImprovementValueFrom,
            assessed_land_value_max: data.assessedLandValueTo,
            assessed_land_value_min: data.assessedLandValueFrom,
            assessed_total_value_min: data.assessedTotalValueFrom,
            assessed_total_value_max: data.assessedTotalValueTo,
            delinquent_since_years_ago: data.delinquentSinceYearsAgo,
            market_improvement_value_max: data.marketImprovementValueTo,
            market_improvement_value_min: data.marketImprovementValueFrom,
            market_land_value_max: data.marketLandValueTo,
            market_land_value_min: data.marketLandValueFrom,
            market_total_value_max: data.marketTotalValueTo,
            market_total_value_min: data.marketTotalValueFrom,

            // Sale fields
            current_sale_price_min: data.currentSalePriceMin,
            current_sale_price_max: data.currentSalePriceMax,
            current_sale_price_code: currentSalePriceCodeList,
            current_sale_recording_date_min: data.currentSaleRecordingDateMin,
            current_sale_recording_date_max: data.currentSaleRecordingDateMax,
            current_sale_seller1_full_name: data.currentSaleSeller1FullNameList,
            current_sale_document_type: currentSaleDocumentTypeList,

            // Open Lien fields
            concurrent_mtg1_loan_amt_min: data.concurrentMtg1LoanAmtMin,
            concurrent_mtg1_loan_amt_max: data.concurrentMtg1LoanAmtMax,
            concurrent_mtg1_recording_date_min: data.concurrentMtg1RecordingDateMin,
            concurrent_mtg1_recording_date_max: data.concurrentMtg1RecordingDateMax,
            concurrent_mtg1_type_financing: concurrentMtg1TypeFinancingList,
            concurrent_mtg1_loan_type: concurrentMtg1LoanTypeList,
            concurrent_mtg1_interest_rate_min: data.concurrentMtg1InterestRateMin,
            concurrent_mtg1_interest_rate_max: data.concurrentMtg1InterestRateMax,

            // Structure fields
            ai_vacant: parseToggleField(data.aiVacant),
            exclude_hoa: parseToggleField(data.excludeHOA),
            sq_ft_max: data.maxTotalSqFt,
            sq_ft_min: data.minTotalSqFt,
            structure_count_max: data.maxTotalStructureCount,
            structure_count_min: data.minTotalStructureCount,

            // TODO: Remove after migrating to form_state because these are
            // *not* search filters they are only included here to be persisted in
            // the search filters JSON.
            county_label: countySelectedOption?.label || "",
            countyOption: countySelectedOption,
            landUseCodeList: toListItemArray(data.landUseCodeList),
            minRoadFrontage: data.minRoadFrontage,
            maxRoadFrontage: data.maxRoadFrontage,
            roadFrontageIncludeHighways: data.roadFrontageIncludeHighways,
            roadFrontageIncludeLocalRoads: data.roadFrontageIncludeLocalRoads,
            roadFrontageIncludeNormalRoads: data.roadFrontageIncludeNormalRoads,
            currentSalePriceCodeList: toListItemArray(data.currentSalePriceCodeList),
            currentSaleDocumentTypeList: toListItemArray(
                data.currentSaleDocumentTypeList,
            ),
            concurrentMtg1TypeFinancingList: toListItemArray(
                data.concurrentMtg1TypeFinancingList,
            ),
            concurrentMtg1LoanTypeList: toListItemArray(
                data.concurrentMtg1LoanTypeList,
            ),
        };
    }, [formValues]);

    const formState = useMemo((): ParcelExportFormState => {
        const parsedForm = FormSchema.safeParse(formValues);
        if (!parsedForm.success) {
            return null;
        }
        const { data } = parsedForm;

        const county = Boolean(data.county)
            ? { id: data.county?.id, label: data.county?.label }
            : null;
        return {
            // Area of interest
            county,
            polygons: data.polygons,

            // Parcel fields
            acres_from: data.acresFrom,
            acres_to: data.acresTo,
            excluded_zip_codes: data.excludeZipCodes,
            included_zip_codes: data.includedZipCodes,
            land_use_code_list: toListItemArray(data.landUseCodeList),
            subdivision_list: data.subdivisionList,
            zoning_type_list: data.zoningTypeList,
            improvement_percentage: data.improvementPercentage,

            // Owner fields
            exclude_corp_owner: data.excludeCorpOwner,
            exclude_keywords: data.excludeKeywords,
            include_keywords: data.includeKeywords,
            inter_family_flag: data.interFamilyFlag,
            is_delinquent: data.isDelinquent,
            max_ownership_length_in_months: data.maxOwnershipLengthInMonths,
            min_ownership_length_in_months: data.minOwnershipLengthInMonths,
            out_of_county_owner: data.outOfCountyOwner,
            out_of_state_owner: data.outOfStateOwner,
            out_of_zip_owner: data.outOfZipOwner,

            // Assessor fields
            assessed_improvement_value_max: data.assessedImprovementValueTo,
            assessed_improvement_value_min: data.assessedImprovementValueFrom,
            assessed_land_value_max: data.assessedLandValueTo,
            assessed_land_value_min: data.assessedLandValueFrom,
            assessed_total_value_max: data.assessedTotalValueTo,
            assessed_total_value_min: data.assessedTotalValueFrom,
            delinquent_since_years_ago: data.delinquentSinceYearsAgo,
            market_improvement_value_max: data.marketImprovementValueTo,
            market_improvement_value_min: data.marketImprovementValueFrom,
            market_land_value_max: data.marketLandValueTo,
            market_land_value_min: data.marketLandValueFrom,
            market_total_value_max: data.marketTotalValueTo,
            market_total_value_min: data.marketTotalValueFrom,

            // Sale fields
            current_sale_price_min: data.currentSalePriceMin,
            current_sale_price_max: data.currentSalePriceMax,
            current_sale_price_code_list: toListItemArray(
                data.currentSalePriceCodeList,
            ),
            current_sale_recording_date_min: data.currentSaleRecordingDateMin,
            current_sale_recording_date_max: data.currentSaleRecordingDateMax,
            current_sale_seller1_full_name_list: data.currentSaleSeller1FullNameList,
            current_sale_document_type_list: toListItemArray(
                data.currentSaleDocumentTypeList,
            ),

            // Open Lien fields
            concurrent_mtg1_loan_amt_min: data.concurrentMtg1LoanAmtMin,
            concurrent_mtg1_loan_amt_max: data.concurrentMtg1LoanAmtMax,
            concurrent_mtg1_recording_date_min: data.concurrentMtg1RecordingDateMin,
            concurrent_mtg1_recording_date_max: data.concurrentMtg1RecordingDateMax,
            concurrent_mtg1_type_financing_list: toListItemArray(
                data.concurrentMtg1TypeFinancingList,
            ),
            concurrent_mtg1_loan_type_list: toListItemArray(
                data.concurrentMtg1LoanTypeList,
            ),
            concurrent_mtg1_interest_rate_min: data.concurrentMtg1InterestRateMin,
            concurrent_mtg1_interest_rate_max: data.concurrentMtg1InterestRateMax,

            // Structure fields
            ai_vacant: data.aiVacant,
            exclude_hoa: data.excludeHOA,
            max_total_sq_ft: data.maxTotalSqFt,
            max_total_structure_count: data.maxTotalStructureCount,
            min_total_sq_ft: data.minTotalSqFt,
            min_total_structure_count: data.minTotalStructureCount,

            // Scrubbing fields
            max_flood_coverage: data.maxFloodCoverage,
            max_road_frontage: data.maxRoadFrontage,
            max_wetland_coverage: data.maxWetlandCoverage,
            min_road_frontage: data.minRoadFrontage,
            remove_duplicate_parcels: data.removeDuplicateParcels,
            remove_land_locked_parcels: data.removeLandLockedParcels,
            road_frontage_include_highways: data.roadFrontageIncludeHighways,
            road_frontage_include_local_roads: data.roadFrontageIncludeLocalRoads,
            road_frontage_include_normal_roads: data.roadFrontageIncludeNormalRoads,

            // Skip Tracing fields
            deduplicating_type: data.deduplicatingType,
            is_scrub_dnc: data.isScrubDnc,
            skip_tracing_type: data.skipTracingType,
        };
    }, [formValues]);

    function resetForm() {
        // Remove all polygons from the map
        formValues.polygons.forEach((feature) => {
            // @ts-ignore
            deletePolygon([feature]);
        });

        // Reset the search result on the map
        setSavedList(null);
        setSearchResult(null);

        // Reset the form
        methods.reset(DEFAULT_FORM_VALUES);

        // Clear API query error
        queryClient.clear();
    }

    useEffect(() => {
        if (!isOpen && !isParcelList) {
            setPolygonActive(false);
        }

        if (isOpen && !isParcelList) {
            setPolygonActive(true);
        }
    }, [isOpen, isParcelList, setPolygonActive]);

    return (
        <FormProvider {...methods}>
            <form>
                <Sidebar
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    removeBackdrop
                    onClose={() => {
                        setSearchResult(null);
                        setSavedList({
                            id: "temp-search-filter",
                            title: "temp-search-filter",
                            form_state: null,
                            search_filters: searchFilters,
                            created_at: null,
                            updated_at: null,
                        });
                    }}
                    className={clsx({
                        "lui-cursor-crosshair": isPolygonActive,
                    })}
                >
                    <ErrorBoundary
                        onReset={() => {
                            // If the error boundary originated from the export list, return the user to the form;
                            //  otherwise, reset the form
                            if (isParcelList) {
                                setIsParcelList(false);
                            } else {
                                resetForm();
                            }
                        }}
                    >
                        {SettingTooltip}

                        {/* Export parce list menu  */}
                        {isParcelList ? (
                            <MapFilterParcel
                                formState={formState}
                                searchFilters={searchFilters}
                                setIsParcelList={setIsParcelList}
                                // TODO: once we add strict types in typescript we won't need to cast this
                                countyOption={methods.watch("county") as CountyOption}
                                polygons={methods.watch("polygons") as FeaturePolygon[]}
                                resetFilterForm={resetForm}
                                scrubDuplicates={formValues.removeDuplicateParcels}
                                sizePreference={formValues.deduplicatingType}
                                landScrubs={{
                                    land_locked: formValues.removeLandLockedParcels,
                                    flood_zone_allowed: parseNumber(
                                        formValues.maxFloodCoverage,
                                    ),
                                    wetlands_allowed: parseNumber(
                                        formValues.maxWetlandCoverage,
                                    ),
                                    road_frontage_min: parseNumber(
                                        formValues.minRoadFrontage,
                                    ),
                                    road_frontage_max: parseNumber(
                                        formValues.maxRoadFrontage,
                                    ),
                                    road_frontage_include_highways:
                                        formValues.roadFrontageIncludeHighways,
                                    road_frontage_include_normal_roads:
                                        formValues.roadFrontageIncludeNormalRoads,
                                    road_frontage_include_local_roads:
                                        formValues.roadFrontageIncludeLocalRoads,
                                    created_at: null,
                                    updated_at: null,
                                }}
                                skipTrace={{
                                    scrub_dnc: formValues.isScrubDnc,
                                    report_type: formValues.skipTracingType,
                                    created_at: null,
                                    updated_at: null,
                                }}
                            />
                        ) : (
                            <>
                                <Sidebar.Header preventAutoFocus>
                                    Filter Parcels
                                </Sidebar.Header>
                                <Sidebar.Content>
                                    <PolygonAccordion
                                        polygon={polygon}
                                        deletePolygon={deletePolygon}
                                    />
                                    <ParcelFormAccordion />
                                    <OwnerFormAccordion />
                                    <AssessorFormAccordion />
                                    <SaleInfoAccordion />
                                    <OpenLienAccordion />
                                    <StructureFormAccordion />
                                    <ScrubbingFormAccordion />
                                    <SkipTracingFormAccordion />
                                </Sidebar.Content>
                                <FormFooter
                                    searchFilters={searchFilters}
                                    setIsParcelList={setIsParcelList}
                                    resetForm={resetForm}
                                    setPolygonActive={setPolygonActive}
                                />
                            </>
                        )}
                    </ErrorBoundary>
                </Sidebar>
            </form>
        </FormProvider>
    );
}

interface PolygonAccordionProps {
    polygon: MapboxDraw;
    deletePolygon: (id: FeaturePolygon[]) => void;
}

function PolygonAccordion({ polygon, deletePolygon }: PolygonAccordionProps) {
    const { isPolygonActive } = useParcelViewerContext();
    const map = useMap();
    const {
        watch,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const polygons = watch("polygons");

    const isError = hasErrorByKeys(errors, ["polygons"]);

    return (
        <div className="lui-p-6 lui-pb-0">
            <div className="lui-flex lui-justify-between lui-items-center">
                <Typography variant="h6" size="lg" weight="bold">
                    Polygons{" "}
                    {isError && (
                        <Icon
                            name="InfoCircle"
                            size="sm"
                            color="red-700"
                            className="lui-mr-1"
                        />
                    )}
                </Typography>
                <Button
                    variant="base"
                    onClick={() => {
                        polygon.changeMode("draw_polygon");
                    }}
                >
                    <span
                        className={clsx({
                            "lui-cursor-crosshair": isPolygonActive,
                        })}
                    >
                        <Icon name="PenPlus" color="primary-500" />{" "}
                        <Typography color="primary-500" variant="span" weight="medium">
                            Add zone
                        </Typography>
                    </span>
                </Button>
            </div>
            <div className="lui-mt-2 lui-flex lui-flex-col lui-gap-2">
                {polygons.map((feature, index) => {
                    return (
                        <div
                            key={index}
                            className="lui-flex lui-gap-3 lui-items-center lui-justify-between"
                        >
                            <div className="lui-flex lui-gap-2 lui-items-center">
                                <Icon name="PenPlus" color="primary-500" />
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        // @ts-ignore
                                        map.current?.fitBounds(bbox(feature), {
                                            maxZoom: 11,
                                            padding: 100,
                                        });
                                    }}
                                >
                                    <Typography>Zone {index + 1}</Typography>
                                </Button>
                            </div>
                            <Button
                                variant="base"
                                onClick={() => {
                                    // @ts-ignore
                                    deletePolygon([feature]);
                                }}
                                icon="Trash"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function FormFooter({
    setIsParcelList,
    searchFilters,
    resetForm,
    setPolygonActive,
}: {
    setIsParcelList: (value: boolean) => void;
    searchFilters: ParcelsListParams;
    resetForm: () => void;
    setPolygonActive: (value: boolean) => void;
}) {
    const [regionChangeWarning, setRegionChangeWarning] = useState(false);
    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: LARGE_SEARCH_WARNING_DELAY_SECONDS,
    });

    const { isOpen } = useSidebar();
    const {
        handleSubmit,
        formState: { errors, isValid },
        watch,
    } = useFormContext<FormSchemaType>();
    const [showErrorBox, setShowErrorBox] = useState(true);
    const { searchResult, setSearchResult } = useParcelViewerContext();
    const {
        isLoading,
        refetch: refetchParcelSearch,
        error,
    } = useParcelsSearchRetrieve(searchFilters, {
        query: {
            // Disable this query and only trigger once its refetched
            enabled: false,
            retry: false,
        },
    });

    const refetch = useCallback(
        async (
            startCountdown: () => void,
            resetCountdown: () => void,
            refetchParcelSearch: () => Promise<
                QueryObserverResult<ParcelSearchResponse, unknown>
            >,
            setSearchResult: (
                value: ParcelSearchResponse,
                options?: SearchResultOptions,
            ) => void,
        ) => {
            startCountdown();
            setPolygonActive(false);
            const newSearchResult = await refetchParcelSearch();
            setPolygonActive(true);
            resetCountdown();
            if (newSearchResult) {
                setSearchResult(newSearchResult.data, {
                    disableZoom: true,
                });
            }
        },
        [setPolygonActive],
    );

    const debouncedRefetch = useDebouncedCallback(refetch, 500);

    const [prevSearchFilters, setPrevSearchFilters] = useState<ParcelsListParams>();
    const countyUpdate = watch("county");
    const polygonUpdate = watch("polygons");
    const values = watch();
    const sensitiveZoneFilters = useMemo(() => {
        return [
            values?.subdivisionList?.length > 0 ? "Subdivision " : "",
            values?.zoningTypeList?.length > 0 ? "Zoning Type" : "",
            values?.landUseCodeList?.length > 0 ? "Land Use" : "",
        ].filter((e) => Boolean(e));
    }, [values]);

    useEffect(() => {
        // Trigger this useEffect when the polygon or county is changed
        // This is used to show warning about irrelevant filters like subdivision/zoning/land use
        if (Boolean(countyUpdate?.id) || polygonUpdate?.length > 0) {
            if (sensitiveZoneFilters.length > 0) {
                setRegionChangeWarning(true);
            }
        } else {
            setRegionChangeWarning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countyUpdate, polygonUpdate]);

    // Refetch the data when searchFilters change
    useEffect(() => {
        if (!isValid || isLoading) {
            return;
        }
        if (
            isOpen &&
            Boolean(searchFilters) &&
            JSON.stringify(searchFilters) !== JSON.stringify(prevSearchFilters)
        ) {
            debouncedRefetch(
                startCountdown,
                resetCountdown,
                refetchParcelSearch,
                setSearchResult,
            );
            setPrevSearchFilters(searchFilters);
        }
    }, [
        searchFilters,
        prevSearchFilters,
        setPrevSearchFilters,
        debouncedRefetch,
        isLoading,
        isOpen,
        isValid,
        refetchParcelSearch,
        resetCountdown,
        setSearchResult,
        startCountdown,
    ]);

    const hasErrors = Object.keys(errors).length > 0;

    // Show the error box when there are errors since its dismissible
    useEffect(() => {
        setShowErrorBox(hasErrors);
    }, [errors, hasErrors]);

    const onSubmit = async () => {
        setIsParcelList(true);
    };

    return (
        <Sidebar.Footer fluid className="lui-p-0">
            {hasErrors && showErrorBox && (
                <div className="lui-border lui-bg-red-200  lui-rounded-lg lui-p-6">
                    <div className="lui-flex lui-gap-2">
                        <Typography>
                            <Icon name="InfoCircle" size="sm" color="red-700" />
                        </Typography>
                        <Typography size="sm" weight="medium">
                            Some fields are missing or contain errors. Please review the
                            highlighted fields.
                        </Typography>
                        <Button
                            variant="base"
                            icon="Close"
                            onClick={() => setShowErrorBox(false)}
                        />
                    </div>
                    <ul className="list-disc lui-mt-2.5">
                        {Object.keys(errors).map((key) => {
                            return (
                                <Typography
                                    key={key}
                                    size="sm"
                                    variant="li"
                                    color="black"
                                    className="lui-mt-1"
                                >
                                    <span className="lui-border-y-0 lui-border-x-0 lui-border-b lui-border-black lui-border-solid">
                                        {get(fieldKeyToTitle, key, key)}
                                    </span>{" "}
                                    {get(errors, `${key}.message`)}
                                </Typography>
                            );
                        })}
                    </ul>
                </div>
            )}

            {count === 0 && (
                <WarningMessage message="Seems like you made a large search. Hang tight while we crunch the numbers." />
            )}

            {searchResult?.more_results && !isLoading && (
                <WarningMessage message="Your search results were cut short. We suggest adding more filters to see the full list of parcels." />
            )}

            {regionChangeWarning && sensitiveZoneFilters.length > 0 && (
                <WarningMessage
                    message={`You've changed your region, but your existing
                            ${sensitiveZoneFilters.join(
                                ", ",
                            )} filters may not apply to this new area, which could yield no results`}
                    onClose={() => {
                        setRegionChangeWarning(false);
                    }}
                />
            )}

            {error && (
                <div className="lui-bg-red-200 lui-border-l-4 lui-border-orange-500  lui-p-4">
                    <div className="lui-flex lui-gap-2">
                        <Icon name="InfoCircle" size="sm" color="red-900" />
                        <Typography size="sm" weight="medium" color="red-900">
                            Api query failed, please try again later.
                        </Typography>
                    </div>
                </div>
            )}

            <div className="lui-flex lui-gap-4 lui-justify-between lui-p-6">
                <Button className="" variant="inline" onClick={resetForm}>
                    Clear filter
                </Button>
                <div className=" lui-flex lui-justify-end">
                    <Button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={
                            isLoading ||
                            (searchResult?.results &&
                                searchResult?.results?.length === 0) ||
                            searchResult?.more_results
                        }
                        isLoading={isLoading}
                        className="lui-min-w-40"
                    >
                        {!searchResult && "Filter Parcels"}
                        {searchResult?.results?.length >= 0 &&
                            `Export ${formatNumber(searchResult?.count)} Parcels`}
                    </Button>
                </div>
            </div>
        </Sidebar.Footer>
    );
}
interface WarningMessageProps {
    message: string;
    onClose?: () => void;
}

function WarningMessage({ message, onClose }: WarningMessageProps) {
    return (
        <div className="lui-relative lui-bg-orange-100 lui-border-l-4 lui-border-orange-500  lui-p-5">
            {onClose && (
                <div className="lui-absolute lui-top-2 lui-right-3">
                    <Button variant="base" onClick={onClose}>
                        <Icon name="Close" size="sm" color="orange-900" />
                    </Button>
                </div>
            )}
            <div className="lui-flex lui-gap-2">
                <div>
                    <Icon name="InfoCircle" size="sm" color="orange-900" />
                </div>
                <Typography size="sm" weight="medium" color="orange-900">
                    {message}{" "}
                </Typography>
            </div>
        </div>
    );
}

interface CountyOptionData {
    isMapboxFeature: boolean;
}

function useAutocompleteRetrieveLazy(params: AutocompleteRetrieveParams) {
    const queryClient = useQueryClient();
    return () =>
        queryClient.ensureQueryData({
            queryKey: ["autocomplete", params.county, params.field, params.query],
            queryFn: () => autocompleteRetrieve(params),
        });
}

function useAutocompleteLookupRetrieveLazy(params: AutocompleteLookupRetrieveParams) {
    const queryClient = useQueryClient();
    return () =>
        queryClient.ensureQueryData({
            queryKey: [
                "autocomplete-lookup",
                params.county,
                params.field,
                params.query,
            ],
            queryFn: () => autocompleteLookupRetrieve(params),
        });
}

function ParcelFormAccordion() {
    const {
        watch,
        register,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(true);
    const { current: map } = useMap();
    const { setMapFilter, setSearchResult } = useParcelViewerContext();

    const countyAutocompleteProps = useAutocomplete<CountyOptionData>({
        id: "county-search-autocomplete",
        placeholder: "Enter county name...",
        label: "County",
        error: errors.county?.message,
        disabled: watch("polygons")?.length > 0,
        // Remove all of these "as CountyOption" once we have strictNullChecks enabled in this project
        selectedOption: watch("county") as CountyOption,
        // TODO: Fix bug where onSelect fails if picking the county option from
        // initial options since it has no Mapbox ID.
        initialOptions: (watch("county") as CountyOption)
            ? [watch("county") as CountyOption]
            : [],
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                setValue("county", null);
                setSearchResult(null);
                subdivisionAutocompleteProps.clear();
                return;
            }

            if (!selectedItem.data?.isMapboxFeature) {
                return;
            }

            const featureColl = await fetchMapboxFeature(selectedItem.id);
            const feature = featureColl.features[0];

            if (feature) {
                const result = await findFeatureOnMap(map, feature, 400);

                if (result && result.mapFeature) {
                    const { identifyLayer, idField, mapFeature } = result;

                    // Filter layer by feature ID
                    const newFilter = ["==", idField, mapFeature.id];
                    const inverseFilter = ["!", newFilter];

                    // Apply map filter for shadow layer
                    setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                    const countyOption = {
                        id: mapFeature.properties.fips,
                        label: selectedItem.label,
                    };

                    setValue("county", countyOption);

                    // Clear errors for these fields only; don't immediately
                    // trigger validation for required fields (ex. acres)
                    trigger(countyOrPolygonFields);
                }
            }
        },
        onSearch: async (inputValue) => {
            if (!inputValue) {
                return [];
            }
            const data = await searchMapbox(inputValue, "district");
            return (
                data?.map((e) => ({
                    id: e.mapbox_id,
                    label: `${e.name}, ${e.context.region.region_code}`,
                    data: {
                        isMapboxFeature: true,
                    },
                })) ?? []
            );
        },
    });

    const county = getValues("county");
    const fetchSubdivisions = useAutocompleteRetrieveLazy({
        county: county?.id,
        field: "SubdivisionName",
        query: "",
    });
    const fetchZoningTypes = useAutocompleteRetrieveLazy({
        county: county?.id,
        field: "Zoning",
        query: "",
    });
    const fetchLandUseCodes = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "LandUseCode",
        query: "",
    });

    const subdivisionAutocompleteProps = useAutocomplete<void>({
        id: "subdivision-search-autocomplete",
        placeholder: "Enter subdivision name...",
        label: fieldKeyToTitle.subdivisionList,
        onSelect: async (selectedItem) => {
            setValue("subdivisionList", [...subdivisionList, selectedItem.id]);
            subdivisionAutocompleteProps.clear();
            trigger();
        },
        onSearch: async (query) => {
            if (!county) {
                return [];
            }
            const response = await fetchSubdivisions();
            const searchTerm = query.toLowerCase();
            return response.data
                .map((str) => ({ id: str, label: str }))
                .filter((item) => !subdivisionList.includes(item.label))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        disabled: !getValues("county"),
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    const zoningTypeAutocompleteProps = useAutocomplete({
        id: "zoning-search-autocomplete",
        placeholder: "Enter zoning type...",
        label: "Zoning Type",
        onSelect: async (selectedItem) => {
            setValue("zoningTypeList", [...zoningTypeList, selectedItem.id]);
            zoningTypeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const county = getValues("county");
            if (!county) {
                return [];
            }
            const response = await fetchZoningTypes();
            const searchTerm = query.toLowerCase();
            return response.data
                .map((str) => ({ id: str, label: str }))
                .filter((item) => !zoningTypeList.includes(item.label))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        disabled: !getValues("county"),
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    const landUseCodeAutocompleteProps = useAutocomplete({
        id: "landuse-search-autocomplete",
        placeholder: "Enter land use code...",
        label: "Land Use",
        onSelect: async (selectedItem) => {
            setValue("landUseCodeList", [...landUseCodeList, selectedItem]);
            landUseCodeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const response = await fetchLandUseCodes();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter((a) => !landUseCodeList.some((b) => a.id === b.id))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    const showExcludeZip = watch("showExcludeZip");
    const includedZipCodes = watch("includedZipCodes", []);
    const excludeZipCodes = watch("excludeZipCodes", []);
    const subdivisionList = watch("subdivisionList", []);
    const zoningTypeList = watch("zoningTypeList", []);
    const landUseCodeList = watch("landUseCodeList", []);

    const isError = hasErrorByKeys(errors, [
        "acresFrom",
        "acresTo",
        "county",
        "excludeZipCode",
        "improvementPercentage",
        "includedZipCodes",
        "subdivisionList",
        "zipCode",
        "zoningTypeList",
        "landUseCodeList",
    ]);

    const excludeZipCodeRegister = register("excludeZipCode");

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Parcel" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Autocomplete {...countyAutocompleteProps} autoFocus />

                <div>
                    <Autocomplete
                        error={errors.subdivisionList?.message.toString()}
                        {...subdivisionAutocompleteProps}
                    />
                    <TagList
                        items={subdivisionList}
                        onRemove={(item) => {
                            setValue(
                                "subdivisionList",
                                subdivisionList.filter((i) => i !== item),
                            );
                            trigger();
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="zipCode"
                        placeholder="Search ZIP..."
                        label={fieldKeyToTitle.includedZipCodes}
                        action={
                            showExcludeZip ? null : (
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        setValue("showExcludeZip", true);
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        color="primary-500"
                                        weight="medium"
                                    >
                                        Exclude ZIPs
                                    </Typography>
                                </Button>
                            )
                        }
                        error={
                            errors.zipCode?.message.toString() ||
                            errors.includedZipCodes?.message.toString()
                        }
                        onEnter={async (value) => {
                            const isValid = await trigger("zipCode");
                            if (!isValid || !value) return;
                            if (includedZipCodes.includes(value)) {
                                // Don't add duplicates
                                setValue("zipCode", null);
                            } else {
                                // Add the zip code to the list
                                setValue("includedZipCodes", [
                                    ...includedZipCodes,
                                    value,
                                ]);
                                setValue("zipCode", null);
                            }
                            trigger();
                        }}
                        {...register("zipCode")}
                    />

                    <TagList
                        items={includedZipCodes}
                        onRemove={(item) => {
                            setValue(
                                "includedZipCodes",
                                includedZipCodes.filter((i) => i !== item),
                            );
                            trigger();
                        }}
                    />
                </div>

                {showExcludeZip && (
                    <div>
                        <Input
                            id="excludeZipCode"
                            placeholder="Search ZIP..."
                            label={fieldKeyToTitle.excludeZipCodes}
                            error={
                                errors.excludeZipCode?.message.toString() ||
                                errors.excludeZipCodes?.message.toString()
                            }
                            onEnter={async (value) => {
                                const isValid = await trigger("excludeZipCode");
                                if (!isValid || !value) return;
                                if (excludeZipCodes.includes(value)) {
                                    // Don't add duplicates
                                    setValue("excludeZipCode", null);
                                } else {
                                    // Add the zip code to the list
                                    setValue("excludeZipCodes", [
                                        ...excludeZipCodes,
                                        value,
                                    ]);
                                    setValue("excludeZipCode", null);
                                }
                                trigger();
                            }}
                            // Autofocus as soon as it shows up
                            autoFocus
                            {...excludeZipCodeRegister}
                        />

                        <TagList
                            items={excludeZipCodes}
                            onRemove={(item) => {
                                setValue(
                                    "excludeZipCodes",
                                    excludeZipCodes.filter((i) => i !== item),
                                );
                                trigger();
                            }}
                        />
                    </div>
                )}
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="acresFrom"
                        label={fieldKeyToTitle.acresFrom}
                        placeholder="From"
                        error={errors.acresFrom?.message}
                        {...register("acresFrom", {
                            onChange: () => {
                                trigger();
                            },
                        })}
                    />
                    <Input
                        id="acresTo"
                        placeholder="To"
                        srLabel="Acres To"
                        error={errors.acresTo?.message}
                        {...register("acresTo", {
                            onChange: () => {
                                trigger();
                            },
                        })}
                    />
                </div>

                <Input
                    id="improvementPercentage"
                    label={fieldKeyToTitle.improvementPercentage}
                    tooltip="The maximum amount of value added to the property over the vacant land value itself."
                    placeholder="%"
                    error={errors.improvementPercentage?.message}
                    {...register("improvementPercentage")}
                />

                <div>
                    <Autocomplete {...zoningTypeAutocompleteProps} />
                    <TagList
                        items={zoningTypeList}
                        onRemove={(item) => {
                            setValue(
                                "zoningTypeList",
                                zoningTypeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Autocomplete {...landUseCodeAutocompleteProps} />
                    <TagList
                        items={landUseCodeList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "landUseCodeList",
                                landUseCodeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function StructureFormAccordion() {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);

    const isError = Boolean(
        errors.minTotalSqFt?.message ||
            errors.minTotalStructureCount?.message ||
            errors.maxTotalSqFt?.message ||
            errors.maxTotalStructureCount?.message ||
            errors.aiVacant?.message,
    );

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Structure" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Toggle
                        id="aiVacant"
                        labelClassName={"lui-typography-medium"}
                        label={fieldKeyToTitle.aiVacant}
                        info="Remove parcels that have AI detected buildings."
                        {...register("aiVacant")}
                    />
                </div>
                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.totalSqFt}
                    </Typography>

                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="totalSqFt"
                            error={errors.minTotalSqFt?.message}
                            placeholder="Min"
                            info="County assessor data"
                            {...register("minTotalSqFt")}
                        />

                        <Input
                            id="totalSqFt"
                            error={errors.maxTotalSqFt?.message}
                            placeholder="Max"
                            {...register("maxTotalSqFt")}
                        />
                    </div>
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="totalStructureCount"
                        label={fieldKeyToTitle.totalStructureCount}
                        error={errors.minTotalStructureCount?.message}
                        placeholder="Min"
                        info="County assessor data"
                        {...register("minTotalStructureCount")}
                    />
                    <Input
                        id="totalStructureCount"
                        srLabel={fieldKeyToTitle.maxTotalStructureCount}
                        error={errors.maxTotalStructureCount?.message}
                        placeholder="Max"
                        {...register("maxTotalStructureCount")}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function OwnerFormAccordion() {
    const {
        register,
        formState: { errors },
        setValue,
        trigger,
        watch,
    } = useFormContext<FormSchemaType>();

    const [isOpened, setIsOpened] = useState(false);

    const isError = hasErrorByKeys(errors, [
        "excludeKeyword",
        "includeKeyword",
        "isDelinquent",
        "maxOwnershipLengthInMonths",
        "minOwnershipLengthInMonths",
        "outOfCountyOwner",
        "outOfStateOwner",
        "outOfZipOwner",
    ]);

    const excludeKeywords = watch("excludeKeywords", []).map((x) => x.toUpperCase());
    const includeKeywords = watch("includeKeywords", []).map((x) => x.toUpperCase());

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Owner" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="outOfStateOwner"
                    label={fieldKeyToTitle.outOfStateOwner}
                    {...register("outOfStateOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfCountyOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfCountyOwner"
                    label={fieldKeyToTitle.outOfCountyOwner}
                    {...register("outOfCountyOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfZipOwner"
                    label={fieldKeyToTitle.outOfZipOwner}
                    {...register("outOfZipOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfCountyOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="excludeCorpOwner"
                    label={fieldKeyToTitle.excludeCorpOwner}
                    tooltip="Do not include any land that is owned by a business entity (LLC, Family Trust, S-Corp, etc)"
                    {...register("excludeCorpOwner")}
                />
                <Toggle
                    id="interFamilyFlag"
                    label={fieldKeyToTitle.interFamilyFlag}
                    {...register("interFamilyFlag")}
                />
                <Toggle
                    id="isDelinquent"
                    label={fieldKeyToTitle.isDelinquent}
                    tooltip="The owner has back taxes on the property"
                    {...register("isDelinquent")}
                />

                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.ownershipLengthInMonths}{" "}
                        <Typography variant="span" color="gray-700">
                            (Months)
                        </Typography>
                    </Typography>
                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="minOwnershipLengthInMonths"
                            placeholder="Min"
                            {...register("minOwnershipLengthInMonths")}
                        />
                        <Input
                            id="maxOwnershipLengthInMonths"
                            placeholder="Max"
                            {...register("maxOwnershipLengthInMonths")}
                        />
                    </div>
                </div>

                <div>
                    <Input
                        id="includeKeywords"
                        placeholder="Include keywords..."
                        label="Only Include Keywords"
                        tooltip="Keywords that MUST be present in the 1st title holder's name (ex: could be a last name, a keyword, a specific niche, etc)."
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("includeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!includeKeywords.includes(keyword)) {
                                    includeKeywords.push(keyword);
                                }
                            });
                            setValue("includeKeywords", includeKeywords);
                            setValue("includeKeyword", null);
                        }}
                        {...register("includeKeyword")}
                    />

                    <TagList
                        items={includeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "includeKeywords",
                                includeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="excludeKeywords"
                        placeholder="Exclude keywords..."
                        label="Exclude Keywords"
                        tooltip="Keywords that MUST NOT be present in the 1st title holder's name (ex: government, school, county, state, etc)."
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("excludeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!excludeKeywords.includes(keyword)) {
                                    excludeKeywords.push(keyword);
                                }
                            });
                            setValue("excludeKeywords", excludeKeywords);
                            setValue("excludeKeyword", null);
                        }}
                        {...register("excludeKeyword")}
                    />

                    <TagList
                        items={excludeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "excludeKeywords",
                                excludeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function AssessorFormAccordion() {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();

    const [isOpened, setIsOpened] = useState(false);

    const isError = hasErrorByKeys(errors, [
        "assessedImprovementValueFrom",
        "assessedImprovementValueTo",
        "assessedLandValueFrom",
        "assessedLandValueTo",
        "assessedTotalValueFrom",
        "assessedTotalValueTo",
        "delinquentSinceYearsAgo",
        "marketImprovementValueFrom",
        "marketImprovementValueTo",
        "marketLandValueFrom",
        "marketLandValueTo",
        "marketTotalValueFrom",
        "marketTotalValueTo",
    ]);

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="County Assessor" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="assessedTotalValueFrom"
                        label={fieldKeyToTitle.assessedTotalValueFrom}
                        tooltip="The total assessed value of the property as reported on the current county tax roll, combining both land and improvement values (e.g., a $250,000 parcel with a $150,000 structure would have a $400,000 total value). This value is used as the basis for property taxation before any exemptions are applied."
                        placeholder="From"
                        error={errors.assessedTotalValueFrom?.message}
                        {...register("assessedTotalValueFrom")}
                    />
                    <Input
                        id="assessedTotalValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.assessedTotalValueTo}
                        error={errors.assessedTotalValueTo?.message}
                        {...register("assessedTotalValueTo")}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="assessedLandValueFrom"
                        label={fieldKeyToTitle.assessedLandValueFrom}
                        tooltip="The assessed value of only the land portion of the property, excluding any structures or improvements (e.g., vacant lots, farmland, or the land under a home). This value is determined by the county assessor and reported on the current tax roll."
                        placeholder="From"
                        error={errors.assessedLandValueFrom?.message}
                        {...register("assessedLandValueFrom")}
                    />
                    <Input
                        id="assessedLandValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.assessedLandValueTo}
                        error={errors.assessedLandValueTo?.message}
                        {...register("assessedLandValueTo")}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="assessedImprovementValueFrom"
                        label={fieldKeyToTitle.assessedImprovementValueFrom}
                        tooltip="The assessed value of all structures and enhancements on the property, separate from the land itself (e.g., houses, barns, garages, swimming pools). This represents the total value of all built improvements as determined by the county assessor."
                        placeholder="From"
                        error={errors.assessedImprovementValueFrom?.message}
                        {...register("assessedImprovementValueFrom")}
                    />
                    <Input
                        id="assessedImprovementValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.assessedImprovementValueTo}
                        error={errors.assessedImprovementValueTo?.message}
                        {...register("assessedImprovementValueTo")}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="marketTotalValueFrom"
                        label={fieldKeyToTitle.marketTotalValueFrom}
                        tooltip="The estimated fair market value of the entire property as determined by the county assessor, representing what the property might sell for under normal market conditions (e.g., a property assessed at $300,000 might have a market value of $450,000). This value may differ from the assessed value depending on local assessment practices."
                        placeholder="From"
                        error={errors.marketTotalValueFrom?.message}
                        {...register("marketTotalValueFrom")}
                    />
                    <Input
                        id="marketTotalValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.marketTotalValueTo}
                        error={errors.marketTotalValueTo?.message}
                        {...register("marketTotalValueTo")}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="marketLandValueFrom"
                        label={fieldKeyToTitle.marketLandValueFrom}
                        tooltip="The estimated fair market value of just the land portion of the property as determined by the assessor (e.g., a quarter-acre lot in a desirable area). This value reflects what the land alone might sell for under normal market conditions."
                        placeholder="From"
                        error={errors.marketLandValueFrom?.message}
                        {...register("marketLandValueFrom")}
                    />
                    <Input
                        id="marketLandValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.marketLandValueTo}
                        error={errors.marketLandValueTo?.message}
                        {...register("marketLandValueTo")}
                    />
                </div>
                <div className="lui-grid lui-grid-cols-3 lui-gap-6">
                    <div className="lui-col-span-2">
                        <Input
                            id="marketImprovementValueFrom"
                            label={fieldKeyToTitle.marketImprovementValueFrom}
                            tooltip="The estimated fair market value of just the improvements on the property as determined by the assessor (e.g., a recently renovated home, commercial building, or other structures). This value reflects what the improvements alone might sell for under normal market conditions."
                            placeholder="From"
                            error={errors.marketImprovementValueFrom?.message}
                            {...register("marketImprovementValueFrom")}
                        />
                    </div>
                    <Input
                        id="marketImprovementValueTo"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.marketImprovementValueTo}
                        error={errors.marketImprovementValueTo?.message}
                        {...register("marketImprovementValueTo")}
                    />
                </div>
                <Input
                    id="delinquentSinceYearsAgo"
                    label={fieldKeyToTitle.delinquentSinceYearsAgo}
                    placeholder="5 years"
                    error={errors.delinquentSinceYearsAgo?.message}
                    {...register("delinquentSinceYearsAgo")}
                />
            </div>
        </Accordion>
    );
}

function SaleInfoAccordion() {
    const {
        register,
        setValue,
        getValues,
        trigger,
        formState: { errors },
        watch,
    } = useFormContext<FormSchemaType>();

    const [isOpened, setIsOpened] = useState(false);

    const isError = hasErrorByKeys(errors, [
        "currentSalePriceMin",
        "currentSalePriceMax",
        "currentSalePriceCodeList",
        "currentSaleRecordingDateMin",
        "currentSaleRecordingDateMax",
        "currentSaleSeller1FullName",
        "currentSaleSeller1FullNameList",
        "currentSaleDocumentTypeList",
    ]);

    const currentSalePriceCodeList = watch("currentSalePriceCodeList", []);
    const currentSaleSeller1FullNameList = watch(
        "currentSaleSeller1FullNameList",
        [],
    ).map((x) => x.toUpperCase());
    const currentSaleDocumentTypeList = watch("currentSaleDocumentTypeList", []);

    const county = getValues("county");
    const fetchSalePriceLookups = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "CurrentSalesPriceCode",
        query: "",
    });
    const fetchDocTypeLookups = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "CurrentSaleDocumentType",
        query: "",
    });

    const currentSalePriceCodeAutocompleteProps = useAutocomplete({
        id: "current-sale-price-code-autocomplete",
        placeholder: "Enter sale price type...",
        label: fieldKeyToTitle.currentSalePriceCodeList,
        onSelect: async (selectedItem) => {
            setValue("currentSalePriceCodeList", [
                ...currentSalePriceCodeList,
                selectedItem,
            ]);
            currentSalePriceCodeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const response = await fetchSalePriceLookups();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter((a) => !currentSalePriceCodeList.some((b) => a.id === b.id))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    const currentSaleDocumentTypeAutocompleteProps = useAutocomplete({
        id: "current-sale-document-type-autocomplete",
        placeholder: "Enter document type...",
        label: fieldKeyToTitle.currentSaleDocumentTypeList,
        onSelect: async (selectedItem) => {
            setValue("currentSaleDocumentTypeList", [
                ...currentSaleDocumentTypeList,
                selectedItem,
            ]);
            currentSaleDocumentTypeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const response = await fetchDocTypeLookups();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter((a) => !currentSaleDocumentTypeList.some((b) => a.id === b.id))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Sale Info" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="currentSalePriceMin"
                        label={fieldKeyToTitle.currentSalePriceMin}
                        placeholder="From"
                        error={errors.currentSalePriceMin?.message}
                        {...register("currentSalePriceMin")}
                    />
                    <Input
                        id="currentSalePriceMax"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.currentSalePriceMax}
                        error={errors.currentSalePriceMax?.message}
                        {...register("currentSalePriceMax")}
                    />
                </div>
                <div>
                    <Autocomplete {...currentSalePriceCodeAutocompleteProps} />
                    <TagList
                        items={currentSalePriceCodeList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "currentSalePriceCodeList",
                                currentSalePriceCodeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="currentSaleRecordingDateMin"
                        label={fieldKeyToTitle.currentSaleRecordingDateMin}
                        placeholder="From Year"
                        error={errors.currentSaleRecordingDateMin?.message}
                        {...register("currentSaleRecordingDateMin")}
                    />
                    <Input
                        id="currentSaleRecordingDateMax"
                        placeholder="To Year"
                        srLabel={fieldKeyToTitle.currentSaleRecordingDateMax}
                        error={errors.currentSaleRecordingDateMax?.message}
                        {...register("currentSaleRecordingDateMax")}
                    />
                </div>
                <div>
                    <Input
                        id="currentSaleSeller1FullNameList"
                        placeholder="Enter name..."
                        label={fieldKeyToTitle.currentSaleSeller1FullNameList}
                        error={errors.currentSaleSeller1FullNameList?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("currentSaleSeller1FullName");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!currentSaleSeller1FullNameList.includes(keyword)) {
                                    currentSaleSeller1FullNameList.push(keyword);
                                }
                            });
                            setValue(
                                "currentSaleSeller1FullNameList",
                                currentSaleSeller1FullNameList,
                            );
                            setValue("currentSaleSeller1FullName", null);
                        }}
                        {...register("currentSaleSeller1FullName")}
                    />
                    <TagList
                        items={currentSaleSeller1FullNameList}
                        onRemove={(item) => {
                            setValue(
                                "currentSaleSeller1FullNameList",
                                currentSaleSeller1FullNameList.filter(
                                    (i) => i !== item,
                                ),
                            );
                        }}
                    />
                </div>
                <div>
                    <Autocomplete {...currentSaleDocumentTypeAutocompleteProps} />
                    <TagList
                        items={currentSaleDocumentTypeList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "currentSaleDocumentTypeList",
                                currentSaleDocumentTypeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function OpenLienAccordion() {
    const {
        register,
        setValue,
        getValues,
        formState: { errors },
        watch,
    } = useFormContext<FormSchemaType>();

    const [isOpened, setIsOpened] = useState(false);

    const isError = hasErrorByKeys(errors, [
        "concurrentMtg1LoanAmtMin",
        "concurrentMtg1LoanAmtMax",
        "concurrentMtg1RecordingDateMin",
        "concurrentMtg1RecordingDateMax",
        "concurrentMtg1TypeFinancingList",
        "concurrentMtg1LoanTypeList",
        "concurrentMtg1InterestRateMin",
        "concurrentMtg1InterestRateMax",
    ]);

    const concurrentMtg1TypeFinancingList = watch(
        "concurrentMtg1TypeFinancingList",
        [],
    );
    const concurrentMtg1LoanTypeList = watch("concurrentMtg1LoanTypeList", []);

    const county = getValues("county");
    const fetchFinTypeLookups = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "ConcurrentMtg1TypeFinancing",
        query: "",
    });
    const fetchLoanTypeLookups = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "ConcurrentMtg1LoanType",
        query: "",
    });

    const concurrentMtg1TypeFinancingAutocompleteProps = useAutocomplete({
        id: "concurrent-mtg1-type-financing-autocomplete",
        placeholder: "Enter financing type...",
        label: fieldKeyToTitle.concurrentMtg1TypeFinancingList,
        onSelect: async (selectedItem) => {
            setValue("concurrentMtg1TypeFinancingList", [
                ...concurrentMtg1TypeFinancingList,
                selectedItem,
            ]);
            concurrentMtg1TypeFinancingAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const response = await fetchFinTypeLookups();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter(
                    (a) => !concurrentMtg1TypeFinancingList.some((b) => a.id === b.id),
                )
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    const concurrentMtg1LoanTypeAutocompleteProps = useAutocomplete({
        id: "concurrent-mtg1-loan-type-autocomplete",
        placeholder: "Enter loan type...",
        label: fieldKeyToTitle.concurrentMtg1LoanTypeList,
        onSelect: async (selectedItem) => {
            setValue("concurrentMtg1LoanTypeList", [
                ...concurrentMtg1LoanTypeList,
                selectedItem,
            ]);
            concurrentMtg1LoanTypeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const response = await fetchLoanTypeLookups();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter((a) => !concurrentMtg1LoanTypeList.some((b) => a.id === b.id))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        debounceDelay: 1,
        invalidationKey: `${county?.id}`,
    });

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Open Lien" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="concurrentMtg1LoanAmtMin"
                        label={fieldKeyToTitle.concurrentMtg1LoanAmtMin}
                        placeholder="From"
                        error={errors.concurrentMtg1LoanAmtMin?.message}
                        {...register("concurrentMtg1LoanAmtMin")}
                    />
                    <Input
                        id="concurrentMtg1LoanAmtMax"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.concurrentMtg1LoanAmtMax}
                        error={errors.concurrentMtg1LoanAmtMax?.message}
                        {...register("concurrentMtg1LoanAmtMax")}
                    />
                </div>
                <div className="lui-grid lui-grid-cols-3 lui-gap-6">
                    <div className="lui-col-span-2">
                        <Input
                            id="concurrentMtg1RecordingDateMin"
                            label={fieldKeyToTitle.concurrentMtg1RecordingDateMin}
                            placeholder="From Year"
                            error={errors.concurrentMtg1RecordingDateMin?.message}
                            {...register("concurrentMtg1RecordingDateMin")}
                        />
                    </div>
                    <Input
                        id="concurrentMtg1RecordingDateMax"
                        placeholder="To Year"
                        srLabel={fieldKeyToTitle.concurrentMtg1RecordingDateMax}
                        error={errors.concurrentMtg1RecordingDateMax?.message}
                        {...register("concurrentMtg1RecordingDateMax")}
                    />
                </div>
                <div>
                    <Autocomplete {...concurrentMtg1TypeFinancingAutocompleteProps} />
                    <TagList
                        items={concurrentMtg1TypeFinancingList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "concurrentMtg1TypeFinancingList",
                                concurrentMtg1TypeFinancingList.filter(
                                    (i) => i !== item,
                                ),
                            );
                        }}
                    />
                </div>
                <div>
                    <Autocomplete {...concurrentMtg1LoanTypeAutocompleteProps} />
                    <TagList
                        items={concurrentMtg1LoanTypeList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "concurrentMtg1LoanTypeList",
                                concurrentMtg1LoanTypeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="concurrentMtg1InterestRateMin"
                        label={fieldKeyToTitle.concurrentMtg1InterestRateMin}
                        placeholder="From"
                        error={errors.concurrentMtg1InterestRateMin?.message}
                        {...register("concurrentMtg1InterestRateMin")}
                    />
                    <Input
                        id="concurrentMtg1InterestRateMax"
                        placeholder="To"
                        srLabel={fieldKeyToTitle.concurrentMtg1InterestRateMax}
                        error={errors.concurrentMtg1InterestRateMax?.message}
                        {...register("concurrentMtg1InterestRateMax")}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function ScrubbingFormAccordion() {
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const isError = hasErrorByKeys(errors, [
        "removeLandLockedParcels",
        "removeDuplicateParcels",
        "deduplicatingType",
        "maxWetlandCoverage",
        "maxFloodCoverage",
        "minRoadFrontage",
        "maxRoadFrontage",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Scrubbing" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="excludeHOA"
                    label={fieldKeyToTitle.excludeHOA}
                    {...register("excludeHOA")}
                />
                <Toggle
                    id="removeLandLockedParcels"
                    label={fieldKeyToTitle.removeLandLockedParcels}
                    {...register("removeLandLockedParcels")}
                />
                <Toggle
                    id="removeDuplicateParcels"
                    label={fieldKeyToTitle.removeDuplicateParcels}
                    tooltip='When a single owner has multiple properties within the dataset, keep only one of the properties. By default, the largest acreage is chosen but you can change this with the "Keep Which Parcel When Deduplicating" option.'
                    {...register("removeDuplicateParcels")}
                />
                <SelectionGroupFormController
                    control={control}
                    label={fieldKeyToTitle.deduplicatingType}
                    name="deduplicatingType"
                    options={[
                        { title: "Smaller Parcel", id: SizePreferenceEnum.smaller },
                        { title: "Larger Parcel", id: SizePreferenceEnum.larger },
                    ]}
                    horizontal
                />
                <Input
                    id="maxWetlandCoverage"
                    label={fieldKeyToTitle.maxWetlandCoverage}
                    placeholder="%"
                    info="Empty % will not scrub."
                    error={errors.maxWetlandCoverage?.message}
                    {...register("maxWetlandCoverage")}
                />
                <Input
                    id="maxFloodCoverage"
                    label={fieldKeyToTitle.maxFloodCoverage}
                    placeholder="%"
                    info="Empty % will not scrub."
                    error={errors.maxFloodCoverage?.message}
                    {...register("maxFloodCoverage")}
                />
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="minRoadFrontage"
                        label={fieldKeyToTitle.minRoadFrontage}
                        placeholder="Min Feet"
                        error={errors.minRoadFrontage?.message}
                        {...register("minRoadFrontage")}
                    />
                    <Input
                        id="maxRoadFrontage"
                        placeholder="Max Feet"
                        srLabel={fieldKeyToTitle.maxRoadFrontage}
                        error={errors.maxRoadFrontage?.message}
                        {...register("maxRoadFrontage")}
                    />
                </div>
                <Toggle
                    id="roadFrontageIncludeHighways"
                    label={fieldKeyToTitle.roadFrontageIncludeHighways}
                    info="Recommended to be ON. Algorithm considers highways roads in frontage scrubbing."
                    {...register("roadFrontageIncludeHighways")}
                />
                <Toggle
                    id="roadFrontageIncludeNormalRoads"
                    label={fieldKeyToTitle.roadFrontageIncludeNormalRoads}
                    info="Recommended to be ON. Algorithm considers typical roads in frontage scrubbing."
                    {...register("roadFrontageIncludeNormalRoads")}
                />
                <Toggle
                    id="roadFrontageIncludeLocalRoads"
                    label={fieldKeyToTitle.roadFrontageIncludeLocalRoads}
                    info="Recommended to be ON. Algorithm considers local roads in frontage scrubbing. NOTE: Local roads may sometimes be private roads."
                    {...register("roadFrontageIncludeLocalRoads")}
                />
            </div>
        </Accordion>
    );
}

function SkipTracingFormAccordion() {
    const [isOpened, setIsOpened] = useState(false);
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const isError = hasErrorByKeys(errors, ["skipTracingType"]);

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Skip Tracing" isError={isError} />}
        >
            <SelectionGroupFormController
                control={control}
                label={fieldKeyToTitle.skipTracingType}
                name="skipTracingType"
                options={[
                    {
                        id: null,
                        title: "No Skipping",
                        subtitle: "FREE",
                    },
                    {
                        id: ReportTypeEnum.standard,
                        title: "Standard",
                        subtitle: `${SKIP_TRACE_PRICE_STANDARD} Credits / Hit`,
                        info: "Includes up to 6 phone numbers and the type of each phone number (mobile, landline, VOIP).",
                    },
                    {
                        id: ReportTypeEnum.premium,
                        title: "Premium",
                        subtitle: `${SKIP_TRACE_PRICE_PREMIUM} Credits / Hit`,
                        info: "Includes up to 7 phone numbers, the type of each phone number (mobile, landline, VOIP), age of owner, owner email, relative phone numbers and emails, and higher hit rate (usually > 95%).",
                        children: (
                            <div className="lui-flex lui-justify-between lui-items-center lui-w-full">
                                <div>
                                    <Typography weight="medium" size="xs">
                                        Scrub DNC
                                    </Typography>
                                    <Typography size="xs" color="gray-700">
                                        Remove Do Not Contact.{" "}
                                        {SKIP_TRACE_PRICE_PREMIUM_DNC} credit / record.
                                    </Typography>
                                </div>
                                <div>
                                    <Toggle
                                        id="isScrubDnc"
                                        {...register("isScrubDnc")}
                                    />
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </Accordion>
    );
}

function AccordionFormTitle({ title, isError }: { title: string; isError?: boolean }) {
    return (
        <div className="lui-flex lui-items-center lui-gap-1.5">
            <Typography size="lg" weight="bold">
                {title}
            </Typography>
            {isError && (
                <Icon
                    name="InfoCircle"
                    size="sm"
                    color="red-700"
                    className="lui-mr-1"
                />
            )}
        </div>
    );
}

// Check if there is an error in the form state by list of keys. Used to check
// the section so we can identify which accordion has an error.
function hasErrorByKeys(errors: Record<string, any>, keys: string[]): boolean {
    return keys.some((key) => get(errors, key));
}

//  Map field key to title
const fieldKeyToTitle: Record<string, string> = {
    acresFrom: "Acres Range",
    acresTo: "Acres To",
    aiVacant: "Vacant Land Only (AI)",
    assessedImprovementValueFrom: "Improvement Value",
    assessedImprovementValueTo: "Improvement Value",
    assessedLandValueFrom: "Land Value",
    assessedLandValueTo: "Land Value",
    assessedTotalValueFrom: "Total Value",
    assessedTotalValueTo: "Total Value",
    atLeastOneFieldRequiredError: "",
    county: "County",
    countyOrPolygonRequiredError: "",
    deduplicatingType: "Keep Which Parcel When Deduplicating",
    delinquentSinceYearsAgo: "Tax Delinquent For...",
    excludeCorpOwner: "Exclude Corporate Owners",
    excludeHOA: "Remove HOA Parcels",
    excludeZipCode: "Exclude ZIP Code",
    excludeZipCodes: "Exclude ZIPs",
    improvementPercentage: "Max Improvement Percentage",
    includedZipCodes: "Include ZIPs",
    interFamilyFlag: "Only Inter-Family Transfers",
    isDelinquent: "Owner Is Tax Delinquent",
    marketImprovementValueFrom: "Market Improvement Value",
    marketImprovementValueTo: "Market Improvement Value",
    marketLandValueFrom: "Market Land Value",
    marketLandValueTo: "Market Land Value",
    marketTotalValueFrom: "Market Total Value",
    marketTotalValueTo: "Market Total Value",
    maxFloodCoverage: "Max Flood Coverage",
    maxOwnershipLengthInMonths: "Max Ownership Length",
    maxRoadFrontage: "Max Road Frontage",
    maxTotalSqFt: "Max Total Structure Footprint",
    maxTotalStructureCount: "Max Structure Count",
    maxWetlandCoverage: "Max Wetland Coverage",
    minOwnershipLengthInMonths: "Min Ownership Length",
    minRoadFrontage: "Road Frontage",
    minTotalSqFt: "Min Total Structure Footprint",
    minTotalStructureCount: "Min Structure Count",
    outOfCountyOwner: "Out of County Owner",
    outOfStateOwner: "Out of State Owner",
    outOfZipOwner: "Out of ZIP Owner",
    ownershipLengthInMonths: "Ownership Length",
    polygons: "Polygons",
    removeDuplicateParcels: "Deduplicate Owners",
    removeLandLockedParcels: "Remove Land Locked Parcels",
    roadFrontageIncludeHighways: "Include Highway Roads In Scrubbing",
    roadFrontageIncludeLocalRoads: "Include Local Roads In Scrubbing",
    roadFrontageIncludeNormalRoads: "Include Normal Roads In Scrubbing",
    skipTracingType: "Skip Tracing Type",
    subdivisionList: "Subdivision",
    totalSqFt: "Total Structure Square Feet",
    totalStructureCount: "Total Structure Count",
    zipCode: "ZIP Code",

    // Sale info fields
    currentSalePriceMin: "Sale Price",
    currentSalePriceMax: "Sale Price",
    currentSalePriceCodeList: "Sale Price Type",
    currentSaleRecordingDateMin: "Last Sale Date",
    currentSaleRecordingDateMax: "Last Sale Date",
    currentSaleSeller1FullName: "Seller Name",
    currentSaleSeller1FullNameList: "Seller Name",
    currentSaleDocumentTypeList: "Transaction Deed Type",

    // Open Lien fields
    concurrentMtg1LoanAmtMin: "Mortgage Amount",
    concurrentMtg1LoanAmtMax: "Mortgage Amount",
    concurrentMtg1RecordingDateMin: "Mortgage Recording Date",
    concurrentMtg1RecordingDateMax: "Mortgage Recording Date",
    concurrentMtg1TypeFinancingList: "Financing Type",
    concurrentMtg1LoanTypeList: "Mortgage Type",
    concurrentMtg1InterestRateMin: "Interest Rate",
    concurrentMtg1InterestRateMax: "Interest Rate",
};

function isNullOrEmpty(val: any): boolean {
    return isNil(val) || trim(val) === "";
}

// Argument may be null if loaded from DEFAULT_FORM_VALUES. Argument may be
// undefined if loading a sparsely populated saved filters object.
function parseNumber(val: any): number | null {
    return isNullOrEmpty(val) ? null : Number(val);
}

// Parse boolean toggle field. Return true if toggle is enabled, or null
// otherwise, to disable the search filter.
function parseToggleField(val: any): boolean | null {
    return Boolean(val) ? true : null;
}
