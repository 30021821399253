import React, { type ReactNode } from "react";

import { useLocalStorage } from "usehooks-ts";
import { Toggle } from "@src/land_ui/toggle_button/Toggle";
import { ControlPopover } from "./control_popover";
import { Input } from "@src/land_ui/input/input";

export interface DataLayerType {
    wetLands: boolean;
    surfaceWater: boolean;
    floodZoneLayer: boolean;
    // soilLayer: boolean,
    buildingLayer: boolean;
    mlsComps: boolean;
    countyLayer: boolean;
    zipLayer: boolean;
    roadsLayer: boolean;
    ownersLayer: boolean;
    compsFilter: {
        minAcres: string;
        maxAcres: string;
    };
    landuseLayer: boolean;
    contourLayer?: boolean;
}

const DEFAULT_DATA_LAYER: DataLayerType = {
    wetLands: false,
    surfaceWater: false,
    floodZoneLayer: false,
    // soilLayer: false,
    buildingLayer: false,
    mlsComps: false,
    countyLayer: false,
    zipLayer: false,
    roadsLayer: false,
    ownersLayer: false,
    compsFilter: {
        minAcres: "",
        maxAcres: "",
    },
    landuseLayer: false,
    contourLayer: false,
};

export function useDataLayerControl() {
    const [dataLayer, setDataLayer] = useLocalStorage<DataLayerType>(
        "dataLayerControl",
        DEFAULT_DATA_LAYER,
    );

    return {
        dataLayer: { ...DEFAULT_DATA_LAYER, ...dataLayer },
        setDataLayer: (newDataLayer: Partial<DataLayerType>) => {
            setDataLayer({
                ...dataLayer,
                ...newDataLayer,
            });
        },
    };
}

interface DataLayerControlProps {
    disableCompsLayer: boolean;
}

export function DataLayerControl(props: DataLayerControlProps) {
    const { dataLayer, setDataLayer } = useDataLayerControl();

    const layerOptions: {
        name: string;
        state: boolean;
        setState: () => void;
        disabled?: boolean;
        extra?: ReactNode;
    }[] = [
        {
            name: "Owner Layer",
            state: dataLayer.ownersLayer,
            setState: () => {
                setDataLayer({
                    ownersLayer: !dataLayer.ownersLayer,
                });
            },
        },
        {
            name: "County Layer",
            state: dataLayer.countyLayer,
            setState: () => {
                setDataLayer({
                    countyLayer: !dataLayer.countyLayer,
                });
            },
        },
        {
            name: "Zip Layer",
            state: dataLayer.zipLayer,
            setState: () => {
                setDataLayer({
                    zipLayer: !dataLayer.zipLayer,
                });
            },
        },
        {
            name: "Wetlands Layer",
            state: dataLayer.wetLands,
            setState: () => {
                setDataLayer({
                    wetLands: !dataLayer.wetLands,
                });
            },
        },
        {
            name: "Surface Water Layer",
            state: dataLayer.surfaceWater,
            setState: () => {
                setDataLayer({
                    surfaceWater: !dataLayer.surfaceWater,
                });
            },
        },
        {
            name: "Flood Zone Layer",
            state: dataLayer.floodZoneLayer,
            setState: () => {
                setDataLayer({
                    ...dataLayer,
                    floodZoneLayer: !dataLayer.floodZoneLayer,
                });
            },
        },
        {
            name: "Contour Layer",
            state: dataLayer.contourLayer,
            setState: () => {
                setDataLayer({
                    contourLayer: !dataLayer.contourLayer,
                });
            },
        },
        {
            name: "Roads Layer",
            state: dataLayer.roadsLayer,
            setState: () => {
                setDataLayer({
                    roadsLayer: !dataLayer.roadsLayer,
                });
            },
        },
        {
            name: "Building Layer",
            state: dataLayer.buildingLayer,
            setState: () => {
                setDataLayer({
                    buildingLayer: !dataLayer.buildingLayer,
                });
            },
        },
        {
            name: "MLS Comps",
            state: dataLayer.mlsComps,
            disabled: props.disableCompsLayer,
            setState: () => {
                setDataLayer({
                    mlsComps: !dataLayer.mlsComps,
                });
            },
            extra: (
                <div
                    className={
                        "lui-flex w-full lui-items-center lui-bg-slate-100 p-2 lui-justify-around"
                    }
                >
                    <div className={"lui-w-28"}>
                        <Input
                            placeholder={"Min ac."}
                            type="number"
                            value={dataLayer.compsFilter.minAcres}
                            onChange={(e) => {
                                setDataLayer({
                                    compsFilter: {
                                        ...dataLayer.compsFilter,
                                        minAcres: e.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className={"lui-w-28"}>
                        <Input
                            placeholder={"Max ac."}
                            type="number"
                            value={dataLayer.compsFilter.maxAcres}
                            onChange={(e) => {
                                setDataLayer({
                                    compsFilter: {
                                        ...dataLayer.compsFilter,
                                        maxAcres: e.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            ),
        },
        {
            name: "Land Use Layer",
            state: dataLayer.landuseLayer,
            setState: () => {
                setDataLayer({
                    landuseLayer: !dataLayer.landuseLayer,
                });
            },
        },

        // {
        //     name: "Soil Layer",
        //     state: dataLayer.soilLayer,
        //     setState: () => {
        //         setDataLayer({
        //             soilLayer: !dataLayer.soilLayer,
        //         });
        //     },
        // },
    ];

    return (
        <ControlPopover
            icon="LayerTwo"
            tooltipContent="Data Layers"
            title="Data Layers"
            placement="left"
        >
            {layerOptions.map(
                (layer) =>
                    !layer.disabled && (
                        <React.Fragment key={layer.name}>
                            <div className="lui-flex lui-w-full lui-justify-between lui-items-center lui-px-4 lui-py-2 hover:lui-bg-gray-100 ">
                                <Toggle
                                    checked={layer.state}
                                    onClick={layer.setState}
                                    label={layer.name}
                                />
                            </div>
                            {layer.state && layer.extra}
                        </React.Fragment>
                    ),
            )}
        </ControlPopover>
    );
}
