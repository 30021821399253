import {
    useParcelsInsightsRetrieve,
    useParcelsMarketInsightsRetrieve,
    useReportsRetrieve,
} from "@src/orval/gen/api";
import { CompingEstimatedPrice } from "@src/pages/comping/estimated_price";
import { CompingReportHeader } from "@src/pages/comping/header";
import { CompingReportInsights } from "@src/pages/comping/insights";
import { MetricsSection } from "@src/pages/comping/metrics/metrics_section";
import { CompingReportNotes } from "@src/pages/comping/notes";
import { CompingReportTables } from "@src/pages/comping/tables";
import { ParcelViewerMap } from "@src/pages/parcel_viewer/map";
import { useParams } from "react-router-dom";
import { CompingSection } from "./comping_section";
import { useQuery } from "@tanstack/react-query";
import { type AxiosResponse } from "axios";
import { type Region } from "@src/pages/parcel_viewer/types";
import { AXIOS_INSTANCE } from "@src/orval/axios-instance";
import CompingReportMarketAnalysis from "./market_analysis";
import { ParcelDetailTabs } from "@src/pages/parcel_viewer/controls/detail";
import { Typography } from "@src/land_ui/typography/typography";

export default function CompingReportDetails() {
    const { id: reportId } = useParams();
    const { data: report, isFetched } = useReportsRetrieve(reportId);

    const { data: parcelInsights } = useParcelsInsightsRetrieve(
        report?.parcel?.PropertyID,
        {
            query: {
                enabled: Boolean(report?.parcel),
            },
        },
    );

    const { data: marketInsights } = useParcelsMarketInsightsRetrieve(
        report?.parcel?.PropertyID,
        {
            query: {
                enabled: Boolean(report?.parcel),
            },
        },
    );

    const { data: countyMetrics } = useQuery<AxiosResponse<Region>>({
        queryKey: ["countyMetrics", report?.parcel?.FIPS],
        queryFn: async () => {
            return AXIOS_INSTANCE.get(
                `/api/county_metrics/${report?.parcel?.FIPS}/?v=4`,
            );
        },
        enabled: Boolean(report?.parcel),
    });

    if (isFetched && !report.parcel) {
        return (
            <CompingSection>
                <div className="lui-flex lui-flex-col lui-gap-5">
                    <Typography size="4xl" weight="bold">
                        This parcel no longer exists
                    </Typography>
                    <Typography size="lg">
                        This can happen for several reasons. Most likely the property
                        has been resurveyed.
                    </Typography>
                    <img
                        src="/static/images/warning.svg"
                        alt="Warning"
                        className="lui-w-1/2"
                    />
                </div>
            </CompingSection>
        );
    }
    return (
        report?.parcel && (
            <div className="lui-flex lui-flex-col lui-gap-3">
                <CompingReportHeader report={report} region={countyMetrics?.data} />
                <div className="lui-flex lui-justify-between lui-gap-3">
                    <CompingReportInsights
                        header="Parcel Insights"
                        insights={parcelInsights}
                    />
                    <CompingReportInsights
                        header="Market Insights"
                        insights={marketInsights}
                    />
                </div>
                <div className="lui-flex lui-flex-col">
                    <ParcelViewerMap
                        showLogo={false}
                        showSearchFilterControls={false}
                        showCompingLayerControl
                        showCompPropertyButton={false}
                        enableMapURLHash={false}
                        enableParcelSelection={false}
                        compingReport={report}
                        style={{ height: 600 }}
                        mapComponentStyle={{ borderRadius: "1rem" }}
                    />
                </div>

                <CompingSection>
                    <CompingEstimatedPrice reportID={reportId} />
                </CompingSection>

                <CompingSection>
                    <MetricsSection report={report} region={countyMetrics?.data} />
                </CompingSection>

                <CompingSection>
                    <CompingReportTables report={report} />
                </CompingSection>

                <CompingReportNotes report={report} />
                <CompingReportMarketAnalysis
                    parcel={report?.parcel}
                    region={countyMetrics?.data}
                />
                <CompingSection noPadding className="lui-overflow-hidden lui-pb-8">
                    <ParcelDetailTabs
                        parcel={report?.parcel}
                        county={countyMetrics?.data}
                        showToolsTab={false}
                        showMetricsTab={false}
                    />
                </CompingSection>
            </div>
        )
    );
}
