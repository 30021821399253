import { Badge } from "@src/land_ui/badge/badge";
import { CompStatusEnum } from "@src/orval/gen/model";

export function CompStatusBadge({ status }: { status: CompStatusEnum }) {
    switch (status) {
        case CompStatusEnum.SOLD:
            return <Badge variant="info">Sold</Badge>;

        case CompStatusEnum.ACTIVE:
            return <Badge variant="regular">Active</Badge>;

        case CompStatusEnum.PENDING:
            return <Badge variant="danger">Pending</Badge>;

        default:
            return <Badge variant="info">Unknown</Badge>;
    }
}
