import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { POSITIVE_NUMBER_VALIDATOR } from "@src/functions/validation_utils";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { Input } from "@src/land_ui/input/input";
import { type LandColumnDef, Table } from "@src/land_ui/table/table";
import { Typography } from "@src/land_ui/typography/typography";
import { type CompingReport } from "@src/orval/gen/model";
import { getTableColumns, type TableComp } from "@src/pages/comping/tables/index";
import { getTargetRange, PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import type { ColumnFiltersState } from "@tanstack/react-table";
import { ViewOnMapButton } from "./view_on_map_button";
import {
    calculateCompDistances,
    useComps,
    useCreateSelectedComp,
    useSelectedComps,
} from "@src/pages/comping/utils";

interface ActiveCompsSectionProps {
    report: CompingReport;
}
export function ActiveCompsTable({ report }: ActiveCompsSectionProps) {
    const zodSchema = z.object({
        from: POSITIVE_NUMBER_VALIDATOR,
        to: POSITIVE_NUMBER_VALIDATOR,
    });
    const range = getTargetRange(report?.parcel);
    const [defaultMin, defaultMax, _] = range || [
        undefined,
        undefined,
        PLACEHOLDER_LABEL,
    ];

    const {
        register,
        formState: { errors, isValid },
        watch,
    } = useForm({
        resolver: zodResolver(zodSchema),
        mode: "all",

        defaultValues: {
            from: defaultMin,
            to: defaultMax,
        },
    });

    const from = watch("from");
    const to = watch("to");

    useEffect(() => {
        if (isValid) {
            setColumnFilters((_prev) => {
                return [
                    {
                        id: "status",
                        value: "ACTIVE",
                    },
                    {
                        id: "acre",
                        value: [from, to],
                    },
                ];
            });
        }
    }, [from, to, isValid]);

    const { data: compsList, isLoading } = useComps(report?.parcel?.FIPS);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const { mutate: createSelectedComp } = useCreateSelectedComp();
    const { data: selectedComps } = useSelectedComps(report?.id);

    const columns = useMemo<LandColumnDef<TableComp>[]>(() => {
        return [
            {
                header: "City",
                accessor: "city",
            },
            ...getTableColumns({
                header: "Actions",
                accessor: "url",
                size: 100,
                cell: (row) => {
                    return (
                        <div className="lui-flex lui-gap-2">
                            <ButtonIcon
                                icon="Plus"
                                onClick={() =>
                                    createSelectedComp({
                                        reportId: report.id,
                                        data: {
                                            ...row,
                                            source_comp: row.id,
                                        },
                                    })
                                }
                                tooltipLabel="Add To Selected Comps"
                            />
                            <ButtonIcon
                                icon="ArrowUpRight"
                                tooltipLabel="Comp Link"
                                href={row.url}
                                openInNewTab
                            />
                            {Boolean(row?.geom) && (
                                <ViewOnMapButton coordinates={row.geom.coordinates} />
                            )}
                        </div>
                    );
                },
            }).filter((c) => c.header !== "Age"),
        ];
    }, [createSelectedComp, report.id]);

    const selectedCompsMap = useMemo(() => {
        const map = new Map();
        if (!selectedComps) return map;
        selectedComps.forEach((comp) => {
            map.set(comp.source_comp, true);
        });
        return map;
    }, [selectedComps]);

    const filteredComps = useMemo(() => {
        if (!compsList || !report?.parcel) {
            return [];
        }

        return calculateCompDistances(
            report.parcel,
            compsList.filter((c) => !selectedCompsMap.has(c.id)),
        );
    }, [report.parcel, compsList, selectedCompsMap]);

    return (
        <div className="lui-flex lui-flex-col lui-gap-16">
            <Table
                title="Active Comps"
                description="The comps that are currently listed for sale in the same county. You can sort the comps by clicking on the columns."
                columns={columns}
                data={filteredComps}
                isLoading={isLoading}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                isDownloadable
            >
                <form className="lui-flex lui-items-center lui-gap-4 lui-mb-4">
                    <Typography weight="medium">Acre Range</Typography>
                    <div className="lui-flex lui-gap-2">
                        <Input
                            type="text"
                            size="sm"
                            placeholder="From"
                            error={errors.from?.message}
                            {...register("from")}
                        />
                        <Input
                            type="text"
                            size="sm"
                            placeholder="To"
                            error={errors.to?.message}
                            {...register("to")}
                        />
                    </div>
                </form>
            </Table>
        </div>
    );
}
