import { isImpersonationActive, clearImpersonationToken } from "functions";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";
import { Button } from "../land_ui/button/button";

export default function BaseLayout(props) {
    const exit = () => {
        clearImpersonationToken();
        window.location.href = "/home/admin";
    };

    return (
        <ErrorBoundary>
            <div {...props}>
                {isImpersonationActive() && (
                    <div className="lui-bg-red-100 lui-flex lui-justify-center lui-p-4">
                        <Button variant="danger" onClick={exit}>
                            Exit Impersonation Mode
                        </Button>
                    </div>
                )}
                {props.children}
            </div>
        </ErrorBoundary>
    );
}
