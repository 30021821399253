import { Button } from "@src/land_ui/button/button";
import { type LandColumnDef, Table } from "@src/land_ui/table/table";
import { Toggle } from "@src/land_ui/toggle_button/Toggle";
import { Typography } from "@src/land_ui/typography/typography";
import { toNumber } from "lodash";
import { useMemo, useState } from "react";
import { Dropdown } from "@src/land_ui/dropdown/dropdown";
import { Input } from "@src/land_ui/input/input";

type User = {
    id: number;
    name: string;
    email: string;
    bio: string;
    dob: string;
    propertyPrice: string;
    acreageOwned: number;
};

const INITIAL_USERS: User[] = [
    {
        id: 500,
        name: "Zoë Zeta",
        email: "zoe@gmail.com",
        dob: "1992-06-15",
        bio: "Zoë is a software engineer with a strong background in distributed systems, microservices, and cloud computing.",
        propertyPrice: "1850000",
        acreageOwned: 1.75,
    },
    {
        id: 1,
        name: "Alice Brown",
        email: "Alice@gmail.com",
        dob: "1990-11-03",
        bio: "Alice Brown is a backend engineer who focuses on API development and database optimization.",
        propertyPrice: "430000",
        acreageOwned: 0.85,
    },
    {
        id: 42,
        name: "Bob Bobberson Bobberson",
        email: "bob@example.com",
        dob: "1988-09-10",
        bio: "Bob is an expert in DevOps and cloud infrastructure. He has experience with AWS, Kubernetes, Terraform, and CI/CD pipelines.",
        propertyPrice: "620000",
        acreageOwned: 1.25,
    },
    {
        id: 200,
        name: "Hannah White",
        email: "hannah@example.com",
        dob: "1998-10-05",
        bio: "Hannah is a mobile app developer who builds cross-platform applications with Flutter and React Native.",
        propertyPrice: "390000",
        acreageOwned: 0.65,
    },
    {
        id: 19,
        name: "David Lee",
        email: "david.lee@example.com",
        dob: "2025-02-02",
        bio: "David Lee is a game developer working with Unity and Unreal Engine. He focuses on gameplay mechanics and physics simulations.",
        propertyPrice: "780000",
        acreageOwned: 2.0,
    },
    {
        id: 99,
        name: "Émile Dupont",
        email: "emile@landinsight.com",
        dob: "1978-07-19",
        bio: "Émile is a software architect with extensive experience in enterprise application development.",
        propertyPrice: "970000",
        acreageOwned: 2.5,
    },
    {
        id: 101,
        name: "Eve Eve Eve",
        email: "eve@example.com",
        dob: "1993-08-14",
        bio: "Eve has a background in AI and machine learning. She works on natural language processing models and computer vision applications.",
        propertyPrice: "520000",
        acreageOwned: 1.1,
    },
    {
        id: 7,
        name: "Frank Frank",
        email: "daw@example.com",
        dob: "1995-12-25",
        bio: "Frank is a data scientist specializing in big data analytics and visualization.",
        propertyPrice: "850000",
        acreageOwned: 1.5,
    },
    {
        id: 8,
        name: "Grace Green",
        email: "sovie@example.com",
        dob: "1997-03-30",
        bio: "Grace is a cybersecurity expert focusing on network security and penetration testing.",
        propertyPrice: "430000",
        acreageOwned: 0.75,
    },
];

export function LandTableView() {
    const [showBioColumn, setShowBioColumn] = useState(false);
    const columns: LandColumnDef<User>[] = useMemo(() => {
        return [
            {
                header: "ID",
                accessor: (v) => v.id * 2,
                sortBy: "alphanumeric",
            },
            {
                accessor: "email",
                header: "Email",
                sortBy: "alphanumeric",
            },
            {
                accessor: "name",
                header: "Name",
            },
            {
                header: "Property Price",
                accessor: "propertyPrice",
                // This is an example of a custom sort function
                sortBy: (a, b) => toNumber(a.propertyPrice) - toNumber(b.propertyPrice),
                cell: (v) => {
                    return (
                        <>
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                            }).format(parseInt(v.propertyPrice))}
                        </>
                    );
                },
            },
            {
                header: "Acreage Owned",
                accessor: "acreageOwned",
                sortBy: "alphanumeric",
            },
            {
                accessor: "bio",
                header: "Bio",
                isHidden: !showBioColumn,
            },
            {
                header: "DOB",
                accessor: "dob",
                sortBy: "datetime",
                cell: (v) => {
                    const date = new Date(v.dob);
                    const formattedDate = new Intl.DateTimeFormat("en-US", {
                        timeZone: "UTC", // or your desired timezone
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).format(date);
                    return <Typography>{formattedDate}</Typography>;
                },
            },
            {
                header: "Subscribe",
                size: 150,
                cell: () => {
                    return (
                        <div className="lui-flex lui-flex-col lui-gap-2">
                            <Button variant="secondary" icon="Announcement">
                                Subscribe
                            </Button>
                        </div>
                    );
                },
            },
        ];
    }, [showBioColumn]);

    const [rowsData, setRowsData] = useState<User[]>(INITIAL_USERS);
    const [isLoading, setIsLoading] = useState(false);
    const [isData, setIsData] = useState(true);

    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="table" variant="h2" weight="bold" size="3xl">
                Table
            </Typography>
            <div className="lui-flex lui-flex-col lui-gap-2">
                <Table
                    title="User List"
                    description="List of users with their details"
                    columns={columns}
                    data={rowsData}
                    isLoading={isLoading}
                >
                    <div className="lui-flex lui-gap-5 lui-mb-4 lui-max-w-xl">
                        <Dropdown
                            id="statusDropdown"
                            options={[
                                { label: "All", id: "all" },
                                { label: "Active", id: "active" },
                                { label: "Inactive", id: "inactive" },
                            ]}
                            size="sm"
                            placeholder="Filter by status"
                            onSelect={() => {}}
                            selectedItem={null}
                        />
                        <Input
                            placeholder="Search by email"
                            size="sm"
                            onChange={(e) => {
                                const searchValue = e.target.value;
                                setRowsData(
                                    INITIAL_USERS.filter((user) =>
                                        user.email
                                            .toLowerCase()
                                            .includes(searchValue.toLowerCase()),
                                    ),
                                );
                            }}
                        />
                        <Input
                            placeholder="Search by email"
                            size="sm"
                            type="date"
                            onChange={(e) => {
                                const searchValue = e.target.value;
                                console.log(searchValue);
                                setRowsData(
                                    INITIAL_USERS.filter((user) =>
                                        user.dob
                                            .toLowerCase()
                                            .includes(searchValue.toLowerCase()),
                                    ),
                                );
                            }}
                        />
                    </div>
                </Table>

                <div className="lui-flex lui-flex-col lui-gap-5 lui-mt-2 lui-max-w-[200px]">
                    <Toggle
                        id="isLoadingCheckbox"
                        label="Loading..."
                        checked={isLoading}
                        onChange={() => setIsLoading(!isLoading)}
                    />

                    <Toggle
                        id="isShowBioColumn"
                        label="Show Bio"
                        checked={showBioColumn}
                        onChange={() => setShowBioColumn(!showBioColumn)}
                    />
                    <Toggle
                        id="isDataCheckbox"
                        label="Data"
                        checked={isData}
                        onChange={() => {
                            setIsData(!isData);
                            if (isData) {
                                setRowsData([]);
                            } else {
                                setRowsData(INITIAL_USERS);
                            }
                        }}
                    />

                    <Button
                        variant="danger"
                        onClick={() => {
                            const randomIndex = Math.floor(
                                Math.random() * rowsData.length,
                            );
                            setRowsData(
                                rowsData.filter((_, index) => index !== randomIndex),
                            );
                        }}
                    >
                        Remove random row
                    </Button>
                </div>
            </div>
        </div>
    );
}
