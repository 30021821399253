import "./color.css";

export type LuiColors =
    | "inherit"
    | "white"
    | "black"

    // Primary options
    | "primary-0"
    | "primary-50"
    | "primary-100"
    | "primary-200"
    | "primary-300"
    | "primary-400"
    | "primary-500"
    | "primary-600"
    | "primary-700"
    | "primary-800"
    | "primary-900"
    | "primary-1000"

    // Red options
    | "red-0"
    | "red-50"
    | "red-100"
    | "red-200"
    | "red-300"
    | "red-400"
    | "red-500"
    | "red-600"
    | "red-700"
    | "red-800"
    | "red-900"
    | "red-1000"
    // Orange options
    | "orange-0"
    | "orange-50"
    | "orange-100"
    | "orange-200"
    | "orange-300"
    | "orange-400"
    | "orange-500"
    | "orange-600"
    | "orange-700"
    | "orange-800"
    | "orange-900"
    | "orange-1000"

    // Green options
    | "green-0"
    | "green-50"
    | "green-100"
    | "green-200"
    | "green-300"
    | "green-400"
    | "green-500"
    | "green-600"
    | "green-700"
    | "green-800"
    | "green-900"
    | "green-1000"

    // Gray options
    | "gray-0"
    | "gray-50"
    | "gray-100"
    | "gray-200"
    | "gray-300"
    | "gray-400"
    | "gray-500"
    | "gray-600"
    | "gray-700"
    | "gray-800"
    | "gray-900"
    | "gray-1000"

    // Blue options
    | "blue-0"
    | "blue-50"
    | "blue-100"
    | "blue-200"
    | "blue-300"
    | "blue-400"
    | "blue-500"
    | "blue-600"
    | "blue-700"
    | "blue-800"
    | "blue-900"
    | "blue-1000";

export const getColorClassName = (color: LuiColors) => {
    return `lui-color-${color}`;
};
