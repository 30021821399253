import { useState, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { type CompingReport } from "@src/orval/gen/model";
import {
    getReportsAdjustmentsListQueryKey,
    getReportsSavedCompsListQueryKey,
    useReportsAutoSelectCompsCreate,
} from "@src/orval/gen/api";
import { Table } from "@src/land_ui/table/table";
import { Button } from "@src/land_ui/button/button";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { Modal } from "@src/land_ui/modal/modal";
import { getTableColumns } from "@src/pages/comping/tables/index";
import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";
import { ViewOnMapButton } from "./view_on_map_button";
import {
    calculateCompDistances,
    DeleteModal,
    useDeleteSelectedComp,
    useSelectedComps,
} from "@src/pages/comping/utils";

interface SelectedCompsSectionProps {
    report: CompingReport;
}

export default function SelectedCompsTable({ report }: SelectedCompsSectionProps) {
    const { mutate: deleteSelectedComp, isPending, error } = useDeleteSelectedComp();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteParams, setDeleteParams] = useState(null);

    const { isLoading, data } = useSelectedComps(report?.id);
    const selectedComps = useMemo(() => {
        return calculateCompDistances(report?.parcel, data);
    }, [report?.parcel, data]);

    //TODO: render outdated saved comps greyed out or with some other indicator. this can
    // be detected by comparing SavedComp.updated_at to SavedComp.source_comp_record.updated_at

    const selectedTableColumns = useMemo(() => {
        return getTableColumns({
            header: "Actions",
            accessor: "url",
            size: 100,
            cell: (row) => {
                return (
                    <div className="lui-flex lui-gap-2">
                        <ButtonIcon
                            icon="Trash"
                            color="red-700"
                            onClick={() => {
                                const params = {
                                    reportId: report.id,
                                    id: row.id,
                                };
                                if (!row?.source_comp_record) {
                                    setDeleteParams(params);
                                    setDeleteModalOpen(true);
                                } else {
                                    deleteSelectedComp(params);
                                }
                            }}
                            tooltipLabel="Remove From Selected Comps"
                        />
                        {row.url && (
                            <ButtonIcon
                                icon="ArrowUpRight"
                                tooltipLabel="Comp Link"
                                href={row.url}
                                openInNewTab
                            />
                        )}
                        {Boolean(row?.geom) && (
                            <ViewOnMapButton coordinates={row.geom.coordinates} />
                        )}
                    </div>
                );
            },
        });
    }, [report.id, deleteSelectedComp]);

    return (
        <div className="lui-flex lui-flex-col lui-gap-4">
            <Table
                title="Selected Comps"
                description="The comps you've selected to be used to calculate the market value of the property. It's recommended to select comps based on proximity, recency of sales and similarity of features."
                actionButton={<AutoSelectCompsButton reportID={report.id} />}
                columns={selectedTableColumns}
                data={selectedComps}
                isLoading={isLoading}
                isDownloadable
            />
            <DeleteModal
                isOpen={deleteModalOpen}
                setIsOpen={setDeleteModalOpen}
                deleteParams={deleteParams}
                setDeleteParams={setDeleteParams}
                mutate={deleteSelectedComp}
                isPending={isPending}
                error={error}
                message="This comp is older than a year and has been removed from our database. If you remove it now, you can’t add it back. Are you sure you want to proceed?"
            />
        </div>
    );
}

interface AutoSelectCompsButtonProps {
    reportID: string;
}

function AutoSelectCompsButton({ reportID }: AutoSelectCompsButtonProps) {
    const queryClient = useQueryClient();
    const {
        mutate: createAutoComps,
        isPending,
        isError,
        reset,
    } = useReportsAutoSelectCompsCreate({
        mutation: {
            onSuccess: (data, { id: reportID }) => {
                queryClient.invalidateQueries({
                    queryKey: getReportsSavedCompsListQueryKey(reportID),
                });
                queryClient.invalidateQueries({
                    queryKey: getReportsAdjustmentsListQueryKey(reportID),
                });
            },
        },
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <AutoCompsConfirmationModal
                isOpen={isModalOpen || isPending}
                setIsOpen={setIsModalOpen}
                isLoading={isPending}
                onConfirm={() => {
                    setIsModalOpen(false);
                    createAutoComps({ id: reportID });
                }}
            />
            <Button variant="base" onClick={() => setIsModalOpen(true)}>
                <Typography
                    variant="span"
                    weight="medium"
                    color="blue-500"
                    className="lui-mx-4 lui-flex lui-gap-2 lui-items-center"
                >
                    <Icon name="MagicWand" color="inherit" />
                    Auto Select Comps
                </Typography>
            </Button>
            <AutoCompsErrorModal isOpen={isError} setIsOpen={() => reset()} />
        </>
    );
}

interface AutoCompsConfirmationModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    isLoading: boolean;
    onConfirm: () => void;
}

function AutoCompsConfirmationModal({
    isOpen,
    setIsOpen,
    isLoading,
    onConfirm,
}: AutoCompsConfirmationModalProps) {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <Modal.Header preventAutoFocus>Auto Select Comps</Modal.Header>
            <Modal.Content className="lui-p-6 lui-flex lui-flex-col lui-gap-2">
                The current selected comps will be cleared. Do you want to continue?
            </Modal.Content>
            <Modal.Footer className="lui-flex lui-justify-end">
                <Button
                    variant="base"
                    className="lui-text-start lui-py-2 lui-px-4"
                    onClick={() => setIsOpen(false)}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    className="lui-py-2 lui-px-6"
                    isLoading={isLoading}
                    onClick={() => onConfirm()}
                >
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

interface AutoCompsErrorModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function AutoCompsErrorModal({ isOpen, setIsOpen }: AutoCompsErrorModalProps) {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <Modal.Header preventAutoFocus>Auto Select Comps Error</Modal.Header>
            <Modal.Content className="lui-p-6 lui-flex lui-flex-col lui-gap-2">
                There was an unexpected error adding auto selecting comps. Please try
                again later.
            </Modal.Content>
            <Modal.Footer className="lui-flex lui-justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    className="lui-py-2 lui-px-6"
                    onClick={() => setIsOpen(false)}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
