import { LngLatBounds } from "mapbox-gl";
import { type BBox } from "geojson";
import { distance, point } from "@turf/turf";
import { type Parcel } from "@src/pages/parcel_viewer/types";

type PointType = number[] | readonly number[];

export function getDistanceInMiles(a: PointType, b: PointType) {
    // Casting the type since readonly and number[] shouldn't be impacted here
    const pointA = point(a as number[]);
    const pointB = point(b as number[]);
    return distance(pointA, pointB, { units: "miles" }).toFixed(2);
}

// Return true if geojson BBox is valid Mapbox LatLngBounds.
export function isLatLngBounds(
    bounds: BBox,
): bounds is [number, number, number, number] {
    return bounds != null && bounds.length === 4 && !bounds.some((n) => isNaN(n));
}

export function getParcelBounds(parcel: Parcel): LngLatBounds {
    const lng0 = parcel.bbox ? parcel.bbox[0] : parcel.point[0];
    const lat0 = parcel.bbox ? parcel.bbox[1] : parcel.point[1];
    const lng1 = parcel.bbox ? parcel.bbox[2] : parcel.point[0];
    const lat1 = parcel.bbox ? parcel.bbox[3] : parcel.point[1];
    return new LngLatBounds([lng0, lat0], [lng1, lat1]);
}

export function getParcelListBounds(parcels: Parcel[]): LngLatBounds {
    if (!parcels || parcels.length === 0) {
        return null;
    }
    const bounds = new LngLatBounds();
    for (const parcel of parcels) {
        bounds.extend(getParcelBounds(parcel));
    }
    return bounds;
}
