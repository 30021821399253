import * as z from "zod";

const isNullOrEmpty = (val: any) => val === null || val === undefined || val === "";

// Parse empty number fields as null instead of using z.coerce.number() because
// Number(null) and Number("") both return 0 instead of null.
// Ref: https://github.com/colinhacks/zod/discussions/2814
// Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
const nullNumber = (val: unknown): number | null =>
    isNullOrEmpty(val) ? null : Number(val);

export const US_ZIP_CODE_VALIDATOR = z
    .string()
    .optional() // undefined if missing from Saved List payload
    .nullable() // null when cleared via setValue()
    .refine((val) => isNullOrEmpty(val) || /^\d{5}?$/.test(val), {
        message: "Invalid US ZIP code",
    });

export const POSITIVE_NUMBER_VALIDATOR = z.preprocess(
    nullNumber,
    z.number({ message: "Please enter a number" }).min(0).nullable(),
);

export const POSITIVE_INTEGER_VALIDATOR = z.preprocess(
    nullNumber,
    z
        .number({ message: "Please enter a number" })
        .int({ message: "Please enter a whole number" })
        .min(0)
        .optional()
        .nullable(),
);

export const INTEGER_PERCENT_VALIDATOR = z.preprocess(
    nullNumber,
    z
        .number({ message: "Please enter a number" })
        .int({ message: "Please enter a whole number" })
        .min(0)
        .max(100)
        .optional()
        .nullable(),
);

export const VALID_YEAR_VALIDATOR = z
    .string()
    .nullable()
    .refine((val) => isNullOrEmpty(val) || /^\d{4}?$/.test(val), {
        message: "Invalid year (must be YYYY format)",
    });
