import { Button } from "@src/land_ui/button/button";
import { useParcelViewerContext } from "@src/pages/parcel_viewer/context";

export function DebugControl() {
    const { toggleTileBoundaries } = useParcelViewerContext();
    return (
        <Button
            variant="secondary"
            icon="DotGrid"
            onClick={() => {
                toggleTileBoundaries();
            }}
        />
    );
}
