import { useEffect, useState } from "react";
import { ButtonIcon } from "./button_icon";
import { type LuiColors } from "@src/land_ui/color/color";
import { type IconSizeType } from "@src/land_ui/icon/icon";

// Create copy to clipboard component
interface CopyButtonProps {
    text: string;
    className?: string;
    size?: IconSizeType;
    color?: LuiColors;
}

export function CopyButton({
    text,
    size = "sm",
    color = "gray-900",
    className,
}: CopyButtonProps) {
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (isClicked) {
            setTimeout(() => {
                setIsClicked(false);
            }, 2000);
        }
    }, [isClicked]);

    return (
        <ButtonIcon
            onClick={() => {
                copyToClipboard(text, {});
                setIsClicked(true);
            }}
            icon={isClicked ? "CheckCircle" : "Copy"}
            tooltipLabel={isClicked ? "Copied!" : "Copy"}
            size={size}
            color={color}
            className={className}
        />
    );
}

function deselectCurrent() {
    const selection = document.getSelection();
    if (!selection.rangeCount) {
        return function () {};
    }
    let active = document.activeElement;

    const ranges: Range[] = [];
    for (let i = 0; i < selection.rangeCount; i++) {
        ranges.push(selection.getRangeAt(i));
    }

    switch (
        active.tagName.toUpperCase() // .toUpperCase handles XHTML
    ) {
        case "INPUT":
        case "TEXTAREA":
            if (active instanceof HTMLElement) {
                active.blur();
            }
            break;

        default:
            active = null;
            break;
    }

    selection.removeAllRanges();
    return function () {
        selection.type === "Caret" && selection.removeAllRanges();

        if (!selection.rangeCount) {
            ranges.forEach(function (range) {
                selection.addRange(range);
            });
        }

        if (active instanceof HTMLElement) {
            active.focus();
        }
    };
}

const clipboardToIE11Formatting = {
    "text/plain": "Text",
    "text/html": "Url",
    default: "Text",
};

const defaultMessage = "Copy to clipboard: #{key}, Enter";

function formatMessage(message: string) {
    const copyKey = `${/mac os x/i.test(navigator.userAgent) ? "⌘" : "Ctrl"}+C`;
    return message.replace(/#{\s*key\s*}/g, copyKey);
}

function copyToClipboard(text: string, options: any) {
    const debug = options.debug || false;
    let message: string | undefined;
    let reselectPrevious: () => void;
    let range: Range | undefined;
    let selection: Selection | undefined;
    let mark: HTMLElement | undefined;
    let success: boolean = false;

    if (!options) {
        options = {};
    }

    try {
        reselectPrevious = deselectCurrent();

        range = document.createRange();
        selection = document.getSelection();

        mark = document.createElement("span");
        mark.textContent = text;
        // avoid screen readers from reading out loud the text
        mark.ariaHidden = "true";
        // reset user styles for span element
        mark.style.all = "unset";
        // prevents scrolling to the end of the page
        mark.style.position = "fixed";
        mark.style.top = "0";
        mark.style.clip = "rect(0, 0, 0, 0)";
        // used to preserve spaces and line breaks
        mark.style.whiteSpace = "pre";
        // do not inherit user-select (it may be `none`)
        mark.style.webkitUserSelect = "text";
        // @ts-ignore
        mark.style.MozUserSelect = "text";
        // @ts-ignore
        mark.style.msUserSelect = "text";
        mark.style.userSelect = "text";
        mark.addEventListener("copy", function (e) {
            e.stopPropagation();
            if (options.format) {
                e.preventDefault();
                if (typeof e.clipboardData === "undefined") {
                    // IE 11
                    // @ts-ignore

                    debug && console.warn("unable to use e.clipboardData");
                    // @ts-ignore
                    debug && console.warn("trying IE specific stuff");
                    // @ts-ignore
                    window.clipboardData.clearData();
                    const format =
                        // @ts-ignore
                        clipboardToIE11Formatting[options.format] ||
                        clipboardToIE11Formatting["default"];
                    // @ts-ignore
                    window.clipboardData.setData(format, text);
                } else {
                    // all other browsers
                    e.clipboardData.clearData();
                    e.clipboardData.setData(options.format, text);
                }
            }
            if (options.onCopy) {
                e.preventDefault();
                options.onCopy(e.clipboardData);
            }
        });

        document.body.appendChild(mark);

        range.selectNodeContents(mark);
        selection.addRange(range);

        const successful = document.execCommand("copy");
        if (!successful) {
            throw new Error("copy command was unsuccessful");
        }
        success = true;
    } catch (err) {
        debug && console.error("unable to copy using execCommand: ", err);
        debug && console.warn("trying IE specific stuff");
        try {
            // @ts-ignore
            window.clipboardData.setData(options.format || "text", text);
            // @ts-ignore
            options.onCopy && options.onCopy(window.clipboardData);
            success = true;
        } catch (err2) {
            debug && console.error("unable to copy using clipboardData: ", err2);
            debug && console.error("falling back to prompt");
            message = formatMessage(
                "message" in options ? options.message : defaultMessage,
            );
            window.prompt(message, text);
        }
    } finally {
        if (selection) {
            if (typeof selection.removeRange == "function") {
                selection.removeRange(range);
            } else {
                selection.removeAllRanges();
            }
        }

        if (mark) {
            document.body.removeChild(mark);
        }
        reselectPrevious();
    }

    return success;
}
