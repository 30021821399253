import { useState } from "react";
import clsx from "clsx";
import { Typography } from "@src/land_ui/typography/typography";

import "./tabs.css";

type TabOption = {
    title: string;
    content: React.ReactNode;
};

type TabsProps = {
    options: TabOption[];
    defaultTab?: number;
    menuClassName?: string;
};

/**
 * LUI Tabs component
 *  — Component to display tabs with content, handles internal state for active tab
 *
 * @example
 * <Tabs options={[
        {title: 'Summary', content: <div>Summary</div>},
        {title: 'Parcel', content: <div>Parcel</div>},
        {title: 'Owner', content: <div>Owner</div>}]} />
 */
export function Tabs({
    options,
    menuClassName = "lui-gap-4",
    defaultTab = 0,
}: TabsProps) {
    const [currentTab, setCurrentTab] = useState(defaultTab);

    return (
        <div className="lui-tab-container">
            <ul className={clsx(["lui-tab-menu", "lui-shadow-sm", menuClassName])}>
                {options
                    .filter((option) => option)
                    .map((option, i) => {
                        return (
                            <li key={i} className="lui-tab-menu-item">
                                <button
                                    className={clsx("lui-tab-menu-button", {
                                        "lui-tab-menu-button--active": currentTab === i,
                                    })}
                                    onClick={() => setCurrentTab(i)}
                                >
                                    <Typography
                                        variant="span"
                                        weight="medium"
                                        size="md"
                                        color={
                                            currentTab === i
                                                ? "primary-500"
                                                : "gray-700"
                                        }
                                    >
                                        {option.title}
                                    </Typography>
                                </button>

                                {currentTab === i && (
                                    <div className="lui-tab-menu-line--active" />
                                )}
                            </li>
                        );
                    })}
            </ul>

            <div className="lui-tab-content">
                {options.map((option, i) => {
                    if (currentTab !== i) {
                        return null;
                    }

                    return <div key={`lui-tab-content-${i}`}>{option.content}</div>;
                })}
            </div>
        </div>
    );
}
