import dayjs, { type Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const FORMATS = {
    SHORT_DATE: "MM/DD/YYYY",
    LONG_DATE: "MMMM D, YYYY",
    RELATIVE: "relative",
} as const;

type FormatKeys = keyof typeof FORMATS;

export type FormatDateType = string | Date | Dayjs;

export function formatDate(date: FormatDateType, format: FormatKeys = "SHORT_DATE") {
    const dateValue = dayjs(date);
    if (!dateValue.isValid()) {
        return "";
    }

    if (format === "RELATIVE") {
        return dateValue.fromNow();
    }

    return dateValue.format(FORMATS[format]);
}
