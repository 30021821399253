import { GaugeComponent } from "react-gauge-component";
import { Typography } from "@src/land_ui/typography/typography";
import clsx from "clsx";
import { useMemo } from "react";

export const GAUGE_COLORS = ["#D4583B", "#F48A48", "#F4B548", "#11A146"];
export const DISABLED_COLORS = ["#E8E8E8"];
interface GaugeProps {
    // Percentage between 0 - 100
    percentage: number;
    // Label to display in the center of the gauge
    label: string;
    // SubLabel to display below the label
    subLabel: string;
    title: string;

    disabled?: boolean;
}
export function Gauge({ percentage, label, subLabel, title, disabled }: GaugeProps) {
    const value = useMemo(() => Math.max(5, Math.min(percentage, 95)), [percentage]);

    return (
        <div className="lui-relative lui-gauge">
            <GaugeComponent
                className="lui-flex-grow "
                type="semicircle"
                arc={{
                    colorArray: disabled ? DISABLED_COLORS : GAUGE_COLORS,
                    cornerRadius: 20,
                    nbSubArcs: 4,
                    subArcs: [
                        { limit: 25 },
                        { limit: 50 },
                        { limit: 75 },
                        { limit: 100 },
                    ],
                }}
                pointer={{
                    type: "blob",
                    // The blob was not showing / hiding when `disabled` was updated
                    // hide: disabled
                    hide: false,
                }}
                labels={{
                    valueLabel: {
                        hide: true,
                    },
                    tickLabels: {
                        hideMinMax: true,
                    },
                }}
                value={disabled ? 5 : value}
            />

            <div className="lui-absolute lui-top-[50%] lui-text-center lui-w-full lui-flex lui-flex-col lui-justify-center">
                <div
                    className={clsx({
                        "lui-text-[#d4583b]":
                            !disabled && percentage < 25 && percentage >= 0,
                        "lui-text-[#F48A48]":
                            !disabled && percentage < 50 && percentage >= 25,
                        "lui-text-[#F4B548]":
                            !disabled && percentage >= 50 && percentage <= 75,
                        "lui-text-[#11A146]": !disabled && percentage >= 75,
                        // Disabled color
                        "lui-text-[#E8E8E8]": disabled,
                    })}
                >
                    <Typography size="2xl" color="inherit" weight="bold">
                        {label}
                    </Typography>
                </div>

                <Typography size="sm" color="gray-700" className="lui-mt-0.5">
                    {subLabel}
                </Typography>
                <Typography
                    size="md"
                    weight="bold"
                    color="gray-1000"
                    className="lui-mt-2.5"
                >
                    {title}
                </Typography>
            </div>
        </div>
    );
}
