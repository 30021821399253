import MapboxDraw, { type DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import { type Feature, type Position, type Polygon, type MultiPolygon } from "geojson";
import { useCallback, useMemo, useState } from "react";
import { useControl } from "react-map-gl";
import { Button } from "@src/land_ui/button/button";
import { useParcelViewerContext } from "@src/pages/parcel_viewer/context";
import MapboxPopup from "@src/components/mapbox_popup";

// @ts-ignore
import CutPolygonMode from "mapbox-gl-draw-cut-polygon-mode";
import { kinks } from "@turf/turf";
import { useDebounceCallback } from "usehooks-ts";
import { first } from "lodash";

export type FeaturePolygon = Feature<Polygon | MultiPolygon> & { id: string };

interface MapboxEvent {
    features: FeaturePolygon[];
    action?: string;
}
type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    onCreate: (evt: MapboxEvent) => void;
    onUpdate: (evt: MapboxEvent) => void;
    onDelete: (evt: MapboxEvent) => void;
    onError: () => void;
};

const CUT_POLYGON_MODE_NAME = "cut_polygon_passing_draw_polygon";
const DRAW_POLYGON_NAME = "draw_polygon";

export function usePolygon(props: DrawControlProps) {
    const { setActivePolygon, isPolygonActive } = useParcelViewerContext();
    const [selectedPosition, setSelectedPosition] = useState<Position>([]);

    const polygon = useControl<MapboxDraw>(
        () => cutPolygonMode,
        ({ map }) => {
            map.on("draw.create", handleCreate);

            map.on("draw.update", handleUpdate);

            map.on("draw.selectionchange", (event: MapboxEvent) => {
                setPopoverPosition(event.features);
                setActivePolygon(true);
            });

            map.on("draw.render", () => {
                try {
                    const currentMode = cutPolygonMode.getMode();
                    if (
                        currentMode === CUT_POLYGON_MODE_NAME ||
                        currentMode === DRAW_POLYGON_NAME
                    ) {
                        setActivePolygon(true);
                    } else {
                        setActivePolygon(false);
                    }
                } catch (e) {
                    // This error occurs more frequently in local development.
                    // It would be beneficial to report this error to Sentry if it occurs in production.
                    console.log(e);
                }
            });

            map.on("zoom", reFocusControl);
            map.on("move", reFocusControl);
        },
        ({ map }) => {
            // Cleanup event listeners, and reset the state when the map control is unmounted
            setSelectedPosition([]);
            setActivePolygon(false);

            // Remove event listeners to avoid memory leaks
            map.off("draw.create", handleCreate);
            map.off("draw.update", handleUpdate);
            map.off("zoom", reFocusControl);
            map.off("move", reFocusControl);
        },
    );

    const reFocusControl = useDebounceCallback(
        // Ensures the tooltip is repositioned correctly when the user zooms in/out
        // This prevents the tooltip from being hidden off-screen
        () => {
            setSelectedPosition([]);
            setTimeout(() => {
                try {
                    const selected = polygon.getSelected();
                    if (selected?.features?.length > 0) {
                        setPopoverPosition(selected?.features as FeaturePolygon[]);
                    }
                } catch (e) {
                    // This error occurs more frequently in local development.
                    // It would be beneficial to report this error to Sentry if it occurs in production.
                    console.log(e);
                }
            }, 1);
        },
        300,
    );
    const setPopoverPosition = useCallback((features: FeaturePolygon[]) => {
        if (features.length > 0) {
            const { geometry } = first(features);
            let coords: Position[] = [];

            if (geometry.type === "Polygon") {
                coords = first(geometry.coordinates);
            } else if (geometry.type === "MultiPolygon") {
                coords = first(first(geometry.coordinates));
            }

            if (coords.length) {
                const center = first(coords); // Get the first point in the polygon
                setSelectedPosition(center);
                return;
            }
        }
        setSelectedPosition([]);
    }, []);

    const handleDeleteFeature = useCallback(
        (features: FeaturePolygon[]) => {
            if (features.length === 0 || !polygon?.delete) {
                return;
            }

            const ids = features.map((f) => f.id);
            polygon.delete(ids);
            props.onDelete({ features });
            setSelectedPosition([]);
            setActivePolygon(false);
        },
        [polygon, props, setActivePolygon],
    );

    const validateFeatures = useCallback(
        (event: MapboxEvent) => {
            for (const feature of event.features) {
                if (kinks(feature).features.length > 0) {
                    props.onError();
                    return false;
                }
            }
            return true;
        },
        [props],
    );

    const handleCreate = useCallback(
        (event: MapboxEvent) => {
            if (!validateFeatures(event)) {
                return;
            }
            props.onCreate(event);
            setPopoverPosition(event.features);
            setActivePolygon(true);
        },
        [validateFeatures, props, setActivePolygon, setPopoverPosition],
    );

    const handleUpdate = useCallback(
        (event: MapboxEvent) => {
            if (!validateFeatures(event)) {
                return;
            }
            props.onUpdate(event);
            if (event.action === "move") {
                // Show the tooltip if the user is dragging the polygon around the map
                setPopoverPosition(event.features);
            }
        },
        [validateFeatures, props, setPopoverPosition],
    );

    const SettingTooltip = useMemo(() => {
        if (selectedPosition.length === 0 || isPolygonActive) {
            return null;
        }

        return (
            <MapboxPopup
                latitude={selectedPosition[1]}
                longitude={selectedPosition[0]}
                onClose={() => {}}
                offset={20}
            >
                <div className="lui-flex lui-justify-center lui-gap-2">
                    <Button
                        icon="Scissors"
                        onClick={() => {
                            try {
                                polygon.changeMode("cut_polygon");
                                setActivePolygon(true);
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    ></Button>{" "}
                    <Button
                        variant="danger"
                        icon="Trash"
                        onClick={() => {
                            const selected = polygon.getSelected();
                            handleDeleteFeature(selected.features as FeaturePolygon[]);
                        }}
                    ></Button>
                </div>
            </MapboxPopup>
        );
    }, [
        handleDeleteFeature,
        polygon,
        selectedPosition,
        setActivePolygon,
        isPolygonActive,
    ]);

    const syncPolygons = useCallback(
        (features: FeaturePolygon[]) => {
            // Only sync after the polygon has been created
            setTimeout(() => {
                polygon.deleteAll();
                features.forEach((feature) => {
                    polygon.add(feature);
                });
            }, 50);
        },
        [polygon],
    );
    const setPolygonActive = useCallback(
        (isActive: boolean) => {
            if (polygon) {
                if (!isActive) {
                    polygon.changeMode("static");
                    setPopoverPosition([]);
                } else {
                    polygon.changeMode("simple_select");
                }
            }
        },
        [polygon, setPopoverPosition],
    );
    return {
        polygon,
        selectedPosition,
        setSelectedPosition,
        deletePolygon: handleDeleteFeature,
        SettingTooltip,
        syncPolygons,
        setPolygonActive,
    };
}

// TODO: best to declare all these variables in js instead of duplicating them
const primary500 = "#11a146";

const styles = [
    // Polygons
    //   Solid fill
    //   Active state defines color
    {
        id: "gl-draw-polygon-fill",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"]],
        paint: {
            "fill-color": primary500,
            "fill-opacity": 0.3,
        },
    },

    {
        id: `cut-fill-active`,
        type: "fill",
        filter: ["all", ["==", "user_cut", "fds"]],
        paint: {
            "fill-color": "red",
            "fill-opacity": 0.5,
        },
    },

    // Lines
    // Polygon
    //   Matches Lines AND Polygons
    //   Active state defines color
    {
        id: "gl-draw-lines",
        type: "line",
        filter: ["any", ["==", "$type", "LineString"], ["==", "$type", "Polygon"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-width": 4,
            "line-color": primary500,
        },
    },
    // Points
    //   Circle with an outline
    //   Active state defines size and color
    {
        id: "gl-draw-point-outer",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"]],
        paint: {
            "circle-color": primary500,
            "circle-radius": 6,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 2,
        },
    },
    {
        id: "gl-draw-point-inner",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"]],
        paint: {
            "circle-color": primary500,
            "circle-radius": 6,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 2,
        },
    },

    {
        id: "gl-draw-vertex-inner",
        type: "circle",
        filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "meta", "vertex"],
            ["!=", "mode", "simple_select"],
        ],
        paint: {
            "circle-color": [
                "case",
                ["==", ["get", "active"], "true"],
                primary500,
                "#fff",
            ],
            "circle-radius": 5,
            "circle-stroke-color": [
                "case",
                ["==", ["get", "active"], "true"],
                "#fff",
                primary500,
            ],

            "circle-stroke-width": ["case", ["==", ["get", "active"], "true"], 3, 4],
        },
    },
    // Midpoint
    //   Visible when editing polygons and lines
    //   Tapping or dragging them adds a new vertex to the feature
    {
        id: "gl-draw-midpoint",
        type: "circle",
        filter: ["all", ["==", "meta", "midpoint"]],
        paint: {
            "circle-color": "#fff",
            "circle-radius": 3,
            "circle-stroke-color": primary500,
            "circle-stroke-width": 2,
        },
    },
];

const StaticMode: DrawCustomMode = {
    toDisplayFeatures: (state, geojson, display) => {
        display(geojson);
    },
};
StaticMode.onSetup = function () {
    this.setActionableState({
        combineFeatures: false,
        uncombineFeatures: false,
        trash: false,
    });
    return {};
};

StaticMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson);
};

const cutPolygonMode = new MapboxDraw({
    displayControlsDefault: false,

    controls: {},
    modes: {
        ...CutPolygonMode(MapboxDraw.modes),
        static: StaticMode,
    },
    userProperties: true,
    styles,
});
