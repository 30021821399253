import { useCompsList } from "@src/orval/gen/api";
import type { CompingReport } from "@src/orval/gen/model";
import {
    formatCurrencyInKs,
    getDecimalValue,
} from "@src/pages/parcel_viewer/controls/detail";
import { useMemo } from "react";
import { CompingGauge } from "./comping_gauge";
import { CompingSectionContainer } from "./comping_section_container";
import { isNil } from "lodash";
import { PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import { useManualCompValues, useSelectedComps } from "@src/pages/comping/utils";

export function CompingScoresSection({ report }: { report: CompingReport }) {
    const { data: selectedComps } = useSelectedComps(report?.id);
    const { data: activeCompsList, isLoading } = useCompsList({
        fips: report?.parcel?.FIPS,
        status: ["ACTIVE"],
    });
    const { averagePpa, fullPrice } = useManualCompValues(report);
    const nextLogicalSalePercentage = useMemo(() => {
        if (isLoading) {
            return 0;
        }

        const lessThanComps = activeCompsList?.filter((comp) => {
            return averagePpa < comp.price / comp.lot_size;
        });

        return getDecimalValue((lessThanComps.length / activeCompsList.length) * 100);
    }, [activeCompsList, averagePpa, isLoading]);

    const makesMoneyScale = useMemo(() => {
        const lastPurchasePrice = report.parcel.CurrentSalesPrice;
        const profit = fullPrice - lastPurchasePrice;
        if (profit <= 0) {
            return 0; // should be 0 just trying to fix styling problem
        }
        if (profit > 0) {
            return 50;
        }
        return 100;
    }, [fullPrice, report.parcel.CurrentSalesPrice]);

    const makesMoneyDisplayInKs = useMemo(() => {
        const lastPurchasePrice = report.parcel.CurrentSalesPrice;
        if (isNil(lastPurchasePrice)) {
            return PLACEHOLDER_LABEL;
        }
        const profit = fullPrice - lastPurchasePrice;
        return formatCurrencyInKs(profit);
    }, [fullPrice, report.parcel.CurrentSalesPrice]);

    return (
        <CompingSectionContainer title="Comping Scores">
            <CompingGauge
                percentage={nextLogicalSalePercentage}
                label={`${nextLogicalSalePercentage}%`}
                subLabel="Below All On Market PPAs"
                title="Next Logical Sale"
                tooltipLabel="The more competitive your pricing compared to other similar properties, the more likely your property will sell next."
                disabled={!selectedComps?.length}
            />
            <CompingGauge
                percentage={((selectedComps?.length ?? 0) / 4) * 100}
                label={`${selectedComps?.length || 0}/4`}
                subLabel="Selected"
                title="Suggested Comp Count"
                tooltipLabel="We recommend using between 3 to 4 comparable properties to get a read on market value. Of course, this always depends on the available comps. Sometimes a single comparable is all you need."
                disabled={!selectedComps?.length}
            />
            <CompingGauge
                percentage={makesMoneyScale}
                label={makesMoneyDisplayInKs}
                subLabel="Owner Purchase Price"
                title="Owner Makes Money"
                tooltipLabel="If you were to offer the owner the calculated comp value, would they make money based on the price they initially purchased at?"
                disabled={
                    isNil(report.parcel.CurrentSalesPrice) || !selectedComps?.length
                }
            />
        </CompingSectionContainer>
    );
}
