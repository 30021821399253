import type { FullParcel } from "@src/orval/gen/model";
import type { Region } from "@src/pages/parcel_viewer/types";
import {
    getMetricsRange,
    getParcelMetrics,
    getTargetRange,
    PLACEHOLDER_LABEL,
} from "@src/pages/parcel_viewer/utils";

import { CompingSection } from "@src/pages/comping/comping_section";
import { Typography } from "@src/land_ui/typography/typography";
import { type LandColumnDef, Table } from "@src/land_ui/table/table";
import { ENTIRE_COUNTY_RANGE } from "./metrics/market_score_section";
import { GAUGE_COLORS } from "@src/land_ui/charts/gauge";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";

interface CompingReportMarketAnalysisProps {
    parcel: FullParcel;
    region: Region;
}

interface MarketAnalysisColumn {
    duration: string;
    county_str: number;
    target_str: number;
    total_sold: number;
}

export default function CompingReportMarketAnalysis({
    parcel,
    region,
}: CompingReportMarketAnalysisProps) {
    const stats = region?.stats || [];
    const targetMetrics = getParcelMetrics(parcel, region);
    const targetRange = getTargetRange(parcel);
    const columnLabel = targetRange ? targetRange[2] : PLACEHOLDER_LABEL;
    const countyMetrics = getMetricsRange(stats, ENTIRE_COUNTY_RANGE);
    const rows: MarketAnalysisColumn[] = [
        {
            duration: "30 Day",
            county_str: countyMetrics.STR_1_MONTH,
            target_str: targetMetrics.STR_1_MONTH,
            total_sold: countyMetrics.SOLD_1_MONTH,
        },
        {
            duration: "90 Day",
            county_str: countyMetrics.STR_3_MONTHS,
            target_str: targetMetrics.STR_3_MONTHS,
            total_sold: countyMetrics.SOLD_3_MONTHS,
        },
        {
            duration: "180 Day",
            county_str: countyMetrics.STR_6_MONTHS,
            target_str: targetMetrics.STR_6_MONTHS,
            total_sold: countyMetrics.SOLD_6_MONTHS,
        },
        {
            duration: "360 Day",
            county_str: countyMetrics.STR_1_YEAR,
            target_str: targetMetrics.STR_1_YEAR,
            total_sold: countyMetrics.SOLD_1_YEAR,
        },
    ];

    //TODO: This should probably use different thresholds than the gauges, and ideally
    //      would map to lui defined colors instead of custom styles.
    const formatPercent = (value: number) => {
        const colorIndex = Math.min(
            Math.floor(value / (100 / GAUGE_COLORS.length)),
            GAUGE_COLORS.length - 1,
        );
        const activeColor = GAUGE_COLORS[colorIndex];
        return (
            <Typography weight="bold" style={{ color: activeColor }}>
                {value}%
            </Typography>
        );
    };

    const tableColumns: LandColumnDef<MarketAnalysisColumn>[] = [
        {
            header: "Sell Through",
            accessor: "duration",
            sortBy: "alphanumeric",
        },
        {
            header: "County",
            accessor: "county_str",
            sortBy: "alphanumeric",
            cell: (rowData) => formatPercent(rowData.county_str),
        },
        {
            header: columnLabel,
            accessor: "target_str",
            sortBy: "alphanumeric",
            cell: (rowData) => formatPercent(rowData.target_str),
        },
        {
            header: "Total Sold",
            accessor: "total_sold",
            sortBy: "alphanumeric",
        },
    ];
    return (
        <CompingSection>
            <Table
                columns={tableColumns}
                data={rows}
                title={"Market Analysis"}
                actionButton={<TutorialButton youtubeId="kq8dy7bN_tI" />}
            />
        </CompingSection>
    );
}
