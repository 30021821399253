import { Icon, type IconNameType, type IconSizeType } from "@src/land_ui/icon/icon";
import { forwardRef } from "react";
import { Button } from "./button";
import { type LuiColors } from "@src/land_ui/color/color";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";

export interface ButtonIconProps {
    disabled?: boolean;
    isLoading?: boolean;
    type?: "button" | "submit";
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    icon?: IconNameType;
    size?: IconSizeType;
    color?: LuiColors;
    // Link button
    href?: string;
    openInNewTab?: boolean;
    tooltipLabel?: string;
}

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
    (
        {
            disabled,
            isLoading,
            icon,
            onClick,
            className,
            href,
            openInNewTab,
            size = "md",
            color = "primary-500",
            tooltipLabel,
        },
        ref,
    ) => {
        const button = (
            <Button
                ref={ref}
                variant="base"
                disabled={disabled}
                isLoading={isLoading}
                onClick={onClick}
                href={href}
                openInNewTab={openInNewTab}
                className={className}
            >
                <Icon name={icon} color={color} size={size} />
            </Button>
        );

        if (tooltipLabel) {
            return <Tooltip showArrow trigger={button} content={tooltipLabel} />;
        }
        return button;
    },
);
