import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
    type CompingReport,
    type CompAdjustment,
    KindEnum,
} from "@src/orval/gen/model";
import {
    getReportsAdjustmentsListQueryKey,
    useReportsAdjustmentsCreate,
    useReportsAdjustmentsDestroy,
    useReportsAdjustmentsList,
    useReportsAdjustmentsUpdate,
} from "@src/orval/gen/api";
import { formatPercentWithSign } from "@src/functions/format";
import {
    type Result,
    isResultValue,
    isResultError,
} from "@src/pages/parcel_viewer/types";
import type { LandColumnDef } from "@src/land_ui/table/table";
import { Table } from "@src/land_ui/table/table";
import { Input } from "@src/land_ui/input/input";
import { Button } from "@src/land_ui/button/button";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { Modal } from "@src/land_ui/modal/modal";
import { Typography } from "@src/land_ui/typography/typography";
import { ErrorMessageBox } from "@src/components/ErrorMessageBox";
import { Icon } from "@src/land_ui/icon/icon";

interface CompAdjustmentsTableProps {
    report: CompingReport;
}

export function CompAdjustmentsTable({ report }: CompAdjustmentsTableProps) {
    const { data: compAdjustments, isLoading } = useReportsAdjustmentsList(report.id);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState<CompAdjustment>(null);

    const showCreateForm = () => {
        setInitialValues(null);
        setIsModalOpen(true);
    };

    const showEditForm = (row: CompAdjustment) => {
        setInitialValues(row);
        setIsModalOpen(true);
    };

    const columns = useMemo<LandColumnDef<CompAdjustment>[]>(() => {
        return [
            {
                header: "Value",
                accessor: "value",
                cell: (row) => <>{formatAdjustmentValue(row)}</>,
                sortBy: "alphanumeric",
            },
            {
                header: "Reason",
                accessor: "reason",
                fullWidth: true,
            },
            {
                header: "Actions",
                cell: (row) => {
                    return (
                        <div className="lui-flex lui-gap-2">
                            <ButtonIcon
                                icon="Pencil"
                                onClick={() => showEditForm(row)}
                                tooltipLabel="Edit Adjustment"
                                disabled={row.name.length > 0}
                            />
                            <DeleteAdjustmentButton reportID={report.id} id={row.id} />
                        </div>
                    );
                },
            },
        ];
    }, [report.id]);

    return (
        <div className="lui-flex lui-flex-col">
            <Table
                title="Adjustments"
                description="Increase or decrease the value of the calculated market value based on specific information you have about the property (ex: +10% in value due to creek, -30% in value due to steep slope and trash)"
                columns={columns}
                data={compAdjustments || []}
                isLoading={isLoading}
                actionButton={
                    <Button variant="base" onClick={() => showCreateForm()}>
                        <Typography
                            color="primary-500"
                            weight="medium"
                            className="lui-flex lui-items-center lui-gap-2"
                        >
                            <Icon name="Plus" color="inherit" />
                            Add Adjustment
                        </Typography>
                    </Button>
                }
            />
            <CompAdjustmentCreateModal
                reportID={report.id}
                initialValues={initialValues}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </div>
    );
}

const DEFAULT_FORM_VALUES = {
    id: 0,
    value: "",
    reason: "",
};

const DEFAULT_FORM_ERRORS = {
    value: "",
};

interface CompAdjustmentCreateModalProps {
    reportID: string;
    initialValues?: CompAdjustment;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function CompAdjustmentCreateModal({
    reportID,
    initialValues,
    isOpen,
    setIsOpen,
}: CompAdjustmentCreateModalProps) {
    const queryClient = useQueryClient();

    const {
        mutate: createAdjustment,
        isPending: isCreatePending,
        isError: isCreateError,
        reset: resetCreate,
    } = useReportsAdjustmentsCreate({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: getReportsAdjustmentsListQueryKey(reportID),
                });
                setIsOpen(false);
            },
        },
    });

    const {
        mutate: updateAdjustment,
        isPending: isUpdatePending,
        isError: isUpdateError,
        reset: resetUpdate,
    } = useReportsAdjustmentsUpdate({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: getReportsAdjustmentsListQueryKey(reportID),
                });
                setIsOpen(false);
            },
        },
    });

    const isPending = isCreatePending || isUpdatePending;
    const isError = isCreateError || isUpdateError;

    const [form, setForm] = useState(DEFAULT_FORM_VALUES);
    const [formErrors, setFormErrors] = useState(DEFAULT_FORM_ERRORS);

    const updateForm = (data: Partial<typeof form>) => {
        setForm({ ...form, ...data });
        setFormErrors(DEFAULT_FORM_ERRORS);
    };

    const resetForm = useCallback(() => {
        setForm(DEFAULT_FORM_VALUES);
        setFormErrors(DEFAULT_FORM_ERRORS);
        resetCreate();
        resetUpdate();
    }, [resetCreate, resetUpdate]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const parsedValue = parseValueField(form.value);
        if (isResultError(parsedValue)) {
            setFormErrors({ ...formErrors, value: parsedValue.error });
        }

        if (isResultValue(parsedValue)) {
            if (form.id) {
                updateAdjustment({
                    id: form.id,
                    reportId: reportID,
                    data: {
                        value: parsedValue.value,
                        reason: form.reason,
                    },
                });
            } else {
                createAdjustment({
                    reportId: reportID,
                    data: {
                        value: parsedValue.value,
                        reason: form.reason,
                    },
                });
            }
        }
    };

    // Reset form when modal closed and initialize form when opened.
    useEffect(() => {
        resetForm();
        if (initialValues) {
            setForm({
                id: initialValues.id,
                value: formatAdjustmentValue(initialValues),
                reason: initialValues.reason,
            });
        }
    }, [isOpen, resetForm, initialValues]);

    return (
        <>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <form onSubmit={onSubmit}>
                    <Modal.Header preventAutoFocus>Create Adjustment</Modal.Header>
                    <Modal.Content className="lui-p-6 lui-flex lui-flex-col lui-gap-2">
                        <Input
                            label="Reason"
                            placeholder="Enter reason..."
                            value={form.reason}
                            onChange={(e) => updateForm({ reason: e.target.value })}
                        />
                        <Input
                            label="Adjustment Percent"
                            placeholder="E.g. +10%"
                            autoFocus
                            autoSelectionOnFocus
                            value={form.value}
                            onChange={(e) => updateForm({ value: e.target.value })}
                        />
                        <Typography weight="bold" color="red-800">
                            {formErrors.value}
                        </Typography>
                        <ErrorMessageBox isShown={isError}>
                            Error saving adjustment.
                        </ErrorMessageBox>
                    </Modal.Content>
                    <Modal.Footer className="lui-flex lui-justify-end">
                        <Button
                            variant="base"
                            className="lui-text-start lui-py-2 lui-px-4"
                            onClick={() => setIsOpen(false)}
                            disabled={isPending}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className="lui-py-2 lui-px-6"
                            isLoading={isPending}
                        >
                            Save Adjustment
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

function parseValueField(value: string): Result<number> {
    // Remove whitespace and commas (e.g., "1,000 %" becomes "1000%")
    const cleaned = value.replace(/[\s,]/g, "");

    // Remove a trailing percent sign, if present.
    const numericPart = cleaned.endsWith("%") ? cleaned.slice(0, -1) : cleaned;

    // Validate that numericPart is a valid decimal number with up to 4 decimal places.
    // This regex allows an optional + or - sign, at least one digit, and optionally a decimal point followed by 1 or 2 digits.
    if (!/^[+-]?\d+(\.\d{1,4})?$/.test(numericPart)) {
        return {
            ok: false,
            error: "Error parsing number. Must be a valid decimal number with up to 4 decimal places.",
        };
    }

    // Parse the string as a float.
    const parsedFloat = parseFloat(numericPart);
    if (isNaN(parsedFloat)) {
        return { ok: false, error: "Error parsing number. Result is NaN." };
    }

    // Check if the parsed integer is within bounds (-10000 to 10000).
    if (parsedFloat < -10000 || parsedFloat > 10000) {
        return {
            ok: false,
            error: "Number out of bounds. Must be between -10,000 and 10,000.",
        };
    }

    // Multiply to get integer with implied 4 decimal places.
    const parsedInt = Math.round(parsedFloat * Math.pow(10, 4));

    return { ok: true, value: parsedInt };
}

function formatAdjustmentValue(row: CompAdjustment) {
    if (row.kind === KindEnum.percent) {
        return formatPercentWithSign(row.value / Math.pow(10, 4)); // implied 4 decimals
    }
    return "?";
}

interface DeleteAdjustmentButtonProps {
    reportID: string;
    id: number;
}

function DeleteAdjustmentButton({ reportID, id }: DeleteAdjustmentButtonProps) {
    const queryClient = useQueryClient();

    const { mutate: deleteAdjustment, isPending } = useReportsAdjustmentsDestroy({
        mutation: {
            onSuccess: () =>
                queryClient.invalidateQueries({
                    queryKey: getReportsAdjustmentsListQueryKey(reportID),
                }),
        },
    });

    return (
        <ButtonIcon
            tooltipLabel="Remove Adjustment"
            icon="Trash"
            color="red-700"
            isLoading={isPending}
            onClick={() => {
                deleteAdjustment({
                    reportId: reportID,
                    id,
                });
            }}
        />
    );
}
