import { useParcel } from "@src/hooks/useParcel";
import { Button } from "@src/land_ui/button/button";
import { useParcelViewerContext } from "@src/pages/parcel_viewer/context";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";

interface FocusLayerControlProps {
    parcelId: number;
}
export function FocusLayerControl({ parcelId }: FocusLayerControlProps) {
    const { goToParcelInMap } = useParcelViewerContext();
    const { parcel } = useParcel({ parcelId, enabled: true });

    if (!parcel) {
        return null;
    }

    return (
        <Tooltip
            trigger={
                <Button
                    variant="secondary"
                    icon="MapMarkIcon"
                    onClick={() => {
                        goToParcelInMap(parcel);
                    }}
                />
            }
            content="Jump To Parcel"
            placement="left"
        />
    );
}
