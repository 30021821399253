/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `percent` - Percent
 */
export type KindEnum = typeof KindEnum[keyof typeof KindEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KindEnum = {
  percent: 'percent',
} as const;
