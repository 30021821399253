import MapboxPopup, { type MapboxPopupProps } from "@src/components/mapbox_popup";
import { Typography } from "@src/land_ui/typography/typography";
import { Button } from "@src/land_ui/button/button";
import { useParcelQueryParam } from "@src/hooks/useParcel";

interface ParcelChooserPopupProperties extends MapboxPopupProps {
    title: string;
    properties: {
        properties: {
            id: number;
            apn: string;
        };
    }[];
    onClose?: () => void;
    setPopupInfo: (info: any) => void;
}

export default function ParcelChooserPopup({
    title,
    properties,
    onClose,
    longitude,
    latitude,
    setPopupInfo,
}: ParcelChooserPopupProperties) {
    const { setParcelQuery } = useParcelQueryParam();
    const property_items = properties.map((parcel, i) => {
        return (
            <Typography size={"sm"} key={`${i}-${parcel.properties.id}`}>
                <Button
                    fullWidth
                    onClick={() => {
                        setPopupInfo(null);
                        setParcelQuery(parcel.properties.id);
                    }}
                >
                    {parcel.properties.apn}
                </Button>
            </Typography>
        );
    });
    return (
        <MapboxPopup
            key="parcel_chooser_popup"
            longitude={longitude}
            latitude={latitude}
            onClose={onClose}
            title={title}
            closeOnMove={false}
            anchor={"left"}
            offset={0}
        >
            <Typography size="md">
                There are multiple parcels at this location. Please choose one.
            </Typography>
            {property_items}
        </MapboxPopup>
    );
}
