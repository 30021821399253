import { Typography } from "@src/land_ui/typography/typography";
import type { CompingReport } from "@src/orval/gen/model";
import type { Region } from "@src/pages/parcel_viewer/types";
import { CompingScoresSection } from "./comping_scores_section";
import { MarketScoreSection } from "./market_score_section";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";

export function MetricsSection({
    report,
    region,
}: {
    report: CompingReport;
    region: Region;
}) {
    return (
        <div>
            <div className="lui-flex lui-flex-row lui-items-center lui-justify-between">
                <Typography size="md" weight="bold" className="lui-mb-6">
                    Metrics
                </Typography>

                <TutorialButton youtubeId="lTXMYXdoicA" />
            </div>

            <div className="lui-flex lui-flex-col lui-gap-5">
                {Boolean(region) && (
                    <MarketScoreSection report={report} region={region} />
                )}
                <CompingScoresSection report={report} />
            </div>
        </div>
    );
}
