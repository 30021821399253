import type { CompingReport } from "@src/orval/gen/model";
import {
    getDecimalValue,
    getOwnerFullName,
} from "@src/pages/parcel_viewer/controls/detail";
import { Typography } from "@src/land_ui/typography/typography";
import { type Region } from "@src/pages/parcel_viewer/types";
import { CompingSection } from "./comping_section";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";

export function CompingReportHeader({
    report,
    region,
}: {
    report: CompingReport;
    region: Region;
}) {
    const parcel = report.parcel;
    const acreage = getDecimalValue(
        report?.acreage_override || parcel?.LotSizeAcres,
        3,
    );
    return (
        <CompingSection>
            <div className="lui-grid lui-grid-cols-3 lui-items-center">
                <div className="lui-flex lui-flex-col lui-self-start lui-gap-1">
                    <Typography color="gray-700" size="sm">
                        APN: {parcel.APN}
                    </Typography>
                    <Typography
                        size="xl"
                        color="gray-1000"
                        weight="bold"
                        className="lui-capitalize"
                    >
                        {getOwnerFullName(parcel)}
                    </Typography>
                    <Typography color="primary-500" weight="medium">
                        {acreage} acres
                        {report?.acreage_override && (
                            <Typography weight="regular">(edited)</Typography>
                        )}
                    </Typography>
                </div>
                <Typography
                    size="2xl"
                    weight="bold"
                    color="gray-1000"
                    className="lui-text-center"
                >
                    {region?.name}, {region?.state}
                </Typography>
                <div className="lui-flex lui-justify-end">
                    <TutorialButton
                        youtubeId="cJPuuMBQAdk"
                        buttonTitle="How To Use This Feature"
                    />
                </div>
            </div>
        </CompingSection>
    );
}
