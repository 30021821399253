import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, {
    Layer,
    type MapboxMap,
    type MapLayerMouseEvent,
    type MapRef,
    Marker,
    Source,
} from "react-map-gl";
import Modal from "react-bootstrap/Modal";

import mapboxgl from "mapbox-gl";
import { type FeatureCollection } from "geojson";
import { isNumber } from "lodash";
import { bbox } from "@turf/turf";

import { MAPBOX_TOKEN } from "settings";
import { fetch, isLoggedIn, logout } from "functions";
import { isLatLngBounds, getParcelListBounds } from "@src/functions/map_utils";
import {
    type CompingReport,
    type FullParcel,
    type ParcelSavedList,
    type ParcelSearchResponse,
} from "@src/orval/gen/model";
import { parcelsRetrieve } from "@src/orval/gen/api";
import {
    type DataLayerType,
    DataLayerControl,
    useDataLayerControl,
} from "@src/components/map_tools/data_layer_control";
import {
    type CompingLayerFilter,
    CompingLayerControl,
    useCompingLayerControl,
} from "@src/components/map_tools/comping_layer_control";
import { MapHelpControl } from "@src/components/map_tools/map_help_control";
import {
    GoogleSourceLayer,
    MapLayerControl,
    useMapLayer,
} from "@src/components/map_tools/map_layer_control";
import { MapToolsControl } from "@src/components/map_tools/map_tools_control";
import { ZoomControls } from "@src/components/map_tools/zoom_control";
import { useQueryParam } from "@src/hooks/useQueryParam";
import { Button } from "@src/land_ui/button/button";

import { useRupt } from "@src/hooks/useRupt";
import {
    type OwnerPropertyState,
    type ParcelViewerContextType,
    ParcelViewerContext,
    UserContext,
} from "./context";
import { ParcelDetail } from "./controls/detail";
import { InspectControl } from "@src/components/map_tools/inspect_control";
import { DebugControl } from "@src/components/map_tools/debug_control";
import { MapHoverTooltip } from "./map_hover_tooltip";
import { MapHeader } from "./map_header";
import CompPopup from "./modals/comp_popup";
import ParcelChooserPopup from "./modals/parcel_chooser_popup";
import {
    buildingsSource,
    compSource,
    countySource,
    floodzoneSource,
    maptilerSource,
    ownerSource,
    parcelSource,
    roadsSource,
    wetlandsSource,
} from "./sources";
import {
    buildingsOutline,
    compsCircleStyle,
    compsLabelStyle,
    compsSelectedLabelStyle,
    countyHighlightOutline,
    countyHighlightShadow,
    countyIdentify,
    countyNameLayer,
    countyOutline,
    floodzone100,
    floodzone500,
    floodzoneCoastal,
    floodzoneFloodway,
    ownerSelectedOutline,
    ownersLabelStyle,
    parcelHighlightOutline,
    parcelHighlightShadow,
    parcelIdentify,
    parcelLandUse,
    parcelSelectedOutline,
    roadsOutline,
    searchResultsCluster,
    searchResultsClusterLabel,
    surfaceWaterFill,
    surfaceWaterOutline,
    wetlandsFill,
    wetlandsOutline,
    zipHighlightOutline,
    zipHighlightShadow,
    zipIdentify,
    zipOutline,
} from "./styles";
import {
    type County,
    type DebugPoint,
    type MapFilter,
    type Parcel,
    type PopupInfo,
    type User,
} from "./types";
import { useParcelQueryParam } from "@src/hooks/useParcel";
import FreeTrialNotification from "@src/components/free_trial";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";
import { ContourLayer } from "./layers/contour_layer";
import { FocusLayerControl } from "@src/components/map_tools/focus_layer_control";
import { useJumpToMapCoordinate } from "@src/hooks/useJumpToMapCoordinate";
import { useSelectedComps } from "@src/pages/comping/utils";

const initialViewState = {
    longitude: -93.55,
    latitude: 39.6,
    zoom: 4,
};

// Render no features
const EMPTY_MAP_FILTER = ["==", ["get", "code"], ""];

const IMAGES = ["rounded_green", "rounded_blue", "rounded_red", "rounded_gold"];

const loadImages = (map: MapboxMap, images: string[]) => {
    for (const image of images) {
        map.loadImage(`/static/images/${image}.png`, (error: any, imageData: any) => {
            if (error) {
                console.log(error);
                return;
            }
            if (!map.hasImage(image)) {
                map.addImage(image, imageData, {
                    stretchX: [[10, 54]],
                    stretchY: [[10, 54]],
                    content: [7, 7, 57, 57],
                    pixelRatio: 1,
                });
            }
        });
    }
};

// TODO(KM): Move to the type definition file
declare global {
    interface Window {
        Intercom: (action: string, options: any) => void;
    }
}

export default function ParcelViewer() {
    const [user, setUser] = useState<User>();
    const { attachedRupt } = useRupt();

    // Set body class name on mount
    useEffect(() => {
        document.body.className = "parcel-viewer";
        return () => {
            // Clear class name on dismount
            document.body.className = "";
        };
    }, []);

    useEffect(() => {
        if (!isLoggedIn()) {
            logout();
            return;
        }

        const fetchUser = async () => {
            try {
                const newUser = await fetch("/user/user/");
                setUser(newUser);
                attachedRupt(newUser);

                // hides the Intercom chat widget at the bottom right of the screen
                window.Intercom("update", {
                    hide_default_launcher: true,
                });
            } catch (xhr) {
                // TODO: show error modal
                console.error("Error loading user", xhr);
            }
        };

        fetchUser();
    }, [attachedRupt, setUser]);

    const context = { user };

    return (
        <UserContext.Provider value={context}>
            <ErrorBoundary
                onReset={() => {
                    window.location.reload();
                }}
            >
                <SubscriptionRequiredModal user={user} />
                <FreeTrialNotification user={user} />
                <ParcelViewerMap
                    showLogo
                    showSearchFilterControls
                    showCompingLayerControl={false}
                    showCompPropertyButton
                    enableMapURLHash
                    enableParcelSelection
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                />
            </ErrorBoundary>
        </UserContext.Provider>
    );
}

function SubscriptionRequiredModal({ user }: { user: User }) {
    return (
        <>
            {user && !user.has_paid_subscription && (
                <Modal
                    show
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Subscription Required
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <p>There's a problem with your subscription.</p>
                        <p>
                            If you canceled and are looking to resubscribe, contact{" "}
                            <a href="mailto:hello@landinsights.co">
                                hello@landinsights.co
                            </a>
                        </p>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

interface ParcelViewerMapProps {
    showLogo: boolean;
    showSearchFilterControls: boolean;
    showCompingLayerControl: boolean;
    showCompPropertyButton: boolean;
    enableMapURLHash: boolean;
    enableParcelSelection: boolean;
    style: React.CSSProperties;
    mapComponentStyle?: React.CSSProperties;
    compingReport?: CompingReport;
}

export function ParcelViewerMap({
    showLogo,
    showSearchFilterControls,
    showCompingLayerControl,
    showCompPropertyButton,
    enableMapURLHash,
    enableParcelSelection,
    compingReport,
    style,
    mapComponentStyle,
}: ParcelViewerMapProps) {
    const mapRef = useRef<MapRef>();

    const [isDebugMode] = useQueryParam("debug", false);

    // Map layers
    const { mapLayerOption, setMapLayer, mapLayer } = useMapLayer();
    const { dataLayer } = useDataLayerControl();
    const { compingLayerFilter } = useCompingLayerControl({
        reportID: compingReport?.id,
    });

    const [isPolygonActive, setActivePolygon] = useState(false);
    const [styleLoaded, setStyleLoaded] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);

    // Map features state
    const [county, setCounty] = useState<County>();
    const [searchResult, _setSearchResult] = useState<ParcelSearchResponse>();

    // mapFilter is the active map feature (parcel/county/zip)
    const [mapFilter, setMapFilter] = useState<MapFilter>();

    // savedList is the active Saved List
    const [savedList, setSavedList] = useState<ParcelSavedList>();

    const [isRulerActive, , removeIsRulerActive] = useQueryParam<boolean>(
        "ruler",
        null,
    );
    const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);
    const [ownerPropertyData, setOwnerPropertyData] = useState<OwnerPropertyState>();

    const { parcelQueryId, setParcelQuery, removeParcelQuery } = useParcelQueryParam();
    const { coordinate, removeCoordinate } = useJumpToMapCoordinate();

    // For debug markers
    const [debugPoints, setDebugPoints] = useState<DebugPoint[]>();

    const onMapLoad = () => {
        setMapLoaded(true);
        updateMapOnLoad();
    };

    const updateMapOnLoad = () => {
        if (!mapRef?.current) {
            return;
        }
        const map = mapRef.current.getMap();
        loadImages(map, IMAGES);
        setStyleLoaded(true);
    };

    const toggleTileBoundaries = useCallback(() => {
        const map = mapRef.current.getMap();
        map.showTileBoundaries = !map.showTileBoundaries;
    }, []);

    const setSearchResult = useCallback(
        (newSearchResult: ParcelSearchResponse, options?: { disableZoom: boolean }) => {
            _setSearchResult(newSearchResult);

            const parcels = newSearchResult?.results || [];

            // Clear prior selected zip/county
            setMapFilter(null);

            if (!options?.disableZoom) {
                // Fit map bounds to search results extent
                const parcelBounds = getParcelListBounds(parcels);
                if (parcelBounds) {
                    mapRef.current.fitBounds(parcelBounds, {
                        padding: 100,
                        duration: 0,
                    });
                }
            }

            // Reset feature state of parcels layer
            mapRef.current.removeFeatureState({
                source: "tiler_source",
                sourceLayer: "parcels",
            });

            // Set feature state of each parcel in parcels layer if the ID exists in parcels searchResults
            parcels.forEach((parcel: Parcel) => {
                mapRef.current.setFeatureState(
                    {
                        source: "tiler_source",
                        sourceLayer: "parcels",
                        id: parcel.PropertyID,
                    },
                    { searchResultMatch: true },
                );
            });
        },
        [mapRef, _setSearchResult, setMapFilter],
    );

    // Handle switching map layers. This is a destructive process in Mapbox GL
    // which is not gracefully handled by react-map-gl. Sources and layers must
    // be removed and then readded after styles are done loading to avoid
    // "Style is not done loading" errors.
    // Ref: https://github.com/visgl/react-map-gl/issues/1122
    const setMapStyle = useCallback(
        (mapStyle: string) => {
            const map = mapRef.current.getMap();

            // Restore map layers when styles are done loading
            map.once("style.load", () => {
                // Fixes an issue where MLS bg images don't load on style change
                updateMapOnLoad();
            });

            // Restore map feature state when tiles have reloaded
            map.once("idle", () => {
                setSearchResult(searchResult);
            });

            // Remove map layers
            setStyleLoaded(false);
            // @ts-ignore
            setMapLayer(mapStyle);
        },
        [searchResult, setMapLayer, setSearchResult],
    );

    const closePopup = () => setPopupInfo(null);

    const onMapClick = (e: MapLayerMouseEvent) => {
        if (
            !e.features ||
            e.features.length === 0 ||
            isRulerActive ||
            isPolygonActive
        ) {
            return;
        }
        for (const feature of e.features) {
            if (feature.source === "comp_source" && feature.geometry.type === "Point") {
                setPopupInfo({
                    latitude: feature.geometry.coordinates[1],
                    longitude: feature.geometry.coordinates[0],
                    type: "mls_comp",
                    title: "Comp details",
                    properties: feature.properties,
                });
                return;
            } else if (enableParcelSelection && feature.source === "tiler_source") {
                const parcelFeatures = e.features
                    .filter((f) => f.layer.id === "parcel_identify")
                    .sort((a, b) => (b.properties.apn < a.properties.apn ? 1 : -1))
                    // When clicking on tile boundaries, features can be returned multiple times
                    // This code filters the sorted list to only include each feature once
                    .filter((f, index, array) => {
                        if (index === array.length - 1) return true;
                        return f.properties.id !== array[index + 1].properties.id;
                    });

                if (parcelFeatures.length > 1) {
                    setPopupInfo({
                        latitude: e.lngLat.lat,
                        longitude: e.lngLat.lng,
                        type: "parcel_chooser",
                        title: "Multiple parcels",
                        properties: parcelFeatures,
                    });
                } else {
                    const id = feature?.id;

                    if (isNumber(id) && id !== parcelQueryId) {
                        setParcelQuery(id);
                        setOwnerPropertyData(null);
                    } else {
                        removeParcelQuery();
                    }

                    // Clear map filter if active parcel changed
                    if (id !== parcelQueryId) {
                        setMapFilter(null);
                    }
                }
                return;
            }
        }
    };

    const parcelQueryIdFilter = useMemo(
        () => (parcelQueryId != null ? ["==", "id", parcelQueryId] : null),
        [parcelQueryId],
    );

    const compParcelFilter = useMemo(
        () =>
            compingReport?.parcel != null
                ? ["==", "id", compingReport.parcel.PropertyID]
                : null,
        [compingReport],
    );

    const ownerQueryIdFilter = useMemo(
        () =>
            ownerPropertyData?.ownerParcelList?.length > 0
                ? ["in", "id", ...ownerPropertyData?.ownerParcelList]
                : null,
        [ownerPropertyData?.ownerParcelList],
    );

    const goToParcelInMap = useCallback(
        (parcel: FullParcel) => {
            if (!mapRef.current || !parcel?.shape) {
                return;
            }
            const bounds = bbox(parcel.shape as any);
            if (isLatLngBounds(bounds)) {
                // Disable parcel offset on Comping Report page.
                const offsetX = enableParcelSelection ? 100 : 0;
                mapRef.current.fitBounds(bounds, {
                    offset: [offsetX, 0],
                    padding: 200,
                    duration: 0,
                });
            }
        },
        [enableParcelSelection],
    );

    const goToParcelInMapByID = useCallback(
        async (parcelID: number) => {
            try {
                const data = await parcelsRetrieve(parcelID);
                goToParcelInMap(data);
            } catch (e) {
                console.error(e);
            }
        },
        [goToParcelInMap],
    );

    // Move map to parcel on page load.
    useEffect(() => {
        if (parcelQueryId) {
            goToParcelInMapByID(parcelQueryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Move map to comp parcel on page load.
    useEffect(() => {
        if (compingReport?.parcel && mapLoaded) {
            goToParcelInMap(compingReport?.parcel);
        }
    }, [compingReport, mapLoaded, goToParcelInMap]);

    useEffect(() => {
        /**
         * This hook is currently used to set the map view to the coordinate on the comping page
         * You can test this by passing ?coordinates=[lat,lng] in the URL
         */
        if (coordinate && mapRef.current) {
            const mapElement = mapRef.current.getContainer();
            const mapPosition = mapElement.getBoundingClientRect().top + window.scrollY;
            removeCoordinate();
            window.scrollTo({
                behavior: "smooth",
                top: mapPosition - 100, // 75 is the height of the header
            });

            mapRef.current.flyTo({
                // @ts-ignore - mapbox-gl types are not correct
                center: coordinate,
                zoom: 17,
            });
        }
    }, [coordinate, removeCoordinate]);

    const context: ParcelViewerContextType = {
        setMapFilter,
        county,
        setCounty,
        savedList,
        setSavedList,
        setDebugPoints,
        toggleTileBoundaries,
        searchResult,
        setSearchResult,
        setActivePolygon,
        isPolygonActive,
        ownerPropertyData,
        setOwnerPropertyData,
        goToParcelInMap,
    };

    let zipFilter = EMPTY_MAP_FILTER;
    let countyFilter = EMPTY_MAP_FILTER;
    let parcelFilter = EMPTY_MAP_FILTER;
    let identifyFilter = EMPTY_MAP_FILTER;

    // Apply map filter
    if (mapFilter) {
        const { identifyLayer, filter, inverseFilter } = mapFilter;
        identifyFilter = filter;
        if (identifyLayer === "county_identify") {
            countyFilter = inverseFilter;
        } else if (identifyLayer === "zip_identify") {
            zipFilter = inverseFilter;
        } else if (identifyLayer === "parcel_identify") {
            parcelFilter = inverseFilter;
        }
    }

    const searchResultGeojson = useMemo<FeatureCollection>(
        () => ({
            type: "FeatureCollection",
            features:
                searchResult?.results
                    ?.filter((parcel) => parcel?.point)
                    ?.map((parcel) => ({
                        id: parcel.PropertyID,
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: parcel.point,
                        },
                        properties: {},
                    })) || [],
        }),
        [searchResult],
    );

    const onError = (e: any) => {
        if (e.message === "Map is not supported by this browser") {
            // Handle Web GL init error raised by react-map-gl
            // TODO: friendly error message
            console.log("WebGL init error. Refresh page to continue.");
        } else if (e.error?.status === 400) {
            // Ignore HTTP 400 Bad Request tile requests
        } else {
            console.error(e);
        }
    };

    const createCompsLayerFilterAcres = (
        dataLayer: DataLayerType,
        showCompingLayerControl: boolean,
        compingLayerFilter: CompingLayerFilter,
    ): any[] => {
        const f: any[] = ["all"];

        const acres = showCompingLayerControl
            ? compingLayerFilter
            : dataLayer.compsFilter;

        const minAcres = parseFloat(acres?.minAcres);
        if (!isNaN(minAcres)) {
            f.push([">=", ["to-number", ["get", "lot_size"]], minAcres]);
        }

        const maxAcres = parseFloat(acres?.maxAcres);
        if (!isNaN(maxAcres)) {
            f.push(["<=", ["to-number", ["get", "lot_size"]], maxAcres]);
        }

        return f;
    };

    const { data: selectedComps } = useSelectedComps(compingReport?.id);

    // Comps layer filter for "All Comps"
    const compsFilter = useMemo(() => {
        // Hide all features if "Selected Comps" option is selected.
        if (showCompingLayerControl && compingLayerFilter.compsVisible === "selected") {
            return EMPTY_MAP_FILTER;
        }
        const f = createCompsLayerFilterAcres(
            dataLayer,
            showCompingLayerControl,
            compingLayerFilter,
        );
        // Exclude selected comps to prevent overlap with Selected Comps layer.
        if (selectedComps?.length > 0) {
            const compIDs: string[] = selectedComps?.map(
                (savedComp) => savedComp?.source_comp,
            );
            f.push(["match", ["get", "id"], compIDs, false, true]);
        }
        return f;
    }, [dataLayer, showCompingLayerControl, compingLayerFilter, selectedComps]);

    // Comps layer filter for "Selected Comps" (Comping Report page only)
    const selectedCompsFilter = useMemo(() => {
        const f = createCompsLayerFilterAcres(
            dataLayer,
            showCompingLayerControl,
            compingLayerFilter,
        );
        // Only render selected comps.
        if (selectedComps?.length > 0) {
            const compIDs: string[] = selectedComps?.map(
                (savedComp) => savedComp?.source_comp,
            );
            f.push(["match", ["get", "id"], compIDs, true, false]);
        } else {
            f.push(EMPTY_MAP_FILTER);
        }
        return f;
    }, [dataLayer, showCompingLayerControl, compingLayerFilter, selectedComps]);

    // Always show comps layer if we're on the Comping Report page
    const showCompsLayer = showCompingLayerControl || dataLayer.mlsComps;

    const sourcesAndLayers = useMemo(() => {
        if (!styleLoaded) {
            return null;
        }
        return (
            <>
                {popupInfo && popupInfo.type === "mls_comp" && (
                    <CompPopup
                        key={popupInfo.properties.id}
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        onClose={closePopup}
                        title={popupInfo.title}
                        properties={popupInfo.properties}
                        reportID={compingReport?.id}
                    />
                )}
                {popupInfo && popupInfo.type === "parcel_chooser" && (
                    <ParcelChooserPopup
                        key={popupInfo.properties.id}
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        onClose={closePopup}
                        title={popupInfo.title}
                        properties={popupInfo.properties}
                        setPopupInfo={setPopupInfo}
                    />
                )}
                <Source {...parcelSource}>
                    {dataLayer.landuseLayer && <Layer {...parcelLandUse} />}
                    <Layer {...parcelIdentify} />
                    <Layer {...parcelHighlightShadow} filter={parcelFilter} />
                    <Layer {...parcelHighlightOutline} />

                    {(compParcelFilter || parcelQueryIdFilter) && (
                        <Layer
                            {...parcelSelectedOutline}
                            filter={compParcelFilter || parcelQueryIdFilter}
                        />
                    )}

                    {ownerQueryIdFilter && (
                        <Layer {...ownerSelectedOutline} filter={ownerQueryIdFilter} />
                    )}
                </Source>
                <Source {...ownerSource}>
                    {dataLayer.ownersLayer && <Layer {...ownersLabelStyle} />}
                </Source>
                <Source {...wetlandsSource}>
                    {dataLayer.wetLands && <Layer {...wetlandsFill} />}
                    {dataLayer.wetLands && <Layer {...wetlandsOutline} />}
                    {dataLayer.surfaceWater && <Layer {...surfaceWaterFill} />}
                    {dataLayer.surfaceWater && <Layer {...surfaceWaterOutline} />}
                </Source>
                <Source {...buildingsSource}>
                    {dataLayer.buildingLayer && <Layer {...buildingsOutline} />}
                </Source>
                <Source {...roadsSource}>
                    {dataLayer.roadsLayer && <Layer {...roadsOutline} />}
                </Source>
                <Source {...floodzoneSource}>
                    {dataLayer.floodZoneLayer && <Layer {...floodzoneCoastal} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzoneFloodway} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzone100} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzone500} />}
                </Source>

                {/* Hide search result clusters while drawing polygons on the map */}
                {!isPolygonActive && (
                    <Source
                        type="geojson"
                        data={searchResultGeojson}
                        cluster={true}
                        clusterMaxZoom={13}
                        tolerance={0.5}
                    >
                        <Layer {...searchResultsCluster} />
                        <Layer {...searchResultsClusterLabel} />
                    </Source>
                )}
                <Source {...countySource}>
                    {dataLayer.countyLayer && <Layer {...countyOutline} />}
                    {dataLayer.countyLayer && <Layer {...countyNameLayer} />}
                    <Layer {...countyIdentify} />
                    <Layer {...countyHighlightOutline} filter={identifyFilter} />
                    <Layer {...countyHighlightShadow} filter={countyFilter} />
                </Source>
                <Source {...maptilerSource}>
                    {dataLayer.zipLayer && <Layer {...zipOutline} />}
                    <Layer {...zipIdentify} />
                    <Layer {...zipHighlightOutline} filter={identifyFilter} />
                    <Layer {...zipHighlightShadow} filter={zipFilter} />
                </Source>
                <Source {...compSource}>
                    {showCompsLayer && (
                        <Layer {...compsCircleStyle} filter={compsFilter} />
                    )}
                    {showCompsLayer && (
                        <Layer {...compsLabelStyle} filter={compsFilter} />
                    )}
                    {showCompingLayerControl && (
                        <Layer
                            {...compsSelectedLabelStyle}
                            filter={selectedCompsFilter}
                        />
                    )}
                </Source>

                {!isRulerActive && (
                    <Source
                        id="mapbox-dem"
                        type="raster-dem"
                        url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    >
                        <Layer id="terrain" type="hillshade" source="mapbox-dem" />
                    </Source>
                )}

                {((isDebugMode && debugPoints) || []).map(
                    ({ point, color, label }, i) => (
                        <Marker
                            key={i}
                            longitude={point[0]}
                            latitude={point[1]}
                            color={color}
                            popup={new mapboxgl.Popup().setText(label)}
                            onClick={(e) => {
                                e.originalEvent.stopPropagation();
                                e.target.togglePopup();
                            }}
                            draggable
                        ></Marker>
                    ),
                )}

                {compingReport?.parcel && (
                    <Marker
                        longitude={compingReport?.parcel.point[0]}
                        latitude={compingReport?.parcel.point[1]}
                        color="blue"
                    ></Marker>
                )}

                <GoogleSourceLayer isShown={mapLayer === "google"} />

                {dataLayer.contourLayer && <ContourLayer />}
            </>
        );
    }, [
        styleLoaded,
        popupInfo,
        parcelFilter,
        ownerQueryIdFilter,
        parcelQueryIdFilter,
        compParcelFilter,
        dataLayer.ownersLayer,
        dataLayer.wetLands,
        dataLayer.surfaceWater,
        dataLayer.buildingLayer,
        dataLayer.roadsLayer,
        dataLayer.floodZoneLayer,
        dataLayer.countyLayer,
        dataLayer.zipLayer,
        dataLayer.contourLayer,
        dataLayer.landuseLayer,
        isPolygonActive,
        searchResultGeojson,
        identifyFilter,
        countyFilter,
        zipFilter,
        compsFilter,
        selectedCompsFilter,
        isRulerActive,
        isDebugMode,
        debugPoints,
        mapLayer,
        showCompsLayer,
        showCompingLayerControl,
        compingReport,
    ]);

    return (
        <ParcelViewerContext.Provider value={context}>
            <div className="lui-flex lui-flex-col" style={style}>
                <Map
                    hash={enableMapURLHash}
                    initialViewState={initialViewState}
                    ref={mapRef}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    mapStyle={mapLayerOption}
                    minZoom={4}
                    maxZoom={20}
                    onClick={onMapClick}
                    onLoad={onMapLoad}
                    interactiveLayerIds={[
                        "parcel_identify",
                        "comps-circle",
                        "comps-label",
                        "comps-selected-label",
                        "floodzone_500_fill",
                        "floodzone_100_fill",
                        "floodzone_coastal_fill",
                        "floodzone_floodway_fill",
                    ]}
                    onError={onError}
                    terrain={
                        isRulerActive
                            ? null
                            : {
                                  source: "mapbox-dem",
                                  exaggeration: 1,
                              }
                    }
                    touchPitch={true}
                    cursor={isPolygonActive ? "crosshair" : "pointer"}
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        ...mapComponentStyle,
                    }}
                >
                    {sourcesAndLayers}

                    {showSearchFilterControls && (
                        <MapHeader
                            setSearchResult={setSearchResult}
                            setShowFilterPanel={() => {
                                removeParcelQuery();
                            }}
                        />
                    )}

                    {parcelQueryId && (
                        <ParcelDetail
                            parcelID={parcelQueryId}
                            onClose={() => removeParcelQuery()}
                        />
                    )}

                    <MapHoverTooltip />

                    {/* Top Right Map Options */}
                    <div className=" lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-top-0 lui-m-6">
                        {showLogo && (
                            <Button
                                variant="secondary"
                                icon="LandInsights"
                                href="/"
                                openInNewTab
                            />
                        )}
                        {isDebugMode && (
                            <>
                                <InspectControl />
                                <DebugControl />
                            </>
                        )}
                    </div>

                    {/* Bottom Right Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-bottom-0 lui-m-6">
                        <FocusLayerControl
                            parcelId={
                                compingReport?.parcel?.PropertyID || parcelQueryId
                            }
                        />
                        <DataLayerControl disableCompsLayer={showCompingLayerControl} />
                        <MapToolsControl />
                        <MapLayerControl setLayer={setMapStyle} />
                        <ZoomControls />
                    </div>

                    {/* Bottom Left Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-left-0 lui-bottom-2 lui-my-6 lui-mx-2">
                        <MapHelpControl />
                    </div>

                    {/* Center bottom options */}
                    <div className="lui-flex lui-absolute lui-bottom-0 lui-left-1/2 -lui-translate-x-1/2 lui-justify-center lui-gap-3 lui-m-6 lui-w-fit ">
                        <>
                            {isRulerActive && (
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        removeIsRulerActive();
                                    }}
                                    icon="Ruler"
                                >
                                    Exit Ruler
                                </Button>
                            )}
                            {showCompPropertyButton && parcelQueryId && (
                                <Button
                                    href={`/comping/?parcel=${parcelQueryId}`}
                                    variant="secondary"
                                    openInNewTab
                                    icon="RightArrow"
                                    iconPosition="right"
                                >
                                    Comp Property&nbsp;
                                </Button>
                            )}
                        </>
                    </div>
                    {showCompingLayerControl && (
                        <CompingLayerControl reportID={compingReport.id} />
                    )}
                </Map>
            </div>
        </ParcelViewerContext.Provider>
    );
}
