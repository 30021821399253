import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./editor.css";

interface EditorProps {
    value: string;
    onChange: (notes: string) => void;
    placeholder?: string;
}

export function Editor({ value: notes, onChange, placeholder }: EditorProps) {
    return (
        <ReactQuill
            value={notes}
            onChange={onChange}
            modules={{
                toolbar: [
                    "bold",
                    "italic",
                    "underline",
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                    "clean",
                ],
            }}
            placeholder={placeholder}
            theme="snow"
        />
    );
}
