import clsx from "clsx";

interface CompingSectionProps {
    children?: React.ReactNode;
    noPadding?: boolean;
    className?: string;
}
export function CompingSection({
    children,
    noPadding,
    className,
}: CompingSectionProps) {
    return (
        <div
            className={clsx(
                "lui-flex lui-flex-col lui-w-full lui-rounded-2xl lui-bg-white lui-border lui-border-gray-200 lui-border-solid ",
                {
                    "lui-p-5": !noPadding,
                },
                className,
            )}
        >
            {children}
        </div>
    );
}
