/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `ACTIVE` - Active
* `PENDING` - Pending
* `SOLD` - Sold
 */
export type CompStatusEnum = typeof CompStatusEnum[keyof typeof CompStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompStatusEnum = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  SOLD: 'SOLD',
} as const;
