import { Modal } from "@src/land_ui/modal/modal";
import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";
import { ErrorMessageBox } from "@src/components/ErrorMessageBox";
import { Button } from "@src/land_ui/button/button";
import {
    type Comp,
    type CompingReport,
    type FullParcel,
    KindEnum,
    type SavedComp,
} from "@src/orval/gen/model";
import { getDistanceInMiles } from "@src/functions/map_utils";
import { type TableComp } from "@src/pages/comping/tables";
import {
    getReportsSavedCompsListQueryKey,
    useCompsList,
    useReportsAdjustmentsList,
    useReportsSavedCompsCreate,
    useReportsSavedCompsDestroy,
    useReportsSavedCompsList,
} from "@src/orval/gen/api";
import { useMemo } from "react";
import { sum } from "lodash";
import { useQueryClient } from "@tanstack/react-query";

interface DeleteModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    deleteParams: object;
    setDeleteParams: (params: object) => void;
    mutate: any;
    isPending: boolean;
    error: unknown;
    message?: string;
}

export function DeleteModal({
    isOpen,
    setIsOpen,
    deleteParams,
    setDeleteParams,
    mutate,
    isPending,
    error,
    message = "Are you sure you want to delete?",
}: DeleteModalProps) {
    return (
        <div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} isError>
                <Modal.Header>
                    <div className="lui-flex lui-gap-3 lui-items-center">
                        <Icon name="Trash" color="red-700" />
                        Delete
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Typography className="lui-p-6">{message}</Typography>
                    <ErrorMessageBox isShown={Boolean(error)}>
                        Something went wrong. Please try again.
                    </ErrorMessageBox>
                </Modal.Content>
                <Modal.Footer className="lui-flex lui-justify-end lui-gap-7">
                    <Button
                        variant="base"
                        onClick={() => {
                            setDeleteParams(null);
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        isLoading={isPending}
                        disabled={isPending}
                        variant="danger"
                        onClick={() => {
                            mutate(deleteParams, {
                                onSettled: () => {
                                    setDeleteParams(null);
                                    setIsOpen(false);
                                },
                            });
                        }}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function calculateCompDistances(parcel: FullParcel, comps: Comp[]): TableComp[] {
    if (!parcel || !comps) {
        return [];
    }
    return comps.map((comp) => {
        const tableComp: TableComp = comp;
        if (parcel?.point && comp.geom) {
            tableComp.distance = getDistanceInMiles(
                parcel.point,
                comp.geom.coordinates,
            );
        }
        return tableComp;
    });
}

export const useSelectedComps = (reportId: string) => {
    return useReportsSavedCompsList(reportId, {
        query: {
            enabled: Boolean(reportId),
        },
    });
};
export const useComps = (fips: string) => {
    return useCompsList(
        {
            fips,
        },
        {
            query: {
                enabled: Boolean(fips),
                queryKey: ["comps", fips],
            },
        },
    );
};
export const useManualCompValues = (report: CompingReport) => {
    const { data: selectedComps } = useSelectedComps(report.id);
    const { data: compAdjustments } = useReportsAdjustmentsList(report.id);

    const averagePpa = useMemo(() => {
        let ppa = 0;
        if (selectedComps?.length > 0) {
            ppa +=
                selectedComps.reduce(
                    (acc, currentValue) => acc + currentValue.price_per_acre,
                    0,
                ) / selectedComps.length;
        }
        if (compAdjustments?.length > 0) {
            const adjustmentSum = sum(
                compAdjustments
                    .filter((ca) => ca.kind === KindEnum.percent)
                    .map((ca) => ca.value / Math.pow(10, 4)), // implied 4 decimals
            );
            const adjustmentPercent = 1 + adjustmentSum / 100;
            ppa *= adjustmentPercent;
        }
        return ppa;
    }, [selectedComps, compAdjustments]);

    const lotSize = report?.parcel.LotSizeAcres / Math.pow(10, 3);

    return {
        averagePpa,
        fullPrice: averagePpa * lotSize,
    };
};
export const useDeleteSelectedComp = () => {
    const queryClient = useQueryClient();

    return useReportsSavedCompsDestroy({
        mutation: {
            // https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#optimistic-updates
            onMutate: async ({ reportId, id }) => {
                const queryKey = getReportsSavedCompsListQueryKey(reportId);
                // Cancel any outgoing refetches
                // (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries({ queryKey });

                // Snapshot previous value
                const previousSavedComps = queryClient.getQueryData(queryKey);

                // Optimistically update to the new value
                queryClient.setQueryData(queryKey, (old: SavedComp[]) =>
                    old.filter((c) => c.id !== id),
                );

                // Return a context object with the snapshot value
                return { previousSavedComps };
            },
            // If the mutation fails,
            // use the context returned from onMutate to roll back
            onError: (err, { reportId }, context) => {
                console.error(err);
                queryClient.setQueryData(
                    getReportsSavedCompsListQueryKey(reportId),
                    context.previousSavedComps,
                );
            },
            // Always refetch after error or success:
            onSettled: (data, err, { reportId }) => {
                queryClient.invalidateQueries({
                    queryKey: getReportsSavedCompsListQueryKey(reportId),
                });
            },
        },
    });
};
export const useCreateSelectedComp = () => {
    const queryClient = useQueryClient();

    return useReportsSavedCompsCreate({
        mutation: {
            onMutate: async ({ reportId, data }) => {
                const queryKey = getReportsSavedCompsListQueryKey(reportId);
                // Cancel any outgoing refetches
                // (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries({ queryKey });

                // Snapshot previous value
                const previousSavedComps = queryClient.getQueryData(queryKey);

                // Optimistically update to the new value
                queryClient.setQueryData(queryKey, (old: SavedComp[]) => [
                    ...old,
                    data,
                ]);

                // Return a context object with the snapshot value
                return { previousSavedComps };
            },
            // If the mutation fails,
            // use the context returned from onMutate to roll back
            onError: (err, { reportId }, context) => {
                console.error(err);
                queryClient.setQueryData(
                    getReportsSavedCompsListQueryKey(reportId),
                    context.previousSavedComps,
                );
            },
            // Always refetch after error or success:
            onSettled: (data, err, { reportId }) => {
                queryClient.invalidateQueries({
                    queryKey: getReportsSavedCompsListQueryKey(reportId),
                });
            },
        },
    });
};
