import { LoadingSpinner } from "@src/land_ui/loading/loading";
import { Typography } from "@src/land_ui/typography/typography";
import {
    type CellContext,
    type ColumnDef,
    type Row,
    type SortingFnOption,
    type SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    type ColumnFiltersState,
    type Table as TanstackTable,
} from "@tanstack/react-table";
import Papa from "papaparse";

import clsx from "clsx";
import {
    chain,
    clamp,
    forEach,
    isEmpty,
    isFunction,
    isNumber,
    isString,
    isUndefined,
    last,
    meanBy,
    sampleSize,
    snakeCase,
    toString,
} from "lodash";
import { type ReactNode, useCallback, useMemo, useState } from "react";

import "./table.css";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";

// Use LandColumnDef instead of ColumnDef to incrementally add features without breaking existing functionality
export interface LandColumnDef<T, V = unknown>
    // >> IMPORTANT <<  ANY VALUE ADDED HERE SHOULD BE ADDED TO useOverrideSettings
    extends Pick<
        ColumnDef<T, V>,
        "header" | "size" | "enableResizing" | "id" | "filterFn"
    > {
    // Use the field name of the data object as accessorKey, e.g., "name" or "user.name"
    accessor?: keyof T | ((originalRow: T, index: number) => V);
    cell?: (rowData: T, cell: CellContext<T, unknown>) => JSX.Element;
    isHidden?: boolean;
    fullWidth?: boolean;
    // Alphanumeric: Sorts by mixed alphanumeric values without case-sensitivity.
    sortBy?: ((a: T, b: T) => number) | "alphanumeric" | "datetime";
}

const DEFAULT_SETTINGS = {
    minWidth: 40,
    maxWidth: 300,
    textMultiplier: 6,
    defaultWidth: 50,
    sortDescFirst: true,
};

interface LandTableProps<T> {
    columns: LandColumnDef<T>[];
    data: T[];
    isLoading?: boolean;
    title?: ReactNode;
    description?: ReactNode;
    children?: ReactNode;
    columnFilters?: ColumnFiltersState;
    setColumnFilters?: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
    actionButton?: ReactNode;
    isDownloadable?: boolean; // TODO: Implement download functionality
}

export function Table<T>({
    title,
    description,
    columns: landColumn,
    data,
    columnFilters,
    setColumnFilters,
    isLoading = false,
    actionButton,
    isDownloadable,
    children,
}: LandTableProps<T>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const overrideColumnsSetting = useOverrideSettings(landColumn);
    const { columns: resizeColumns } = useDataBasedColumnSizing<T>(
        overrideColumnsSetting,
        data,
    );

    const table = useReactTable({
        data,
        columns: resizeColumns,
        defaultColumn: {
            sortDescFirst: DEFAULT_SETTINGS.sortDescFirst,
            minSize: 100,
        },
        state: { sorting, columnFilters },
        onColumnFiltersChange: setColumnFilters,
        enableColumnResizing: true,
        onSortingChange: (updaterOrValue) => {
            if (typeof updaterOrValue === "function") {
                setSorting(updaterOrValue(sorting)); // Call the function with the current state
            } else {
                setSorting(updaterOrValue); // Directly use the new value
            }
        },
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });
    const rows = table.getRowModel().rows;

    return (
        <div className="lui-table">
            <div className="lui-flex lui-justify-between lui-items-center lui-gap-2 ">
                {(title || description) && (
                    <div className="lui-mb-4 lui-flex lui-flex-col lui-gap-0.5  lui-max-w-5xl ">
                        {title && (
                            <Typography size="lg" color="gray-1000" weight="bold">
                                {title}
                            </Typography>
                        )}

                        {description && (
                            <Typography size="sm" color="gray-700">
                                {description}
                            </Typography>
                        )}
                    </div>
                )}

                <div className="lui-mb-4 lui-flex lui-gap-4 lui-justify-end lui-items-center lui-self-end">
                    {actionButton}
                </div>
            </div>

            {children}

            <div className="lui-table__wrapper">
                <table>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const isFullWidth =
                                        header.column.columnDef?.meta?.fullWidth;
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={
                                                isFullWidth
                                                    ? {}
                                                    : {
                                                          width: header.getSize(),
                                                      }
                                            }
                                            onClick={
                                                header.column.columnDef.enableSorting &&
                                                header.column.getToggleSortingHandler()
                                            }
                                            className={clsx({
                                                "lui-cursor-pointer lui-select-none":
                                                    header.column.columnDef
                                                        .enableSorting,
                                                "lui-w-full": isFullWidth,
                                            })}
                                        >
                                            <div>
                                                {!header.isPlaceholder && (
                                                    <div
                                                        className={clsx(
                                                            "lui-flex lui-w-full  lui-justify-between lui-gap-1 lui-items-center",
                                                            {
                                                                "lui-cursor-pointer":
                                                                    header.column
                                                                        .columnDef
                                                                        .enableSorting,
                                                                "lui-text-gray-1000 lui-font-bold":
                                                                    header.column.getIsSorted(),
                                                                "lui-text-gray-700 lui-font-medium":
                                                                    !header.column.getIsSorted(),
                                                            },
                                                        )}
                                                    >
                                                        <div>
                                                            {flexRender(
                                                                header.column.columnDef
                                                                    .header,
                                                                header.getContext(),
                                                            )}
                                                        </div>

                                                        {header.column.columnDef
                                                            .enableSorting &&
                                                            header.column.getIsSorted() && (
                                                                <>
                                                                    {header.column.getIsSorted() ===
                                                                    "asc"
                                                                        ? "↑"
                                                                        : "↓"}
                                                                </>
                                                            )}
                                                    </div>
                                                )}
                                            </div>

                                            {header.column.getCanResize() &&
                                                !isFullWidth && (
                                                    <div
                                                        onMouseDown={header.getResizeHandler()}
                                                        onTouchStart={header.getResizeHandler()}
                                                        // Prevent event bubbling to avoid sorting while resizing
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        className={`lui-table--resizer ${
                                                            header.column.getIsResizing()
                                                                ? "lui-table--isResizing"
                                                                : ""
                                                        }`}
                                                    ></div>
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>

                    <tbody className="lui-relative">
                        {rows.length > 0 &&
                            rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            style={{
                                                width: cell.column.getSize(),
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}

                        {rows.length === 0 && (
                            <tr>
                                <td colSpan={resizeColumns.length}>
                                    <Typography
                                        size="sm"
                                        color="gray-700"
                                        className="lui-text-center py-2"
                                    >
                                        No data available
                                    </Typography>
                                </td>
                            </tr>
                        )}
                        {isLoading && (
                            <tr className="lui-table__loading">
                                <td>
                                    <Typography size="sm" color="gray-700">
                                        <LoadingSpinner />
                                    </Typography>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {isDownloadable && (
                <div className="lui-flex lui-justify-end lui-mt-4 lui-px-2">
                    <CSVExportButton table={table} data={data} title={title} />
                </div>
            )}
            {/* TODO: Implement pagination component */}
        </div>
    );
}

function useOverrideSettings<T>(columns: LandColumnDef<T>[]) {
    return useMemo<ColumnDef<T>[]>(() => {
        return columns
            .filter((luiColumn) => !luiColumn.isHidden)
            .map((luiColumn) => {
                const column: Partial<ColumnDef<T>> = {
                    header: luiColumn.header,
                    enableResizing: luiColumn.enableResizing,
                };

                if (!isUndefined(luiColumn.id)) {
                    column.id = luiColumn.id;
                }

                if (!isUndefined(luiColumn.filterFn)) {
                    column.filterFn = luiColumn.filterFn;
                }

                if (!isUndefined(luiColumn.sortBy)) {
                    column.enableSorting = true;
                    column.sortingFn = getSortMethod(luiColumn.sortBy);
                }

                if (!isUndefined(luiColumn.cell)) {
                    column.cell = (t: CellContext<T, unknown>) =>
                        luiColumn.cell(t.row.original, t);
                }

                if (!isUndefined(luiColumn.size)) {
                    column.size = luiColumn.size;
                }

                if (luiColumn.fullWidth) {
                    column.meta = {
                        fullWidth: luiColumn.fullWidth,
                    };
                }

                return {
                    accessorKey: isString(luiColumn.accessor)
                        ? luiColumn.accessor
                        : null,
                    accessorFn: isFunction(luiColumn.accessor)
                        ? luiColumn.accessor
                        : null,
                    ...column,
                };
            });
    }, [columns]);
}

function getSortMethod<T>(sortBy: LandColumnDef<T>["sortBy"]): SortingFnOption<T> {
    if (isFunction(sortBy)) {
        return (a: Row<T>, b: Row<T>) => sortBy(a.original, b.original);
    }

    if (isString(sortBy)) {
        switch (sortBy) {
            case "alphanumeric":
                return "alphanumeric";

            case "datetime":
                return "datetime";

            // An example of new sorting method can be added here
            // case "number":
            //     return (a: Row<T>, b: Row<T>, columnId: string) => {
            //         return a.getValue(columnId) < b.getValue(columnId) ? 1 : -1;
            //     };

            default:
                return "auto";
        }
    }

    return "auto";
}

type DataItem = Record<string, any>;

/**
 * A hook to calculate column sizes based on the data provided.
 * It uses the first 10 rows of data to calculate the size of each column.
 * If the column is a string or number, it calculates the average length of the values.
 */
export const useDataBasedColumnSizing = <TData extends DataItem>(
    columns: ColumnDef<TData>[],
    data: TData[],
) => {
    // Analyze data to generate sizing info
    const columnSizes = useMemo<Record<keyof TData, number>>(() => {
        if (isEmpty(data)) return {} as Record<keyof TData, number>;

        // Get all unique keys from the data
        const allKeys = [
            ...new Set(data.flatMap((item) => Object.keys(item))),
        ] as Array<keyof TData>;

        // Create 10 sample dataset for analysis
        const sample = sampleSize(data, 10);

        // Create an empty object to store sizes
        const sizes = {} as Record<keyof TData, number>;

        // Process each key and calculate its size
        forEach(allKeys, (key) => {
            const values = chain(sample).map(key).compact().value();
            const lastValue = last(values);

            if (isString(lastValue) || isNumber(lastValue)) {
                const avgLength = meanBy(values, (val) => toString(val).length);
                const calculatedWidth = Math.round(
                    avgLength * DEFAULT_SETTINGS.textMultiplier,
                );

                sizes[key] = clamp(
                    calculatedWidth,
                    DEFAULT_SETTINGS.minWidth,
                    DEFAULT_SETTINGS.maxWidth,
                );
            } else {
                sizes[key] = DEFAULT_SETTINGS.defaultWidth;
            }
        });

        return sizes;
    }, [data]);

    /**
     * Applies calculated sizes to column definitions
     */
    const applySizing = useCallback(
        (columns: ColumnDef<TData>[]): ColumnDef<TData>[] =>
            columns.map((column) => {
                if ("accessorKey" in column && column.size === undefined) {
                    const key = column.accessorKey as keyof TData;
                    const size =
                        key && columnSizes[key]
                            ? columnSizes[key]
                            : DEFAULT_SETTINGS.defaultWidth;

                    // Increase size if header text is longer
                    const newSize = Math.max(
                        size,
                        column.header.length * DEFAULT_SETTINGS.textMultiplier,
                    );

                    return {
                        ...column,
                        size: newSize,
                    };
                }

                return column;
            }),
        [columnSizes],
    );

    const resizeColumns = useMemo(() => applySizing(columns), [applySizing, columns]);

    return {
        columns: resizeColumns,
    };
};

interface CSVExportButtonProps<T> {
    table: TanstackTable<T>;
    data: T[];
    title?: ReactNode;
}

function CSVExportButton<T>({
    table,
    title,
}: CSVExportButtonProps<T>): React.ReactElement {
    function getExportData(): Record<string, any>[] {
        const rows = table.getRowModel().rows;

        const jsonData = rows.map((row) => {
            const rowData: Record<string, any> = {};

            row.getVisibleCells().forEach((cell) => {
                const headerTitle = isString(cell.column.columnDef.header)
                    ? cell.column.columnDef.header
                    : cell.column.id;

                rowData[headerTitle] = cell.getValue();
            });

            return rowData;
        });

        return jsonData;
    }

    function handleExport(): void {
        const data = getExportData();

        const csv = Papa.unparse(data);

        // Create a Blob with the CSV data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        const fileName = isString(title) ? snakeCase(title) : "exported_data";
        const fullName = snakeCase(`${fileName}_${new Date().toLocaleString()}.csv`);
        link.setAttribute("download", fullName);
        link.style.visibility = "hidden";

        // Add to DOM, trigger download, and clean up
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Button variant="base" onClick={handleExport}>
            <Typography
                variant="span"
                color="primary-500"
                weight="medium"
                size="sm"
                className="lui-flex lui-items-center lui-gap-2"
            >
                <Icon name="Download" color="inherit" size="sm" />
                Download Comps
            </Typography>
        </Button>
    );
}
