// Error codes
export const INSUFFICIENT_FUNDS = "insufficient_funds";
export const USER_EXISTS = "user_exists";
export const DUPLICATE_LAND_SCRUB = "duplicate_land_scrub";

// Skip Trace pricing (must match settings.py)
export const SKIP_TRACE_PRICE_STANDARD = 2;
export const SKIP_TRACE_PRICE_PREMIUM = 4;
export const SKIP_TRACE_PRICE_PREMIUM_DNC = 1;

// Land Scrubbing prices (must match settings.py)
export const LAND_LOCKED_COST = 2;
export const WETLANDS_COST = 4;
export const FLOOD_ZONE_COST = 4;

// LandScrubbingRequest Status
export const LAND_SCRUB_COMPLETED = "Completed";
export const LAND_SCRUB_FAILURE = "Failure";
export const LAND_SCRUB_WORKING = "Working";

// LandScrubbingRow.Status
export const LAND_SCRUBBING_ROW_FAILURE = "failure";

// SkipTrace status field
export const SKIP_TRACE_SUCCESS = "success";
export const SKIP_TRACE_FAILURE = "failure";

// SkipTrace.ReportType choice field
export const SKIP_TRACE_REPORT_TYPE_STANDARD = "standard";
export const SKIP_TRACE_REPORT_TYPE_PREMIUM = "premium";

// WalletTransaction.CreditType
export const CREDIT_TYPE_CASH = "cash";
export const CREDIT_TYPE_GIFT = "gift";

export type AcreRangeKey =
    | "0-9.5k sqft"
    | "0-0.25 acre"
    | "0.15 acre-1 acre"
    | "0.2 acre-100 acre"
    | "9.5k sqft-1 acre"
    | "0.25 acre-1 acre"
    | "1 acre-2 acre"
    | "1 acre-3 acre"
    | "2 acre-5 acre"
    | "2 acre-100 acre"
    | "3 acre-5 acre"
    | "5 acre-10 acre"
    | "5 acre-100 acre"
    | "5 acre-10000 acre"
    | "10 acre-20 acre"
    | "20 acre-40 acre"
    | "40 acre-60 acre"
    | "40 acre-100 acre"
    | "40 acre-10000 acre"
    | "60 acre-80 acre"
    | "80 acre-500 acre"
    | "100 acre-10000 acre";

type RangePairTuple = [number, number, AcreRangeKey]; // minAcre, maxAcre, key

// Keep in sync with RANGE_PAIRS in backend/analytics/metrics.py
export const RANGE_PAIRS: RangePairTuple[] = [
    [0, 0.21809, "0-9.5k sqft"],
    [0, 0.25, "0-0.25 acre"],
    [0.15, 1, "0.15 acre-1 acre"],
    [0.2, 100, "0.2 acre-100 acre"],
    [0.21809, 1, "9.5k sqft-1 acre"],
    [0.25, 1, "0.25 acre-1 acre"],
    [1, 2, "1 acre-2 acre"],
    [1, 3, "1 acre-3 acre"],
    [2, 5, "2 acre-5 acre"],
    [2, 100, "2 acre-100 acre"],
    [3, 5, "3 acre-5 acre"],
    [5, 10, "5 acre-10 acre"],
    [5, 100, "5 acre-100 acre"],
    [5, 10000, "5 acre-10000 acre"],
    [10, 20, "10 acre-20 acre"],
    [20, 40, "20 acre-40 acre"],
    [40, 60, "40 acre-60 acre"],
    [40, 100, "40 acre-100 acre"],
    [40, 10000, "40 acre-10000 acre"],
    [60, 80, "60 acre-80 acre"],
    [80, 500, "80 acre-500 acre"],
    [100, 10000, "100 acre-10000 acre"],
];

// ACRE_RANGES maps internal acreage keys to formatted labels for the UI
export const ACRE_RANGES: { [key in AcreRangeKey]: string } = {
    "0-9.5k sqft": "0 to 9.5k sqft",
    "0-0.25 acre": "0 to 0.25 Acre",
    "0.15 acre-1 acre": "0.15 to 1 Acre",
    "0.2 acre-100 acre": "0.2 to 100 Acres",
    "9.5k sqft-1 acre": "9.5k sqft to 1 Acre",
    "0.25 acre-1 acre": "0.25 to 1 Acre",
    "1 acre-2 acre": "1 to 2 Acres",
    "1 acre-3 acre": "1 to 3 Acres",
    "2 acre-5 acre": "2 to 5 Acres",
    "2 acre-100 acre": "2 to 100 Acres",
    "3 acre-5 acre": "3 to 5 Acres",
    "5 acre-10 acre": "5 to 10 Acres",
    "5 acre-100 acre": "5 to 100 Acres",
    "5 acre-10000 acre": "5 to 10000 Acres",
    "10 acre-20 acre": "10 to 20 Acres",
    "20 acre-40 acre": "20 to 40 Acres",
    "40 acre-60 acre": "40 to 60 Acres",
    "40 acre-100 acre": "40 to 100 Acres",
    "40 acre-10000 acre": "40 to 10000 Acres",
    "60 acre-80 acre": "60 to 80 Acres",
    "80 acre-500 acre": "80 to 500 Acres",
    "100 acre-10000 acre": "100 to 10000 Acres",
};

export const DATA_PLATFORM_RANGE_PAIRS: RangePairTuple[] = [
    [0, 0.21809, "0-9.5k sqft"],
    [0.21809, 1, "9.5k sqft-1 acre"],
    [1, 2, "1 acre-2 acre"],
    [2, 5, "2 acre-5 acre"],
    [5, 10, "5 acre-10 acre"],
    [10, 20, "10 acre-20 acre"],
    [20, 40, "20 acre-40 acre"],
    [40, 60, "40 acre-60 acre"],
    [60, 80, "60 acre-80 acre"],
    [80, 500, "80 acre-500 acre"],
    [100, 10000, "100 acre-10000 acre"],
];

// Keep in sync with METRIC_KEYS in backend/analytics/metrics.py
export const METRIC_KEYS = [
    "Active",
    "Pending",
    "Sold: 1mo",
    "Sold: 3mo",
    "Sold: 6mo",
    "Sold: 1yr",
    "Pending STR",
    "1mo STR",
    "3mo STR",
    "6mo STR",
    "1yr STR",
    "Avg DOM 1mo",
    "Avg DOM 3mo",
    "Avg DOM 6mo",
    "Avg DOM 1yr",
    "Median Price 1mo",
    "Median Price 3mo",
    "Median Price 6mo",
    "Median Price 1yr",
    "Median Pending Price",
    "Median Active Price",
    "Median Active Acreage",
    "Median Pending Acreage",
    "Median Sold Acreage",
    "Median Pending PPA",
    "Median Active PPA",
    "Median Sold PPA",
    "Active PPA Min",
    "Active PPA Max",
    "Pending PPA Min",
    "Pending PPA Max",
    "PPA Range 1mo Min",
    "PPA Range 1mo Max",
    "PPA Range 3mo Min",
    "PPA Range 3mo Max",
    "PPA Range 6mo Min",
    "PPA Range 6mo Max",
    "PPA Range 1yr Min",
    "PPA Range 1yr Max",
    "Gini 1mo",
    "Gini 3mo",
    "Gini 6mo",
    "Gini 1yr",
];

export const GEO_SCALES = ["County", "ZIP"];

export const PRICING_TOOL_FEATURE_FLAG = "pricing-tool";
