import { useJumpToMapCoordinate } from "@src/hooks/useJumpToMapCoordinate";
import { ButtonIcon } from "@src/land_ui/button/button_icon";

interface ViewOnMapButtonProps {
    coordinates: number[];
}
export function ViewOnMapButton({ coordinates }: ViewOnMapButtonProps) {
    const { setCoordinate } = useJumpToMapCoordinate();

    return (
        <ButtonIcon
            icon="MapMarkIcon"
            onClick={() => {
                setCoordinate(coordinates);
            }}
            tooltipLabel="View On Map"
        />
    );
}
