import React from "react";
import { useLocalStorage } from "usehooks-ts";

import { Button } from "@src/land_ui/button/button";
import { Input } from "@src/land_ui/input/input";

export interface CompingLayerFilter {
    compsVisible: "all" | "selected";
    minAcres: string;
    maxAcres: string;
}

const DEFAULT_COMPING_LAYER_FILTER: CompingLayerFilter = {
    compsVisible: "all",
    minAcres: "",
    maxAcres: "",
};

interface useCompingLayerControlOptions {
    reportID: string;
}

export function useCompingLayerControl({ reportID }: useCompingLayerControlOptions) {
    const [compingLayerFilter, setCompingLayerFilter] =
        useLocalStorage<CompingLayerFilter>(
            `compingLayerControl-${reportID}`,
            DEFAULT_COMPING_LAYER_FILTER,
        );

    return {
        compingLayerFilter: { ...DEFAULT_COMPING_LAYER_FILTER, ...compingLayerFilter },
        setCompingLayerFilter: (newLayerFilter: Partial<CompingLayerFilter>) =>
            setCompingLayerFilter({
                ...compingLayerFilter,
                ...newLayerFilter,
            }),
    };
}

interface CompingLayerControlProps {
    reportID: string;
}

export function CompingLayerControl({ reportID }: CompingLayerControlProps) {
    const { compingLayerFilter, setCompingLayerFilter } = useCompingLayerControl({
        reportID,
    });

    const { compsVisible, minAcres, maxAcres } = compingLayerFilter;

    return (
        <div className="lui-flex lui-absolute lui-bottom-0 lui-left-1/2 -lui-translate-x-1/2 lui-justify-center lui-gap-3 lui-m-6 lui-w-fit ">
            <Button
                variant={compsVisible === "all" ? "primary" : "secondary"}
                onClick={() => setCompingLayerFilter({ compsVisible: "all" })}
            >
                All Comps
            </Button>
            <Button
                variant={compsVisible === "selected" ? "primary" : "secondary"}
                onClick={() => setCompingLayerFilter({ compsVisible: "selected" })}
            >
                Selected Comps
            </Button>
            <div className="lui-w-28">
                <Input
                    placeholder="Min ac."
                    type="number"
                    value={minAcres}
                    onChange={(e) =>
                        setCompingLayerFilter({
                            minAcres: e.target.value,
                        })
                    }
                    className="text-center"
                />
            </div>
            <div className="lui-w-28">
                <Input
                    placeholder="Max ac."
                    type="number"
                    value={maxAcres}
                    onChange={(e) =>
                        setCompingLayerFilter({
                            maxAcres: e.target.value,
                        })
                    }
                    className="text-center"
                />
            </div>
        </div>
    );
}
