import type { CompingReport } from "@src/orval/gen/model";
import type { Region } from "@src/pages/parcel_viewer/types";
import {
    getMetricsRange,
    getParcelMetrics,
    PLACEHOLDER_LABEL,
} from "@src/pages/parcel_viewer/utils";
import { useMemo } from "react";
import { CompingGauge } from "./comping_gauge";
import { CompingSectionContainer } from "./comping_section_container";

export const ENTIRE_COUNTY_RANGE = "0.2 acre-100 acre";

export function MarketScoreSection({
    region,
    report,
}: {
    region: Region;
    report: CompingReport;
}) {
    const targetMetrics = useMemo(
        () => getParcelMetrics(report.parcel, region),
        [report.parcel, region],
    );
    const countyMetrics = useMemo(
        () => getMetricsRange(region.stats, ENTIRE_COUNTY_RANGE),
        [region.stats],
    );
    const activeSupplyValue = useMemo(() => {
        return countyMetrics?.ACTIVE?.toString() || PLACEHOLDER_LABEL;
    }, [countyMetrics?.ACTIVE]);

    const activeSupplyPercent = useMemo(() => {
        if (!countyMetrics?.ACTIVE) {
            return 0;
        } else if (countyMetrics?.ACTIVE < 25 || countyMetrics?.ACTIVE > 300) {
            // red
            return 12.5;
        } else if (countyMetrics?.ACTIVE > 250) {
            // yellow
            return 65;
        }
        // green
        return 85;
    }, [countyMetrics?.ACTIVE]);

    const pricingDifficultiesPercentage = useMemo(() => {
        // Kyle consider a pricing difficulties of 70 or higher to be easier,
        // Since our gauge is currently set to 25 percent increment which makes it difficult to represent 70% as an easy value (Green text)
        return 100 - targetMetrics.GINI_1_YEAR + 10;
    }, [targetMetrics.GINI_1_YEAR]);

    const pricingDifficultiesLabel = useMemo(() => {
        if (pricingDifficultiesPercentage < 25) {
            return "Hard";
        } else if (pricingDifficultiesPercentage < 70) {
            return "Medium";
        }

        return "Easy";
    }, [pricingDifficultiesPercentage]);

    return (
        <CompingSectionContainer title="Market Scores">
            {countyMetrics && targetMetrics && (
                <>
                    <CompingGauge
                        percentage={activeSupplyPercent}
                        label={activeSupplyValue}
                        subLabel="On Market Comps"
                        title="Active Supply"
                        tooltipLabel="Total number of parcels listed for sale within the county."
                    />
                    <CompingGauge
                        percentage={countyMetrics.STR_6_MONTHS}
                        label={`${countyMetrics.STR_6_MONTHS}%`}
                        subLabel="6mo STR"
                        title="County Strength"
                        tooltipLabel="How strong the demand is for land within the county in the last 6 months. 50% means all parcels will typically sell within a year."
                    />
                    <CompingGauge
                        percentage={pricingDifficultiesPercentage}
                        label={pricingDifficultiesLabel}
                        subLabel={targetMetrics.ACREAGE_RANGE}
                        title="How Easy to Price?"
                        tooltipLabel="Determine by analyzing how price per acre behaves given similar sized properties in the county."
                    />
                </>
            )}
        </CompingSectionContainer>
    );
}
