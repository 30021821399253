import { useParcelsRetrieve } from "@src/orval/gen/api";
import { useQueryParam } from "./useQueryParam";
import { useSidebarControl } from "./useSidebarControl";

interface useParcelProps {
    parcelId: number;
    enabled: boolean;
}

// Specific hook with utilities for parcels controls and navigation
export function useParcel({ parcelId, enabled }: useParcelProps) {
    const {
        refetch,
        isLoading,
        data: parcel,
        error,
    } = useParcelsRetrieve(parcelId, {
        query: {
            enabled: enabled && Boolean(parcelId),
            queryKey: ["parcelsRetrieve", parcelId],
        },
    });
    return {
        parcel,
        refetch,
        isLoading,
        error,
    };
}

export function useParcelQueryParam() {
    const { hideSidebar } = useSidebarControl();

    const {
        value,
        setValue: setParcelParamValue,
        removeValue,
    } = useQueryParam<number>("parcel", null);

    function setValue(v: number) {
        setParcelParamValue(v);
        hideSidebar();
    }

    return {
        parcelQueryId: value,
        setParcelQuery: setValue,
        removeParcelQuery: removeValue,
    };
}
