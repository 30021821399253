import { useState } from "react";
import { type Comp, type CompingReport, type SourceComp } from "@src/orval/gen/model";
import { SoldCompsTable } from "@src/pages/comping/tables/sold";
import { CompAdjustmentsTable } from "@src/pages/comping/tables/adjustments";
import SelectedCompsTable from "@src/pages/comping/tables/selected";
import type { LandColumnDef } from "@src/land_ui/table/table";
import { formatDate } from "@src/functions/date_utils";
import { formatCurrency } from "@src/pages/parcel_viewer/utils";
import { CompStatusBadge } from "@src/pages/comping/comp_status_badge";
import { ActiveCompsTable } from "./active";
import { Typography } from "@src/land_ui/typography/typography";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";
import { useManualCompValues, useSelectedComps } from "@src/pages/comping/utils";
import { OfferAndProfitTable } from "./offer_and_profit";
import { Button } from "@src/land_ui/button/button";

export function CompingReportTables({ report }: { report: CompingReport }) {
    return (
        <div className="lui-flex lui-flex-col lui-gap-10">
            <ManualCompValue report={report} />
            <CompAdjustmentsTable report={report} />
            <SelectedCompsTable report={report} />
            <SoldCompsTable report={report} />
            <ActiveCompsTable report={report} />
        </div>
    );
}

function ManualCompValue({ report }: { report: CompingReport }) {
    const { averagePpa, fullPrice } = useManualCompValues(report);
    const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
    const { data: selectedComps } = useSelectedComps(report?.id);

    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-between">
                <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center">
                    <Typography color="gray-1000" size="lg" weight="bold">
                        Manual Comp Value
                    </Typography>
                    <ButtonIcon
                        icon="InfoCircle"
                        color="gray-600"
                        size="sm"
                        tooltipLabel="The calculated value of the property based on the comps you've selected plus your underwritten adjustments."
                    />
                </div>
                <div>
                    <TutorialButton youtubeId="BWrB2oBnFqI" />
                </div>
            </div>
            <div className="lui-flex lui-flex-col lui-items-center lui-justify-center lui-w-full lui-gap-2 lui-rounded-xl lui-border lui-border-gray-100 lui-border-solid lui-p-3">
                {selectedComps?.length > 0 ? (
                    <>
                        <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-center">
                            <Typography
                                color={fullPrice >= 0 ? "primary-500" : "red-800"}
                                size="4xl"
                                weight="bold"
                                className="lui-tracking-wide"
                            >
                                {formatCurrency(fullPrice)}
                            </Typography>
                            <ButtonIcon
                                icon="InfoCircle"
                                size="sm"
                                color="gray-600"
                                tooltipLabel="The calculated value of the property based on the comps you've selected plus your underwritten adjustments."
                            />
                        </div>
                        <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-center">
                            <Typography color="gray-1000" size="md" weight="medium">
                                {`${formatCurrency(averagePpa)} / ac`}
                            </Typography>
                            <ButtonIcon
                                icon="InfoCircle"
                                size="sm"
                                color="gray-600"
                                tooltipLabel="The calculated value of the property based on the comps you’ve selected plus your underwritten adjustments."
                            />
                        </div>
                        {!isCalculatorOpen && (
                            <Button
                                variant="inline"
                                icon="Calculator"
                                onClick={() => setIsCalculatorOpen(true)}
                            >
                                Show Offer & Profit Calculator
                            </Button>
                        )}
                    </>
                ) : (
                    <div className="lui-flex lui-flex-col lui-gap-2 lui-text-center">
                        <Typography color="primary-500" size="lg" weight="medium">
                            No comps selected yet.
                        </Typography>
                        <Typography color="gray-700" size="sm" weight="regular">
                            Get started and select sold or active comps to get comp
                            value estimate.
                        </Typography>
                    </div>
                )}
            </div>

            {isCalculatorOpen && (
                <OfferAndProfitTable
                    price={fullPrice}
                    title="Manual Comp Offer and Profit"
                    subTitle="Use this calculator to determine an offer price and the potential of the deal based on your manually calculated market value. You can copy any of the values by clicking on the green button to the right of the value."
                />
            )}
        </div>
    );
}

export interface TableComp extends Comp {
    distance?: string;
    source_comp_record?: SourceComp;
}

export const defaultTableColumns: LandColumnDef<TableComp>[] = [
    {
        header: "Age",
        accessor: "sold_date",
        sortBy: "datetime",
        cell: (row) => {
            return <>{formatDate(row.sold_date, "RELATIVE")}</>;
        },
        size: 50,
    },
    {
        header: "Price",
        accessor: "price",
        cell: (row) => <>{formatCurrency(row.price)}</>,
        sortBy: "alphanumeric",
    },
    {
        header: "Acre",
        id: "acre",
        accessor: "lot_size",
        sortBy: "alphanumeric",
        filterFn: "inNumberRange",
    },
    {
        header: "PPA",
        accessor: (row) => row.price / row.lot_size,
        sortBy: "alphanumeric",
        cell: (row) => <>{formatCurrency(row.price / row.lot_size)}</>,
    },
    {
        header: "Distance",
        accessor: "distance",
        sortBy: "alphanumeric",
    },
    { header: "ZIP", accessor: "zip", sortBy: "alphanumeric" },
    {
        header: "DoM",
        accessor: "days_on_market",
        sortBy: "alphanumeric",

        cell: (row) => (
            <>
                {/*  Hide -1 values */}
                {row.days_on_market >= 0 ? row.days_on_market : ""}
            </>
        ),
    },
    {
        header: "Status",
        id: "status",
        filterFn: (row, columnId, filterValue, _addMeta) =>
            filterValue.includes(row.getValue(columnId)),
        accessor: "status",
        cell: (row) => <CompStatusBadge status={row.status} />,
        sortBy: "alphanumeric",
    },
];

export const getTableColumns = (...additionalColumns: LandColumnDef<TableComp>[]) => {
    return [...defaultTableColumns, ...additionalColumns];
};
