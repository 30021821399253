/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  APIError,
  AutoCompPrice,
  AutocompleteLookupResponse,
  AutocompleteLookupRetrieveParams,
  AutocompleteResponse,
  AutocompleteRetrieveParams,
  Comp,
  CompAdjustment,
  CompingReport,
  CompsListParams,
  ExportsListParams,
  ExportsParcelsListParams,
  ExportsRowsListParams,
  FullParcel,
  InsightRulesResponse,
  OwnerPortfolioResponse,
  OwnerSearchResponse,
  PaginatedCompingReportList,
  PaginatedFullParcelList,
  PaginatedParcelExportReadOnlyList,
  PaginatedParcelExportRowList,
  PaginatedParcelSavedListList,
  ParcelExportCreate,
  ParcelExportPriceBreakdown,
  ParcelExportReadOnly,
  ParcelExportRow,
  ParcelExportRowUpdate,
  ParcelExportTag,
  ParcelExportUpdate,
  ParcelSavedList,
  ParcelSearchResponse,
  ParcelsListParams,
  ParcelsOwnerSearchListParams,
  ParcelsSearchRetrieveParams,
  PatchedCompAdjustment,
  PatchedCompingReport,
  PatchedParcelExportRow,
  PatchedParcelExportTag,
  PatchedParcelSavedList,
  PatchedPricingReport,
  PricingReport,
  ReportsListParams,
  SavedComp,
  SavedlistsListParams
} from './model'
import { customInstance } from '../axios-instance';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;





export const compsList = (
    params?: CompsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Comp[]>(
      {url: `/api/analytics/comps/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCompsListQueryKey = (params?: CompsListParams,) => {
    return [`/api/analytics/comps/`, ...(params ? [params]: [])] as const;
    }

    
export const getCompsListQueryOptions = <TData = Awaited<ReturnType<typeof compsList>>, TError = unknown>(params?: CompsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof compsList>>> = ({ signal }) => compsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type CompsListQueryResult = NonNullable<Awaited<ReturnType<typeof compsList>>>
export type CompsListQueryError = unknown


export function useCompsList<TData = Awaited<ReturnType<typeof compsList>>, TError = unknown>(
 params: undefined |  CompsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof compsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useCompsList<TData = Awaited<ReturnType<typeof compsList>>, TError = unknown>(
 params?: CompsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof compsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useCompsList<TData = Awaited<ReturnType<typeof compsList>>, TError = unknown>(
 params?: CompsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useCompsList<TData = Awaited<ReturnType<typeof compsList>>, TError = unknown>(
 params?: CompsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getCompsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const compsRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Comp>(
      {url: `/api/analytics/comps/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getCompsRetrieveQueryKey = (id: string,) => {
    return [`/api/analytics/comps/${id}/`] as const;
    }

    
export const getCompsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof compsRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompsRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof compsRetrieve>>> = ({ signal }) => compsRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type CompsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof compsRetrieve>>>
export type CompsRetrieveQueryError = unknown


export function useCompsRetrieve<TData = Awaited<ReturnType<typeof compsRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof compsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useCompsRetrieve<TData = Awaited<ReturnType<typeof compsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof compsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useCompsRetrieve<TData = Awaited<ReturnType<typeof compsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useCompsRetrieve<TData = Awaited<ReturnType<typeof compsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof compsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getCompsRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const pricingList = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<PricingReport[]>(
      {url: `/api/comping/pricing/`, method: 'GET', signal
    },
      );
    }
  

export const getPricingListQueryKey = () => {
    return [`/api/comping/pricing/`] as const;
    }

    
export const getPricingListQueryOptions = <TData = Awaited<ReturnType<typeof pricingList>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPricingListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pricingList>>> = ({ signal }) => pricingList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type PricingListQueryResult = NonNullable<Awaited<ReturnType<typeof pricingList>>>
export type PricingListQueryError = unknown


export function usePricingList<TData = Awaited<ReturnType<typeof pricingList>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pricingList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePricingList<TData = Awaited<ReturnType<typeof pricingList>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pricingList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePricingList<TData = Awaited<ReturnType<typeof pricingList>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function usePricingList<TData = Awaited<ReturnType<typeof pricingList>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getPricingListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const pricingCreate = (
    pricingReport: NonReadonly<PricingReport>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PricingReport>(
      {url: `/api/comping/pricing/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pricingReport, signal
    },
      );
    }
  


export const getPricingCreateMutationOptions = <TData = Awaited<ReturnType<typeof pricingCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<PricingReport>}, TContext>, }
) => {
const mutationKey = ['pricingCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pricingCreate>>, {data: NonReadonly<PricingReport>}> = (props) => {
          const {data} = props ?? {};

          return  pricingCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NonReadonly<PricingReport>}, TContext>}

    export type PricingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof pricingCreate>>>
    export type PricingCreateMutationBody = NonReadonly<PricingReport>
    export type PricingCreateMutationError = unknown

    export const usePricingCreate = <TData = Awaited<ReturnType<typeof pricingCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<PricingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {data: NonReadonly<PricingReport>},
        TContext
      > => {

      const mutationOptions = getPricingCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const pricingRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PricingReport>(
      {url: `/api/comping/pricing/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getPricingRetrieveQueryKey = (id: string,) => {
    return [`/api/comping/pricing/${id}/`] as const;
    }

    
export const getPricingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pricingRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPricingRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pricingRetrieve>>> = ({ signal }) => pricingRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type PricingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pricingRetrieve>>>
export type PricingRetrieveQueryError = unknown


export function usePricingRetrieve<TData = Awaited<ReturnType<typeof pricingRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pricingRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePricingRetrieve<TData = Awaited<ReturnType<typeof pricingRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pricingRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePricingRetrieve<TData = Awaited<ReturnType<typeof pricingRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function usePricingRetrieve<TData = Awaited<ReturnType<typeof pricingRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pricingRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getPricingRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const pricingUpdate = (
    id: string,
    pricingReport: NonReadonly<PricingReport>,
 ) => {
      
      
      return customInstance<PricingReport>(
      {url: `/api/comping/pricing/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pricingReport
    },
      );
    }
  


export const getPricingUpdateMutationOptions = <TData = Awaited<ReturnType<typeof pricingUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PricingReport>}, TContext>, }
) => {
const mutationKey = ['pricingUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pricingUpdate>>, {id: string;data: NonReadonly<PricingReport>}> = (props) => {
          const {id,data} = props ?? {};

          return  pricingUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PricingReport>}, TContext>}

    export type PricingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof pricingUpdate>>>
    export type PricingUpdateMutationBody = NonReadonly<PricingReport>
    export type PricingUpdateMutationError = unknown

    export const usePricingUpdate = <TData = Awaited<ReturnType<typeof pricingUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PricingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<PricingReport>},
        TContext
      > => {

      const mutationOptions = getPricingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const pricingPartialUpdate = (
    id: string,
    patchedPricingReport: NonReadonly<PatchedPricingReport>,
 ) => {
      
      
      return customInstance<PricingReport>(
      {url: `/api/comping/pricing/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedPricingReport
    },
      );
    }
  


export const getPricingPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof pricingPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedPricingReport>}, TContext>, }
) => {
const mutationKey = ['pricingPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pricingPartialUpdate>>, {id: string;data: NonReadonly<PatchedPricingReport>}> = (props) => {
          const {id,data} = props ?? {};

          return  pricingPartialUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedPricingReport>}, TContext>}

    export type PricingPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof pricingPartialUpdate>>>
    export type PricingPartialUpdateMutationBody = NonReadonly<PatchedPricingReport>
    export type PricingPartialUpdateMutationError = unknown

    export const usePricingPartialUpdate = <TData = Awaited<ReturnType<typeof pricingPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedPricingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<PatchedPricingReport>},
        TContext
      > => {

      const mutationOptions = getPricingPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const pricingDestroy = (
    id: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/comping/pricing/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getPricingDestroyMutationOptions = <TData = Awaited<ReturnType<typeof pricingDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
) => {
const mutationKey = ['pricingDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pricingDestroy>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  pricingDestroy(id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type PricingDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof pricingDestroy>>>
    
    export type PricingDestroyMutationError = unknown

    export const usePricingDestroy = <TData = Awaited<ReturnType<typeof pricingDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPricingDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsList = (
    params?: ReportsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedCompingReportList>(
      {url: `/api/comping/reports/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReportsListQueryKey = (params?: ReportsListParams,) => {
    return [`/api/comping/reports/`, ...(params ? [params]: [])] as const;
    }

    
export const getReportsListQueryOptions = <TData = Awaited<ReturnType<typeof reportsList>>, TError = unknown>(params?: ReportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsList>>> = ({ signal }) => reportsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof reportsList>>>
export type ReportsListQueryError = unknown


export function useReportsList<TData = Awaited<ReturnType<typeof reportsList>>, TError = unknown>(
 params: undefined |  ReportsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsList<TData = Awaited<ReturnType<typeof reportsList>>, TError = unknown>(
 params?: ReportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsList<TData = Awaited<ReturnType<typeof reportsList>>, TError = unknown>(
 params?: ReportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsList<TData = Awaited<ReturnType<typeof reportsList>>, TError = unknown>(
 params?: ReportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsCreate = (
    compingReport: NonReadonly<CompingReport>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompingReport>(
      {url: `/api/comping/reports/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: compingReport, signal
    },
      );
    }
  


export const getReportsCreateMutationOptions = <TData = Awaited<ReturnType<typeof reportsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<CompingReport>}, TContext>, }
) => {
const mutationKey = ['reportsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsCreate>>, {data: NonReadonly<CompingReport>}> = (props) => {
          const {data} = props ?? {};

          return  reportsCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NonReadonly<CompingReport>}, TContext>}

    export type ReportsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsCreate>>>
    export type ReportsCreateMutationBody = NonReadonly<CompingReport>
    export type ReportsCreateMutationError = unknown

    export const useReportsCreate = <TData = Awaited<ReturnType<typeof reportsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<CompingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {data: NonReadonly<CompingReport>},
        TContext
      > => {

      const mutationOptions = getReportsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompingReport>(
      {url: `/api/comping/reports/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsRetrieveQueryKey = (id: string,) => {
    return [`/api/comping/reports/${id}/`] as const;
    }

    
export const getReportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof reportsRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsRetrieve>>> = ({ signal }) => reportsRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof reportsRetrieve>>>
export type ReportsRetrieveQueryError = unknown


export function useReportsRetrieve<TData = Awaited<ReturnType<typeof reportsRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsRetrieve<TData = Awaited<ReturnType<typeof reportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsRetrieve<TData = Awaited<ReturnType<typeof reportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsRetrieve<TData = Awaited<ReturnType<typeof reportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsUpdate = (
    id: string,
    compingReport: NonReadonly<CompingReport>,
 ) => {
      
      
      return customInstance<CompingReport>(
      {url: `/api/comping/reports/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: compingReport
    },
      );
    }
  


export const getReportsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof reportsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<CompingReport>}, TContext>, }
) => {
const mutationKey = ['reportsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsUpdate>>, {id: string;data: NonReadonly<CompingReport>}> = (props) => {
          const {id,data} = props ?? {};

          return  reportsUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<CompingReport>}, TContext>}

    export type ReportsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsUpdate>>>
    export type ReportsUpdateMutationBody = NonReadonly<CompingReport>
    export type ReportsUpdateMutationError = unknown

    export const useReportsUpdate = <TData = Awaited<ReturnType<typeof reportsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<CompingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<CompingReport>},
        TContext
      > => {

      const mutationOptions = getReportsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsPartialUpdate = (
    id: string,
    patchedCompingReport: NonReadonly<PatchedCompingReport>,
 ) => {
      
      
      return customInstance<CompingReport>(
      {url: `/api/comping/reports/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedCompingReport
    },
      );
    }
  


export const getReportsPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof reportsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedCompingReport>}, TContext>, }
) => {
const mutationKey = ['reportsPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsPartialUpdate>>, {id: string;data: NonReadonly<PatchedCompingReport>}> = (props) => {
          const {id,data} = props ?? {};

          return  reportsPartialUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedCompingReport>}, TContext>}

    export type ReportsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsPartialUpdate>>>
    export type ReportsPartialUpdateMutationBody = NonReadonly<PatchedCompingReport>
    export type ReportsPartialUpdateMutationError = unknown

    export const useReportsPartialUpdate = <TData = Awaited<ReturnType<typeof reportsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedCompingReport>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<PatchedCompingReport>},
        TContext
      > => {

      const mutationOptions = getReportsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsDestroy = (
    id: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/comping/reports/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getReportsDestroyMutationOptions = <TData = Awaited<ReturnType<typeof reportsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
) => {
const mutationKey = ['reportsDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsDestroy>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  reportsDestroy(id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type ReportsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof reportsDestroy>>>
    
    export type ReportsDestroyMutationError = unknown

    export const useReportsDestroy = <TData = Awaited<ReturnType<typeof reportsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getReportsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsAutoSelectCompsRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<AutoCompPrice>(
      {url: `/api/comping/reports/${id}/auto_select_comps/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsAutoSelectCompsRetrieveQueryKey = (id: string,) => {
    return [`/api/comping/reports/${id}/auto_select_comps/`] as const;
    }

    
export const getReportsAutoSelectCompsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsAutoSelectCompsRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>> = ({ signal }) => reportsAutoSelectCompsRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsAutoSelectCompsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>>
export type ReportsAutoSelectCompsRetrieveQueryError = unknown


export function useReportsAutoSelectCompsRetrieve<TData = Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAutoSelectCompsRetrieve<TData = Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAutoSelectCompsRetrieve<TData = Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsAutoSelectCompsRetrieve<TData = Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAutoSelectCompsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsAutoSelectCompsRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsAutoSelectCompsCreate = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<AutoCompPrice>(
      {url: `/api/comping/reports/${id}/auto_select_comps/`, method: 'POST', signal
    },
      );
    }
  


export const getReportsAutoSelectCompsCreateMutationOptions = <TData = Awaited<ReturnType<typeof reportsAutoSelectCompsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
) => {
const mutationKey = ['reportsAutoSelectCompsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsAutoSelectCompsCreate>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  reportsAutoSelectCompsCreate(id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type ReportsAutoSelectCompsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsAutoSelectCompsCreate>>>
    
    export type ReportsAutoSelectCompsCreateMutationError = unknown

    export const useReportsAutoSelectCompsCreate = <TData = Awaited<ReturnType<typeof reportsAutoSelectCompsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getReportsAutoSelectCompsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsAdjustmentsList = (
    reportId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompAdjustment[]>(
      {url: `/api/comping/reports/${reportId}/adjustments/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsAdjustmentsListQueryKey = (reportId: string,) => {
    return [`/api/comping/reports/${reportId}/adjustments/`] as const;
    }

    
export const getReportsAdjustmentsListQueryOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError = unknown>(reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsAdjustmentsListQueryKey(reportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsAdjustmentsList>>> = ({ signal }) => reportsAdjustmentsList(reportId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsAdjustmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsList>>>
export type ReportsAdjustmentsListQueryError = unknown


export function useReportsAdjustmentsList<TData = Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError = unknown>(
 reportId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAdjustmentsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAdjustmentsList<TData = Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAdjustmentsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAdjustmentsList<TData = Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsAdjustmentsList<TData = Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsAdjustmentsListQueryOptions(reportId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsAdjustmentsCreate = (
    reportId: string,
    compAdjustment: NonReadonly<CompAdjustment>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompAdjustment>(
      {url: `/api/comping/reports/${reportId}/adjustments/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: compAdjustment, signal
    },
      );
    }
  


export const getReportsAdjustmentsCreateMutationOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<CompAdjustment>}, TContext>, }
) => {
const mutationKey = ['reportsAdjustmentsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsAdjustmentsCreate>>, {reportId: string;data: NonReadonly<CompAdjustment>}> = (props) => {
          const {reportId,data} = props ?? {};

          return  reportsAdjustmentsCreate(reportId,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<CompAdjustment>}, TContext>}

    export type ReportsAdjustmentsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsCreate>>>
    export type ReportsAdjustmentsCreateMutationBody = NonReadonly<CompAdjustment>
    export type ReportsAdjustmentsCreateMutationError = unknown

    export const useReportsAdjustmentsCreate = <TData = Awaited<ReturnType<typeof reportsAdjustmentsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<CompAdjustment>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;data: NonReadonly<CompAdjustment>},
        TContext
      > => {

      const mutationOptions = getReportsAdjustmentsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsAdjustmentsRetrieve = (
    reportId: string,
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompAdjustment>(
      {url: `/api/comping/reports/${reportId}/adjustments/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsAdjustmentsRetrieveQueryKey = (reportId: string,
    id: number,) => {
    return [`/api/comping/reports/${reportId}/adjustments/${id}/`] as const;
    }

    
export const getReportsAdjustmentsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError = unknown>(reportId: string,
    id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsAdjustmentsRetrieveQueryKey(reportId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>> = ({ signal }) => reportsAdjustmentsRetrieve(reportId,id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsAdjustmentsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>>
export type ReportsAdjustmentsRetrieveQueryError = unknown


export function useReportsAdjustmentsRetrieve<TData = Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError = unknown>(
 reportId: string,
    id: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAdjustmentsRetrieve<TData = Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError = unknown>(
 reportId: string,
    id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsAdjustmentsRetrieve<TData = Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError = unknown>(
 reportId: string,
    id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsAdjustmentsRetrieve<TData = Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError = unknown>(
 reportId: string,
    id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsAdjustmentsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsAdjustmentsRetrieveQueryOptions(reportId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsAdjustmentsUpdate = (
    reportId: string,
    id: number,
    compAdjustment: NonReadonly<CompAdjustment>,
 ) => {
      
      
      return customInstance<CompAdjustment>(
      {url: `/api/comping/reports/${reportId}/adjustments/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: compAdjustment
    },
      );
    }
  


export const getReportsAdjustmentsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<CompAdjustment>}, TContext>, }
) => {
const mutationKey = ['reportsAdjustmentsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsAdjustmentsUpdate>>, {reportId: string;id: number;data: NonReadonly<CompAdjustment>}> = (props) => {
          const {reportId,id,data} = props ?? {};

          return  reportsAdjustmentsUpdate(reportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<CompAdjustment>}, TContext>}

    export type ReportsAdjustmentsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsUpdate>>>
    export type ReportsAdjustmentsUpdateMutationBody = NonReadonly<CompAdjustment>
    export type ReportsAdjustmentsUpdateMutationError = unknown

    export const useReportsAdjustmentsUpdate = <TData = Awaited<ReturnType<typeof reportsAdjustmentsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<CompAdjustment>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;id: number;data: NonReadonly<CompAdjustment>},
        TContext
      > => {

      const mutationOptions = getReportsAdjustmentsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsAdjustmentsPartialUpdate = (
    reportId: string,
    id: number,
    patchedCompAdjustment: NonReadonly<PatchedCompAdjustment>,
 ) => {
      
      
      return customInstance<CompAdjustment>(
      {url: `/api/comping/reports/${reportId}/adjustments/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedCompAdjustment
    },
      );
    }
  


export const getReportsAdjustmentsPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<PatchedCompAdjustment>}, TContext>, }
) => {
const mutationKey = ['reportsAdjustmentsPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsAdjustmentsPartialUpdate>>, {reportId: string;id: number;data: NonReadonly<PatchedCompAdjustment>}> = (props) => {
          const {reportId,id,data} = props ?? {};

          return  reportsAdjustmentsPartialUpdate(reportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<PatchedCompAdjustment>}, TContext>}

    export type ReportsAdjustmentsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsPartialUpdate>>>
    export type ReportsAdjustmentsPartialUpdateMutationBody = NonReadonly<PatchedCompAdjustment>
    export type ReportsAdjustmentsPartialUpdateMutationError = unknown

    export const useReportsAdjustmentsPartialUpdate = <TData = Awaited<ReturnType<typeof reportsAdjustmentsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number;data: NonReadonly<PatchedCompAdjustment>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;id: number;data: NonReadonly<PatchedCompAdjustment>},
        TContext
      > => {

      const mutationOptions = getReportsAdjustmentsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsAdjustmentsDestroy = (
    reportId: string,
    id: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/comping/reports/${reportId}/adjustments/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getReportsAdjustmentsDestroyMutationOptions = <TData = Awaited<ReturnType<typeof reportsAdjustmentsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number}, TContext>, }
) => {
const mutationKey = ['reportsAdjustmentsDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsAdjustmentsDestroy>>, {reportId: string;id: number}> = (props) => {
          const {reportId,id} = props ?? {};

          return  reportsAdjustmentsDestroy(reportId,id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;id: number}, TContext>}

    export type ReportsAdjustmentsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof reportsAdjustmentsDestroy>>>
    
    export type ReportsAdjustmentsDestroyMutationError = unknown

    export const useReportsAdjustmentsDestroy = <TData = Awaited<ReturnType<typeof reportsAdjustmentsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: number}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;id: number},
        TContext
      > => {

      const mutationOptions = getReportsAdjustmentsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsSavedCompsList = (
    reportId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SavedComp[]>(
      {url: `/api/comping/reports/${reportId}/saved_comps/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsSavedCompsListQueryKey = (reportId: string,) => {
    return [`/api/comping/reports/${reportId}/saved_comps/`] as const;
    }

    
export const getReportsSavedCompsListQueryOptions = <TData = Awaited<ReturnType<typeof reportsSavedCompsList>>, TError = unknown>(reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsSavedCompsListQueryKey(reportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsSavedCompsList>>> = ({ signal }) => reportsSavedCompsList(reportId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsSavedCompsListQueryResult = NonNullable<Awaited<ReturnType<typeof reportsSavedCompsList>>>
export type ReportsSavedCompsListQueryError = unknown


export function useReportsSavedCompsList<TData = Awaited<ReturnType<typeof reportsSavedCompsList>>, TError = unknown>(
 reportId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsSavedCompsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsSavedCompsList<TData = Awaited<ReturnType<typeof reportsSavedCompsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsSavedCompsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsSavedCompsList<TData = Awaited<ReturnType<typeof reportsSavedCompsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsSavedCompsList<TData = Awaited<ReturnType<typeof reportsSavedCompsList>>, TError = unknown>(
 reportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsSavedCompsListQueryOptions(reportId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsSavedCompsCreate = (
    reportId: string,
    savedComp: NonReadonly<SavedComp>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SavedComp>(
      {url: `/api/comping/reports/${reportId}/saved_comps/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: savedComp, signal
    },
      );
    }
  


export const getReportsSavedCompsCreateMutationOptions = <TData = Awaited<ReturnType<typeof reportsSavedCompsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<SavedComp>}, TContext>, }
) => {
const mutationKey = ['reportsSavedCompsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsSavedCompsCreate>>, {reportId: string;data: NonReadonly<SavedComp>}> = (props) => {
          const {reportId,data} = props ?? {};

          return  reportsSavedCompsCreate(reportId,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<SavedComp>}, TContext>}

    export type ReportsSavedCompsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsSavedCompsCreate>>>
    export type ReportsSavedCompsCreateMutationBody = NonReadonly<SavedComp>
    export type ReportsSavedCompsCreateMutationError = unknown

    export const useReportsSavedCompsCreate = <TData = Awaited<ReturnType<typeof reportsSavedCompsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;data: NonReadonly<SavedComp>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;data: NonReadonly<SavedComp>},
        TContext
      > => {

      const mutationOptions = getReportsSavedCompsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reportsSavedCompsRetrieve = (
    reportId: string,
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SavedComp>(
      {url: `/api/comping/reports/${reportId}/saved_comps/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getReportsSavedCompsRetrieveQueryKey = (reportId: string,
    id: string,) => {
    return [`/api/comping/reports/${reportId}/saved_comps/${id}/`] as const;
    }

    
export const getReportsSavedCompsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError = unknown>(reportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReportsSavedCompsRetrieveQueryKey(reportId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>> = ({ signal }) => reportsSavedCompsRetrieve(reportId,id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReportsSavedCompsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>>
export type ReportsSavedCompsRetrieveQueryError = unknown


export function useReportsSavedCompsRetrieve<TData = Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError = unknown>(
 reportId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsSavedCompsRetrieve<TData = Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError = unknown>(
 reportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReportsSavedCompsRetrieve<TData = Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError = unknown>(
 reportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useReportsSavedCompsRetrieve<TData = Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError = unknown>(
 reportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsSavedCompsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReportsSavedCompsRetrieveQueryOptions(reportId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const reportsSavedCompsDestroy = (
    reportId: string,
    id: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/comping/reports/${reportId}/saved_comps/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getReportsSavedCompsDestroyMutationOptions = <TData = Awaited<ReturnType<typeof reportsSavedCompsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: string}, TContext>, }
) => {
const mutationKey = ['reportsSavedCompsDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsSavedCompsDestroy>>, {reportId: string;id: string}> = (props) => {
          const {reportId,id} = props ?? {};

          return  reportsSavedCompsDestroy(reportId,id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{reportId: string;id: string}, TContext>}

    export type ReportsSavedCompsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof reportsSavedCompsDestroy>>>
    
    export type ReportsSavedCompsDestroyMutationError = unknown

    export const useReportsSavedCompsDestroy = <TData = Awaited<ReturnType<typeof reportsSavedCompsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{reportId: string;id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {reportId: string;id: string},
        TContext
      > => {

      const mutationOptions = getReportsSavedCompsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const autocompleteRetrieve = (
    params: AutocompleteRetrieveParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<AutocompleteResponse>(
      {url: `/api/property/autocomplete/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getAutocompleteRetrieveQueryKey = (params: AutocompleteRetrieveParams,) => {
    return [`/api/property/autocomplete/`, ...(params ? [params]: [])] as const;
    }

    
export const getAutocompleteRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof autocompleteRetrieve>>, TError = unknown>(params: AutocompleteRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAutocompleteRetrieveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof autocompleteRetrieve>>> = ({ signal }) => autocompleteRetrieve(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type AutocompleteRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof autocompleteRetrieve>>>
export type AutocompleteRetrieveQueryError = unknown


export function useAutocompleteRetrieve<TData = Awaited<ReturnType<typeof autocompleteRetrieve>>, TError = unknown>(
 params: AutocompleteRetrieveParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof autocompleteRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useAutocompleteRetrieve<TData = Awaited<ReturnType<typeof autocompleteRetrieve>>, TError = unknown>(
 params: AutocompleteRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof autocompleteRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useAutocompleteRetrieve<TData = Awaited<ReturnType<typeof autocompleteRetrieve>>, TError = unknown>(
 params: AutocompleteRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useAutocompleteRetrieve<TData = Awaited<ReturnType<typeof autocompleteRetrieve>>, TError = unknown>(
 params: AutocompleteRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getAutocompleteRetrieveQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const autocompleteLookupRetrieve = (
    params: AutocompleteLookupRetrieveParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<AutocompleteLookupResponse>(
      {url: `/api/property/autocomplete/lookup/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getAutocompleteLookupRetrieveQueryKey = (params: AutocompleteLookupRetrieveParams,) => {
    return [`/api/property/autocomplete/lookup/`, ...(params ? [params]: [])] as const;
    }

    
export const getAutocompleteLookupRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError = unknown>(params: AutocompleteLookupRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAutocompleteLookupRetrieveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>> = ({ signal }) => autocompleteLookupRetrieve(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type AutocompleteLookupRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>>
export type AutocompleteLookupRetrieveQueryError = unknown


export function useAutocompleteLookupRetrieve<TData = Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError = unknown>(
 params: AutocompleteLookupRetrieveParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof autocompleteLookupRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useAutocompleteLookupRetrieve<TData = Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError = unknown>(
 params: AutocompleteLookupRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof autocompleteLookupRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useAutocompleteLookupRetrieve<TData = Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError = unknown>(
 params: AutocompleteLookupRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useAutocompleteLookupRetrieve<TData = Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError = unknown>(
 params: AutocompleteLookupRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteLookupRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getAutocompleteLookupRetrieveQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsList = (
    params?: ExportsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedParcelExportReadOnlyList>(
      {url: `/api/property/exports/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExportsListQueryKey = (params?: ExportsListParams,) => {
    return [`/api/property/exports/`, ...(params ? [params]: [])] as const;
    }

    
export const getExportsListQueryOptions = <TData = Awaited<ReturnType<typeof exportsList>>, TError = unknown>(params?: ExportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsList>>> = ({ signal }) => exportsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsListQueryResult = NonNullable<Awaited<ReturnType<typeof exportsList>>>
export type ExportsListQueryError = unknown


export function useExportsList<TData = Awaited<ReturnType<typeof exportsList>>, TError = unknown>(
 params: undefined |  ExportsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsList<TData = Awaited<ReturnType<typeof exportsList>>, TError = unknown>(
 params?: ExportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsList<TData = Awaited<ReturnType<typeof exportsList>>, TError = unknown>(
 params?: ExportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsList<TData = Awaited<ReturnType<typeof exportsList>>, TError = unknown>(
 params?: ExportsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsRowsList = (
    parcelExportId: string,
    params?: ExportsRowsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedParcelExportRowList>(
      {url: `/api/property/exports/${parcelExportId}/rows/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExportsRowsListQueryKey = (parcelExportId: string,
    params?: ExportsRowsListParams,) => {
    return [`/api/property/exports/${parcelExportId}/rows/`, ...(params ? [params]: [])] as const;
    }

    
export const getExportsRowsListQueryOptions = <TData = Awaited<ReturnType<typeof exportsRowsList>>, TError = unknown>(parcelExportId: string,
    params?: ExportsRowsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsRowsListQueryKey(parcelExportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsRowsList>>> = ({ signal }) => exportsRowsList(parcelExportId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(parcelExportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsRowsListQueryResult = NonNullable<Awaited<ReturnType<typeof exportsRowsList>>>
export type ExportsRowsListQueryError = unknown


export function useExportsRowsList<TData = Awaited<ReturnType<typeof exportsRowsList>>, TError = unknown>(
 parcelExportId: string,
    params: undefined |  ExportsRowsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsRowsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsRowsList<TData = Awaited<ReturnType<typeof exportsRowsList>>, TError = unknown>(
 parcelExportId: string,
    params?: ExportsRowsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsRowsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsRowsList<TData = Awaited<ReturnType<typeof exportsRowsList>>, TError = unknown>(
 parcelExportId: string,
    params?: ExportsRowsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsRowsList<TData = Awaited<ReturnType<typeof exportsRowsList>>, TError = unknown>(
 parcelExportId: string,
    params?: ExportsRowsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRowsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsRowsListQueryOptions(parcelExportId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsRowsUpdate = (
    parcelExportId: string,
    id: string,
    parcelExportRowUpdate: ParcelExportRowUpdate,
 ) => {
      
      
      return customInstance<ParcelExportRow>(
      {url: `/api/property/exports/${parcelExportId}/rows/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportRowUpdate
    },
      );
    }
  


export const getExportsRowsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof exportsRowsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: ParcelExportRowUpdate}, TContext>, }
) => {
const mutationKey = ['exportsRowsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsRowsUpdate>>, {parcelExportId: string;id: string;data: ParcelExportRowUpdate}> = (props) => {
          const {parcelExportId,id,data} = props ?? {};

          return  exportsRowsUpdate(parcelExportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: ParcelExportRowUpdate}, TContext>}

    export type ExportsRowsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsRowsUpdate>>>
    export type ExportsRowsUpdateMutationBody = ParcelExportRowUpdate
    export type ExportsRowsUpdateMutationError = unknown

    export const useExportsRowsUpdate = <TData = Awaited<ReturnType<typeof exportsRowsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: ParcelExportRowUpdate}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;id: string;data: ParcelExportRowUpdate},
        TContext
      > => {

      const mutationOptions = getExportsRowsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsRowsPartialUpdate = (
    parcelExportId: string,
    id: string,
    patchedParcelExportRow: NonReadonly<PatchedParcelExportRow>,
 ) => {
      
      
      return customInstance<ParcelExportRow>(
      {url: `/api/property/exports/${parcelExportId}/rows/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedParcelExportRow
    },
      );
    }
  


export const getExportsRowsPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof exportsRowsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportRow>}, TContext>, }
) => {
const mutationKey = ['exportsRowsPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsRowsPartialUpdate>>, {parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportRow>}> = (props) => {
          const {parcelExportId,id,data} = props ?? {};

          return  exportsRowsPartialUpdate(parcelExportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportRow>}, TContext>}

    export type ExportsRowsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsRowsPartialUpdate>>>
    export type ExportsRowsPartialUpdateMutationBody = NonReadonly<PatchedParcelExportRow>
    export type ExportsRowsPartialUpdateMutationError = unknown

    export const useExportsRowsPartialUpdate = <TData = Awaited<ReturnType<typeof exportsRowsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportRow>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportRow>},
        TContext
      > => {

      const mutationOptions = getExportsRowsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsTagsList = (
    parcelExportId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportTag[]>(
      {url: `/api/property/exports/${parcelExportId}/tags/`, method: 'GET', signal
    },
      );
    }
  

export const getExportsTagsListQueryKey = (parcelExportId: string,) => {
    return [`/api/property/exports/${parcelExportId}/tags/`] as const;
    }

    
export const getExportsTagsListQueryOptions = <TData = Awaited<ReturnType<typeof exportsTagsList>>, TError = unknown>(parcelExportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsTagsListQueryKey(parcelExportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsTagsList>>> = ({ signal }) => exportsTagsList(parcelExportId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(parcelExportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsTagsListQueryResult = NonNullable<Awaited<ReturnType<typeof exportsTagsList>>>
export type ExportsTagsListQueryError = unknown


export function useExportsTagsList<TData = Awaited<ReturnType<typeof exportsTagsList>>, TError = unknown>(
 parcelExportId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsTagsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsTagsList<TData = Awaited<ReturnType<typeof exportsTagsList>>, TError = unknown>(
 parcelExportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsTagsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsTagsList<TData = Awaited<ReturnType<typeof exportsTagsList>>, TError = unknown>(
 parcelExportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsTagsList<TData = Awaited<ReturnType<typeof exportsTagsList>>, TError = unknown>(
 parcelExportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsTagsListQueryOptions(parcelExportId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsTagsCreate = (
    parcelExportId: string,
    parcelExportTag: NonReadonly<ParcelExportTag>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportTag>(
      {url: `/api/property/exports/${parcelExportId}/tags/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportTag, signal
    },
      );
    }
  


export const getExportsTagsCreateMutationOptions = <TData = Awaited<ReturnType<typeof exportsTagsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;data: NonReadonly<ParcelExportTag>}, TContext>, }
) => {
const mutationKey = ['exportsTagsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsTagsCreate>>, {parcelExportId: string;data: NonReadonly<ParcelExportTag>}> = (props) => {
          const {parcelExportId,data} = props ?? {};

          return  exportsTagsCreate(parcelExportId,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;data: NonReadonly<ParcelExportTag>}, TContext>}

    export type ExportsTagsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsTagsCreate>>>
    export type ExportsTagsCreateMutationBody = NonReadonly<ParcelExportTag>
    export type ExportsTagsCreateMutationError = unknown

    export const useExportsTagsCreate = <TData = Awaited<ReturnType<typeof exportsTagsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;data: NonReadonly<ParcelExportTag>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;data: NonReadonly<ParcelExportTag>},
        TContext
      > => {

      const mutationOptions = getExportsTagsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsTagsRetrieve = (
    parcelExportId: string,
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportTag>(
      {url: `/api/property/exports/${parcelExportId}/tags/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getExportsTagsRetrieveQueryKey = (parcelExportId: string,
    id: string,) => {
    return [`/api/property/exports/${parcelExportId}/tags/${id}/`] as const;
    }

    
export const getExportsTagsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError = unknown>(parcelExportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsTagsRetrieveQueryKey(parcelExportId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsTagsRetrieve>>> = ({ signal }) => exportsTagsRetrieve(parcelExportId,id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(parcelExportId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsTagsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof exportsTagsRetrieve>>>
export type ExportsTagsRetrieveQueryError = unknown


export function useExportsTagsRetrieve<TData = Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError = unknown>(
 parcelExportId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsTagsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsTagsRetrieve<TData = Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError = unknown>(
 parcelExportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsTagsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsTagsRetrieve<TData = Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError = unknown>(
 parcelExportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsTagsRetrieve<TData = Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError = unknown>(
 parcelExportId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsTagsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsTagsRetrieveQueryOptions(parcelExportId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsTagsUpdate = (
    parcelExportId: string,
    id: string,
    parcelExportTag: NonReadonly<ParcelExportTag>,
 ) => {
      
      
      return customInstance<ParcelExportTag>(
      {url: `/api/property/exports/${parcelExportId}/tags/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportTag
    },
      );
    }
  


export const getExportsTagsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof exportsTagsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<ParcelExportTag>}, TContext>, }
) => {
const mutationKey = ['exportsTagsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsTagsUpdate>>, {parcelExportId: string;id: string;data: NonReadonly<ParcelExportTag>}> = (props) => {
          const {parcelExportId,id,data} = props ?? {};

          return  exportsTagsUpdate(parcelExportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<ParcelExportTag>}, TContext>}

    export type ExportsTagsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsTagsUpdate>>>
    export type ExportsTagsUpdateMutationBody = NonReadonly<ParcelExportTag>
    export type ExportsTagsUpdateMutationError = unknown

    export const useExportsTagsUpdate = <TData = Awaited<ReturnType<typeof exportsTagsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<ParcelExportTag>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;id: string;data: NonReadonly<ParcelExportTag>},
        TContext
      > => {

      const mutationOptions = getExportsTagsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsTagsPartialUpdate = (
    parcelExportId: string,
    id: string,
    patchedParcelExportTag: NonReadonly<PatchedParcelExportTag>,
 ) => {
      
      
      return customInstance<ParcelExportTag>(
      {url: `/api/property/exports/${parcelExportId}/tags/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedParcelExportTag
    },
      );
    }
  


export const getExportsTagsPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof exportsTagsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportTag>}, TContext>, }
) => {
const mutationKey = ['exportsTagsPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsTagsPartialUpdate>>, {parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportTag>}> = (props) => {
          const {parcelExportId,id,data} = props ?? {};

          return  exportsTagsPartialUpdate(parcelExportId,id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportTag>}, TContext>}

    export type ExportsTagsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsTagsPartialUpdate>>>
    export type ExportsTagsPartialUpdateMutationBody = NonReadonly<PatchedParcelExportTag>
    export type ExportsTagsPartialUpdateMutationError = unknown

    export const useExportsTagsPartialUpdate = <TData = Awaited<ReturnType<typeof exportsTagsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportTag>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;id: string;data: NonReadonly<PatchedParcelExportTag>},
        TContext
      > => {

      const mutationOptions = getExportsTagsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsTagsDestroy = (
    parcelExportId: string,
    id: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/property/exports/${parcelExportId}/tags/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getExportsTagsDestroyMutationOptions = <TData = Awaited<ReturnType<typeof exportsTagsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string}, TContext>, }
) => {
const mutationKey = ['exportsTagsDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsTagsDestroy>>, {parcelExportId: string;id: string}> = (props) => {
          const {parcelExportId,id} = props ?? {};

          return  exportsTagsDestroy(parcelExportId,id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{parcelExportId: string;id: string}, TContext>}

    export type ExportsTagsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof exportsTagsDestroy>>>
    
    export type ExportsTagsDestroyMutationError = unknown

    export const useExportsTagsDestroy = <TData = Awaited<ReturnType<typeof exportsTagsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{parcelExportId: string;id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {parcelExportId: string;id: string},
        TContext
      > => {

      const mutationOptions = getExportsTagsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportReadOnly>(
      {url: `/api/property/exports/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getExportsRetrieveQueryKey = (id: string,) => {
    return [`/api/property/exports/${id}/`] as const;
    }

    
export const getExportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof exportsRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsRetrieve>>> = ({ signal }) => exportsRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof exportsRetrieve>>>
export type ExportsRetrieveQueryError = unknown


export function useExportsRetrieve<TData = Awaited<ReturnType<typeof exportsRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsRetrieve<TData = Awaited<ReturnType<typeof exportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsRetrieve<TData = Awaited<ReturnType<typeof exportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsRetrieve<TData = Awaited<ReturnType<typeof exportsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsUpdate = (
    id: string,
    parcelExportUpdate: ParcelExportUpdate,
 ) => {
      
      
      return customInstance<ParcelExportReadOnly>(
      {url: `/api/property/exports/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportUpdate
    },
      );
    }
  


export const getExportsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof exportsUpdate>>, TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: ParcelExportUpdate}, TContext>, }
) => {
const mutationKey = ['exportsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsUpdate>>, {id: string;data: ParcelExportUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  exportsUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: ParcelExportUpdate}, TContext>}

    export type ExportsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsUpdate>>>
    export type ExportsUpdateMutationBody = ParcelExportUpdate
    export type ExportsUpdateMutationError = APIError

    export const useExportsUpdate = <TData = Awaited<ReturnType<typeof exportsUpdate>>, TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: ParcelExportUpdate}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: ParcelExportUpdate},
        TContext
      > => {

      const mutationOptions = getExportsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsCreateLegacyScrubCreate = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportReadOnly>(
      {url: `/api/property/exports/${id}/create_legacy_scrub/`, method: 'POST', signal
    },
      );
    }
  


export const getExportsCreateLegacyScrubCreateMutationOptions = <TData = Awaited<ReturnType<typeof exportsCreateLegacyScrubCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
) => {
const mutationKey = ['exportsCreateLegacyScrubCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsCreateLegacyScrubCreate>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  exportsCreateLegacyScrubCreate(id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type ExportsCreateLegacyScrubCreateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsCreateLegacyScrubCreate>>>
    
    export type ExportsCreateLegacyScrubCreateMutationError = unknown

    export const useExportsCreateLegacyScrubCreate = <TData = Awaited<ReturnType<typeof exportsCreateLegacyScrubCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getExportsCreateLegacyScrubCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Deprecated: This view has been made redundant by ParcelExportRowViewSet.
 */
export const exportsParcelsList = (
    id: string,
    params?: ExportsParcelsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedParcelExportRowList>(
      {url: `/api/property/exports/${id}/parcels/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExportsParcelsListQueryKey = (id: string,
    params?: ExportsParcelsListParams,) => {
    return [`/api/property/exports/${id}/parcels/`, ...(params ? [params]: [])] as const;
    }

    
export const getExportsParcelsListQueryOptions = <TData = Awaited<ReturnType<typeof exportsParcelsList>>, TError = unknown>(id: string,
    params?: ExportsParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportsParcelsListQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsParcelsList>>> = ({ signal }) => exportsParcelsList(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ExportsParcelsListQueryResult = NonNullable<Awaited<ReturnType<typeof exportsParcelsList>>>
export type ExportsParcelsListQueryError = unknown


export function useExportsParcelsList<TData = Awaited<ReturnType<typeof exportsParcelsList>>, TError = unknown>(
 id: string,
    params: undefined |  ExportsParcelsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsParcelsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsParcelsList<TData = Awaited<ReturnType<typeof exportsParcelsList>>, TError = unknown>(
 id: string,
    params?: ExportsParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportsParcelsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useExportsParcelsList<TData = Awaited<ReturnType<typeof exportsParcelsList>>, TError = unknown>(
 id: string,
    params?: ExportsParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useExportsParcelsList<TData = Awaited<ReturnType<typeof exportsParcelsList>>, TError = unknown>(
 id: string,
    params?: ExportsParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportsParcelsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportsParcelsListQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exportsCalculatePriceCreate = (
    parcelExportCreate: ParcelExportCreate,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportPriceBreakdown>(
      {url: `/api/property/exports/calculate_price/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportCreate, signal
    },
      );
    }
  


export const getExportsCalculatePriceCreateMutationOptions = <TData = Awaited<ReturnType<typeof exportsCalculatePriceCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>, }
) => {
const mutationKey = ['exportsCalculatePriceCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsCalculatePriceCreate>>, {data: ParcelExportCreate}> = (props) => {
          const {data} = props ?? {};

          return  exportsCalculatePriceCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>}

    export type ExportsCalculatePriceCreateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsCalculatePriceCreate>>>
    export type ExportsCalculatePriceCreateMutationBody = ParcelExportCreate
    export type ExportsCalculatePriceCreateMutationError = unknown

    export const useExportsCalculatePriceCreate = <TData = Awaited<ReturnType<typeof exportsCalculatePriceCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {data: ParcelExportCreate},
        TContext
      > => {

      const mutationOptions = getExportsCalculatePriceCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportsPurchaseCreate = (
    parcelExportCreate: ParcelExportCreate,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelExportReadOnly>(
      {url: `/api/property/exports/purchase/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: parcelExportCreate, signal
    },
      );
    }
  


export const getExportsPurchaseCreateMutationOptions = <TData = Awaited<ReturnType<typeof exportsPurchaseCreate>>, TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>, }
) => {
const mutationKey = ['exportsPurchaseCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportsPurchaseCreate>>, {data: ParcelExportCreate}> = (props) => {
          const {data} = props ?? {};

          return  exportsPurchaseCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>}

    export type ExportsPurchaseCreateMutationResult = NonNullable<Awaited<ReturnType<typeof exportsPurchaseCreate>>>
    export type ExportsPurchaseCreateMutationBody = ParcelExportCreate
    export type ExportsPurchaseCreateMutationError = APIError

    export const useExportsPurchaseCreate = <TData = Awaited<ReturnType<typeof exportsPurchaseCreate>>, TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ParcelExportCreate}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {data: ParcelExportCreate},
        TContext
      > => {

      const mutationOptions = getExportsPurchaseCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const fipsLookupRetrieve = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/api/property/fips_lookup/`, method: 'GET', signal
    },
      );
    }
  

export const getFipsLookupRetrieveQueryKey = () => {
    return [`/api/property/fips_lookup/`] as const;
    }

    
export const getFipsLookupRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFipsLookupRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fipsLookupRetrieve>>> = ({ signal }) => fipsLookupRetrieve(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type FipsLookupRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof fipsLookupRetrieve>>>
export type FipsLookupRetrieveQueryError = unknown


export function useFipsLookupRetrieve<TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof fipsLookupRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useFipsLookupRetrieve<TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof fipsLookupRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useFipsLookupRetrieve<TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useFipsLookupRetrieve<TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getFipsLookupRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 
Must include at least one of the following filters:

* county
* geom_intersects
* owner_parcel_id

 */
export const parcelsList = (
    params?: ParcelsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedFullParcelList>(
      {url: `/api/property/parcels/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getParcelsListQueryKey = (params?: ParcelsListParams,) => {
    return [`/api/property/parcels/`, ...(params ? [params]: [])] as const;
    }

    
export const getParcelsListQueryOptions = <TData = Awaited<ReturnType<typeof parcelsList>>, TError = unknown>(params?: ParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsList>>> = ({ signal }) => parcelsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsListQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsList>>>
export type ParcelsListQueryError = unknown


export function useParcelsList<TData = Awaited<ReturnType<typeof parcelsList>>, TError = unknown>(
 params: undefined |  ParcelsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsList<TData = Awaited<ReturnType<typeof parcelsList>>, TError = unknown>(
 params?: ParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsList<TData = Awaited<ReturnType<typeof parcelsList>>, TError = unknown>(
 params?: ParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsList<TData = Awaited<ReturnType<typeof parcelsList>>, TError = unknown>(
 params?: ParcelsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const parcelsRetrieve = (
    propertyID: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<FullParcel>(
      {url: `/api/property/parcels/${propertyID}/`, method: 'GET', signal
    },
      );
    }
  

export const getParcelsRetrieveQueryKey = (propertyID: number,) => {
    return [`/api/property/parcels/${propertyID}/`] as const;
    }

    
export const getParcelsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof parcelsRetrieve>>, TError = unknown>(propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsRetrieveQueryKey(propertyID);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsRetrieve>>> = ({ signal }) => parcelsRetrieve(propertyID, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(propertyID), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsRetrieve>>>
export type ParcelsRetrieveQueryError = unknown


export function useParcelsRetrieve<TData = Awaited<ReturnType<typeof parcelsRetrieve>>, TError = unknown>(
 propertyID: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsRetrieve<TData = Awaited<ReturnType<typeof parcelsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsRetrieve<TData = Awaited<ReturnType<typeof parcelsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsRetrieve<TData = Awaited<ReturnType<typeof parcelsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsRetrieveQueryOptions(propertyID,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const parcelsInsightsRetrieve = (
    propertyID: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<InsightRulesResponse>(
      {url: `/api/property/parcels/${propertyID}/insights/`, method: 'GET', signal
    },
      );
    }
  

export const getParcelsInsightsRetrieveQueryKey = (propertyID: number,) => {
    return [`/api/property/parcels/${propertyID}/insights/`] as const;
    }

    
export const getParcelsInsightsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError = unknown>(propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsInsightsRetrieveQueryKey(propertyID);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>> = ({ signal }) => parcelsInsightsRetrieve(propertyID, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(propertyID), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsInsightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>>
export type ParcelsInsightsRetrieveQueryError = unknown


export function useParcelsInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsInsightsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsInsightsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsInsightsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsInsightsRetrieveQueryOptions(propertyID,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const parcelsMarketInsightsRetrieve = (
    propertyID: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<InsightRulesResponse>(
      {url: `/api/property/parcels/${propertyID}/market_insights/`, method: 'GET', signal
    },
      );
    }
  

export const getParcelsMarketInsightsRetrieveQueryKey = (propertyID: number,) => {
    return [`/api/property/parcels/${propertyID}/market_insights/`] as const;
    }

    
export const getParcelsMarketInsightsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError = unknown>(propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsMarketInsightsRetrieveQueryKey(propertyID);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>> = ({ signal }) => parcelsMarketInsightsRetrieve(propertyID, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(propertyID), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsMarketInsightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>>
export type ParcelsMarketInsightsRetrieveQueryError = unknown


export function useParcelsMarketInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsMarketInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsMarketInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsMarketInsightsRetrieve<TData = Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsMarketInsightsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsMarketInsightsRetrieveQueryOptions(propertyID,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const parcelsOwnerPortfolioRetrieve = (
    propertyID: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<OwnerPortfolioResponse>(
      {url: `/api/property/parcels/${propertyID}/owner_portfolio/`, method: 'GET', signal
    },
      );
    }
  

export const getParcelsOwnerPortfolioRetrieveQueryKey = (propertyID: number,) => {
    return [`/api/property/parcels/${propertyID}/owner_portfolio/`] as const;
    }

    
export const getParcelsOwnerPortfolioRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError = unknown>(propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsOwnerPortfolioRetrieveQueryKey(propertyID);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>> = ({ signal }) => parcelsOwnerPortfolioRetrieve(propertyID, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(propertyID), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsOwnerPortfolioRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>>
export type ParcelsOwnerPortfolioRetrieveQueryError = unknown


export function useParcelsOwnerPortfolioRetrieve<TData = Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError = unknown>(
 propertyID: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsOwnerPortfolioRetrieve<TData = Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsOwnerPortfolioRetrieve<TData = Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsOwnerPortfolioRetrieve<TData = Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError = unknown>(
 propertyID: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerPortfolioRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsOwnerPortfolioRetrieveQueryOptions(propertyID,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const parcelsOwnerSearchList = (
    params?: ParcelsOwnerSearchListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<OwnerSearchResponse[]>(
      {url: `/api/property/parcels/owner_search/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getParcelsOwnerSearchListQueryKey = (params?: ParcelsOwnerSearchListParams,) => {
    return [`/api/property/parcels/owner_search/`, ...(params ? [params]: [])] as const;
    }

    
export const getParcelsOwnerSearchListQueryOptions = <TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError = unknown>(params?: ParcelsOwnerSearchListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsOwnerSearchListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsOwnerSearchList>>> = ({ signal }) => parcelsOwnerSearchList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsOwnerSearchListQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsOwnerSearchList>>>
export type ParcelsOwnerSearchListQueryError = unknown


export function useParcelsOwnerSearchList<TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError = unknown>(
 params: undefined |  ParcelsOwnerSearchListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsOwnerSearchList<TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError = unknown>(
 params?: ParcelsOwnerSearchListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsOwnerSearchList<TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError = unknown>(
 params?: ParcelsOwnerSearchListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsOwnerSearchList<TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError = unknown>(
 params?: ParcelsOwnerSearchListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsOwnerSearchList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsOwnerSearchListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the least amount of information necessary to render points on
the map for performance.
 */
export const parcelsSearchRetrieve = (
    params?: ParcelsSearchRetrieveParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelSearchResponse>(
      {url: `/api/property/parcels/search/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getParcelsSearchRetrieveQueryKey = (params?: ParcelsSearchRetrieveParams,) => {
    return [`/api/property/parcels/search/`, ...(params ? [params]: [])] as const;
    }

    
export const getParcelsSearchRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError = unknown>(params?: ParcelsSearchRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getParcelsSearchRetrieveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsSearchRetrieve>>> = ({ signal }) => parcelsSearchRetrieve(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ParcelsSearchRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof parcelsSearchRetrieve>>>
export type ParcelsSearchRetrieveQueryError = unknown


export function useParcelsSearchRetrieve<TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError = unknown>(
 params: undefined |  ParcelsSearchRetrieveParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsSearchRetrieve<TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError = unknown>(
 params?: ParcelsSearchRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useParcelsSearchRetrieve<TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError = unknown>(
 params?: ParcelsSearchRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useParcelsSearchRetrieve<TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError = unknown>(
 params?: ParcelsSearchRetrieveParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof parcelsSearchRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getParcelsSearchRetrieveQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const savedlistsList = (
    params?: SavedlistsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaginatedParcelSavedListList>(
      {url: `/api/property/savedlists/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSavedlistsListQueryKey = (params?: SavedlistsListParams,) => {
    return [`/api/property/savedlists/`, ...(params ? [params]: [])] as const;
    }

    
export const getSavedlistsListQueryOptions = <TData = Awaited<ReturnType<typeof savedlistsList>>, TError = unknown>(params?: SavedlistsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSavedlistsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof savedlistsList>>> = ({ signal }) => savedlistsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type SavedlistsListQueryResult = NonNullable<Awaited<ReturnType<typeof savedlistsList>>>
export type SavedlistsListQueryError = unknown


export function useSavedlistsList<TData = Awaited<ReturnType<typeof savedlistsList>>, TError = unknown>(
 params: undefined |  SavedlistsListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof savedlistsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useSavedlistsList<TData = Awaited<ReturnType<typeof savedlistsList>>, TError = unknown>(
 params?: SavedlistsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof savedlistsList>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useSavedlistsList<TData = Awaited<ReturnType<typeof savedlistsList>>, TError = unknown>(
 params?: SavedlistsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useSavedlistsList<TData = Awaited<ReturnType<typeof savedlistsList>>, TError = unknown>(
 params?: SavedlistsListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getSavedlistsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const savedlistsCreate = (
    parcelSavedList: NonReadonly<ParcelSavedList>,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelSavedList>(
      {url: `/api/property/savedlists/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: parcelSavedList, signal
    },
      );
    }
  


export const getSavedlistsCreateMutationOptions = <TData = Awaited<ReturnType<typeof savedlistsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<ParcelSavedList>}, TContext>, }
) => {
const mutationKey = ['savedlistsCreate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savedlistsCreate>>, {data: NonReadonly<ParcelSavedList>}> = (props) => {
          const {data} = props ?? {};

          return  savedlistsCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: NonReadonly<ParcelSavedList>}, TContext>}

    export type SavedlistsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof savedlistsCreate>>>
    export type SavedlistsCreateMutationBody = NonReadonly<ParcelSavedList>
    export type SavedlistsCreateMutationError = unknown

    export const useSavedlistsCreate = <TData = Awaited<ReturnType<typeof savedlistsCreate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: NonReadonly<ParcelSavedList>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {data: NonReadonly<ParcelSavedList>},
        TContext
      > => {

      const mutationOptions = getSavedlistsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savedlistsRetrieve = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ParcelSavedList>(
      {url: `/api/property/savedlists/${id}/`, method: 'GET', signal
    },
      );
    }
  

export const getSavedlistsRetrieveQueryKey = (id: string,) => {
    return [`/api/property/savedlists/${id}/`] as const;
    }

    
export const getSavedlistsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof savedlistsRetrieve>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSavedlistsRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof savedlistsRetrieve>>> = ({ signal }) => savedlistsRetrieve(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type SavedlistsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof savedlistsRetrieve>>>
export type SavedlistsRetrieveQueryError = unknown


export function useSavedlistsRetrieve<TData = Awaited<ReturnType<typeof savedlistsRetrieve>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof savedlistsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useSavedlistsRetrieve<TData = Awaited<ReturnType<typeof savedlistsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof savedlistsRetrieve>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useSavedlistsRetrieve<TData = Awaited<ReturnType<typeof savedlistsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useSavedlistsRetrieve<TData = Awaited<ReturnType<typeof savedlistsRetrieve>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof savedlistsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getSavedlistsRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const savedlistsUpdate = (
    id: string,
    parcelSavedList: NonReadonly<ParcelSavedList>,
 ) => {
      
      
      return customInstance<ParcelSavedList>(
      {url: `/api/property/savedlists/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: parcelSavedList
    },
      );
    }
  


export const getSavedlistsUpdateMutationOptions = <TData = Awaited<ReturnType<typeof savedlistsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<ParcelSavedList>}, TContext>, }
) => {
const mutationKey = ['savedlistsUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savedlistsUpdate>>, {id: string;data: NonReadonly<ParcelSavedList>}> = (props) => {
          const {id,data} = props ?? {};

          return  savedlistsUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<ParcelSavedList>}, TContext>}

    export type SavedlistsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof savedlistsUpdate>>>
    export type SavedlistsUpdateMutationBody = NonReadonly<ParcelSavedList>
    export type SavedlistsUpdateMutationError = unknown

    export const useSavedlistsUpdate = <TData = Awaited<ReturnType<typeof savedlistsUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<ParcelSavedList>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<ParcelSavedList>},
        TContext
      > => {

      const mutationOptions = getSavedlistsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savedlistsPartialUpdate = (
    id: string,
    patchedParcelSavedList: NonReadonly<PatchedParcelSavedList>,
 ) => {
      
      
      return customInstance<ParcelSavedList>(
      {url: `/api/property/savedlists/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedParcelSavedList
    },
      );
    }
  


export const getSavedlistsPartialUpdateMutationOptions = <TData = Awaited<ReturnType<typeof savedlistsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedParcelSavedList>}, TContext>, }
) => {
const mutationKey = ['savedlistsPartialUpdate'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savedlistsPartialUpdate>>, {id: string;data: NonReadonly<PatchedParcelSavedList>}> = (props) => {
          const {id,data} = props ?? {};

          return  savedlistsPartialUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedParcelSavedList>}, TContext>}

    export type SavedlistsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof savedlistsPartialUpdate>>>
    export type SavedlistsPartialUpdateMutationBody = NonReadonly<PatchedParcelSavedList>
    export type SavedlistsPartialUpdateMutationError = unknown

    export const useSavedlistsPartialUpdate = <TData = Awaited<ReturnType<typeof savedlistsPartialUpdate>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: NonReadonly<PatchedParcelSavedList>}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string;data: NonReadonly<PatchedParcelSavedList>},
        TContext
      > => {

      const mutationOptions = getSavedlistsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savedlistsDestroy = (
    id: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/api/property/savedlists/${id}/`, method: 'DELETE'
    },
      );
    }
  


export const getSavedlistsDestroyMutationOptions = <TData = Awaited<ReturnType<typeof savedlistsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
) => {
const mutationKey = ['savedlistsDestroy'];
const {mutation: mutationOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savedlistsDestroy>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  savedlistsDestroy(id,)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type SavedlistsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof savedlistsDestroy>>>
    
    export type SavedlistsDestroyMutationError = unknown

    export const useSavedlistsDestroy = <TData = Awaited<ReturnType<typeof savedlistsDestroy>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, }
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSavedlistsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
