import { useNavigate, useParams } from "react-router-dom";
import {
    useReportsCreate,
    useReportsDestroy,
    useReportsList,
} from "@src/orval/gen/api.js";
import { useEffect, useMemo, useState } from "react";
import { CompingSection } from "@src/pages/comping/comping_section";
import { type LandColumnDef, Table } from "@src/land_ui/table/table";
import { type CompingReport } from "@src/orval/gen/model";
import {
    getDecimalValue,
    getOwnerFullName,
} from "@src/pages/parcel_viewer/controls/detail";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { formatDatetime } from "@src/functions";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteModal } from "@src/pages/comping/utils";
import { Typography } from "@src/land_ui/typography/typography";

export default function Comping() {
    const { id: parcelIdStr } = useParams();

    if (!parcelIdStr) {
        return <CompingReportList />;
    }
    const parcelId = parseInt(parcelIdStr, 10);

    return <CompingTool parcelId={parcelId} />;
}

function CompingReportList() {
    const { data: compingReports, isLoading } = useReportsList(null, {
        query: {
            queryKey: ["reportsList"],
        },
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const queryClient = useQueryClient();
    const {
        mutate: deleteCompingReport,
        isPending,
        error,
    } = useReportsDestroy({
        mutation: {
            onSettled: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ["reportsList"],
                });
                setDeleteParams(null);
            },
        },
    });
    const [deleteParams, setDeleteParams] = useState(null);
    const tableColumns: LandColumnDef<CompingReport>[] = useMemo(
        () => [
            {
                header: "State",
                accessor: (row) => row.parcel?.SitusState,
                sortBy: "alphanumeric",
            },
            {
                header: "APN",
                accessor: (row) => row.parcel?.APN,
                sortBy: "alphanumeric",
            },
            {
                header: "Owner",
                accessor: (row) => getOwnerFullName(row.parcel),
                cell: (row) => (
                    <div className="lui-capitalize">{getOwnerFullName(row.parcel)}</div>
                ),
                sortBy: "alphanumeric",
            },
            {
                header: "Acreage",
                accessor: (row) =>
                    getDecimalValue(
                        row.acreage_override || row.parcel?.LotSizeAcres,
                        3,
                    ),
                sortBy: "alphanumeric",
            },
            {
                header: "Created",
                accessor: "created",
                sortBy: "datetime",
                cell: (rowData) => <>{formatDatetime(rowData.created)}</>,
            },
            {
                header: "Actions",
                cell: (rowData) => (
                    <div className="lui-flex lui-flex-row lui-gap-2">
                        <ButtonIcon
                            icon="Trash"
                            color="red-700"
                            tooltipLabel="Delete Report"
                            onClick={() => {
                                setDeleteParams({ id: rowData.id });
                                setDeleteModalOpen(true);
                            }}
                        />
                        <ButtonIcon
                            icon="Pencil"
                            tooltipLabel="Edit Report"
                            href={`/home/comping/report/${rowData.id}`}
                        />
                    </div>
                ),
            },
        ],
        [setDeleteParams, setDeleteModalOpen],
    );

    return (
        <CompingSection>
            {!isLoading && compingReports && (
                <Table
                    title={"Comps"}
                    description={`${compingReports.count} results`}
                    columns={tableColumns}
                    data={compingReports.results}
                />
            )}
            <DeleteModal
                isOpen={deleteModalOpen}
                setIsOpen={setDeleteModalOpen}
                deleteParams={deleteParams}
                setDeleteParams={setDeleteParams}
                mutate={deleteCompingReport}
                error={error}
                isPending={isPending}
                message="Are you sure you want to delete this report?"
            />
        </CompingSection>
    );
}

function CompingTool({ parcelId }: { parcelId: number }) {
    const navigate = useNavigate();
    const { isSuccess, data: compingReports } = useReportsList({
        parcel_id: parcelId,
    });
    const { mutate: createCompingReport } = useReportsCreate();

    useEffect(() => {
        // If a report does not exist, create it. Otherwise, redirect it
        if (isSuccess) {
            if (compingReports.results.length === 0) {
                createCompingReport(
                    {
                        data: {
                            parcel_id: parcelId,
                        },
                    },
                    {
                        onSuccess: (reportCreateData) => {
                            navigate(`/home/comping/report/${reportCreateData.id}`);
                        },
                    },
                );
            } else {
                const report = compingReports.results[0];
                navigate(`/home/comping/report/${report.id}`);
            }
        }
    }, [isSuccess, compingReports, navigate, createCompingReport, parcelId]);

    //TODO: Replace with loading screen instead of rendering placeholder
    return <CompingReportLoading />;
}

function CompingReportLoading() {
    return (
        <CompingSection>
            <div className="lui-flex lui-flex-col lui-gap-10">
                <Typography size="4xl" weight="bold">
                    Comp Report Loading
                </Typography>
                <img
                    src="/static/images/processing.svg"
                    alt="Loading"
                    className="lui-w-1/2"
                />
            </div>
        </CompingSection>
    );
}
