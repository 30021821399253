import { useQuery } from "@tanstack/react-query";
import { type AxiosResponse } from "axios";
import { clearCurrentToken } from "functions/ajax";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { AXIOS_INSTANCE } from "@src/orval/axios-instance";
import { useRupt } from "./useRupt";

export interface LandUser {
    id: string;
    email: string;
    name: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    company_id: string;
    is_staff: boolean;
    is_superuser: boolean;
    is_friend: boolean;
    lia_member: boolean;
    beta: boolean;
    onboarding_survey_completed: boolean;
    onboarding_eula_completed: boolean; // Deprecated
    onboarding_stripe_completed: boolean;
    has_agreed_to_eula: boolean;
    has_paid_subscription: boolean;
    has_unlimited_data: boolean;
    stripe_customer_id: string; // Deprecated
    stripe_subscription_period_end: string; // Deprecated
    stripe_subscription_canceled_at: string; // Deprecated
    data_point_preferences: string[];
    created_at: string;
    last_login: string;
    permissions: string[];
}

interface useCurrentUserProps {
    loadUser?: boolean;
}
export function useCurrentUser({ loadUser = true }: useCurrentUserProps = {}) {
    const { data, isLoading, refetch } = useQuery<AxiosResponse<LandUser>>({
        queryKey: ["currentUser"],
        queryFn: async () => {
            return AXIOS_INSTANCE.get("/user/user/");
        },
        enabled: loadUser,
    });
    const posthog = usePostHog();
    const { detachRupt } = useRupt();

    // Attach posthog user
    useEffect(() => {
        if (Boolean(data?.data)) {
            posthog?.identify(data.data?.id, {
                email: data.data?.email,
                name: data.data?.name,
            });
        }
    }, [data, posthog]);

    return {
        user: data?.data,
        isLoading,
        getUser: async () => {
            try {
                const user = (await refetch()).data?.data;
                return user;
            } catch (e) {
                return null;
            }
        },

        logout: async () => {
            clearCurrentToken();
            detachRupt(data?.data);
            window.location.href = "/";
        },
    };
}
