import type { Location } from "react-router-dom";
import type { FullParcel } from "@src/orval/gen/model";
import type { Region, RegionStatistics } from "@src/pages/parcel_viewer/types";
import {
    type AcreRangeKey,
    ACRE_RANGES,
    DATA_PLATFORM_RANGE_PAIRS,
} from "@src/constants";
import { getDecimalValue } from "@src/pages/parcel_viewer/controls/detail";

export const PLACEHOLDER_LABEL = "——";

// TODO: remove (not used)
export const getKeyFromHash = (location: Location, key: string) => {
    const params = location.hash?.substring(1).split("&");
    for (const param of params) {
        const [k, v] = param.split("=");
        if (k === key) {
            return v;
        }
    }
};

// TODO: remove (not used)
export const setKeyInHash = (location: Location, key: string, value: string) => {
    const params = location.hash?.substring(1).split("&");
    const newParams = [];
    let found = false;
    for (const param of params) {
        const [k] = param.split("=");
        if (k === key) {
            newParams.push(`${k}=${value}`);
            found = true;
        } else {
            newParams.push(param);
        }
    }
    if (!found) {
        newParams.push(`${key}=${value}`);
    }
    return `#${newParams.join("&")}`;
};

// TODO: move to src/functions/format.tsx with other formatters?
export function formatCurrency(value: number) {
    if (value === null || value === undefined) {
        return PLACEHOLDER_LABEL;
    }
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);
}

interface ParcelMetricsLabels {
    ACREAGE_RANGE: string;
    ACTIVE: number;
    PENDING: number;
    STR_1_MONTH: number;
    STR_3_MONTHS: number;
    STR_6_MONTHS: number;
    STR_1_YEAR: number;
    SOLD_1_MONTH: number;
    SOLD_3_MONTHS: number;
    SOLD_6_MONTHS: number;
    SOLD_1_YEAR: number;
    MEDIAN_SOLD_PPA: number;
    GINI_6_MONTHS: number;
    GINI_1_YEAR: number;
}

const DEFAULT_METRICS_LABELS: ParcelMetricsLabels = {
    ACREAGE_RANGE: undefined,
    ACTIVE: undefined,
    PENDING: undefined,
    STR_1_MONTH: undefined,
    STR_3_MONTHS: undefined,
    STR_6_MONTHS: undefined,
    STR_1_YEAR: undefined,
    SOLD_1_MONTH: undefined,
    SOLD_3_MONTHS: undefined,
    SOLD_6_MONTHS: undefined,
    SOLD_1_YEAR: undefined,
    MEDIAN_SOLD_PPA: undefined,
    GINI_6_MONTHS: undefined,
    GINI_1_YEAR: undefined,
};

export function getTargetRange(parcel: FullParcel) {
    const lotSize = getDecimalValue(parcel?.LotSizeAcres, 3);
    return DATA_PLATFORM_RANGE_PAIRS.find(
        ([minAcre, maxAcre, _]) => lotSize >= minAcre && lotSize < maxAcre,
    );
}

export function getParcelMetrics(
    parcel: FullParcel,
    county: Region,
): ParcelMetricsLabels {
    if (!parcel || !county) {
        return DEFAULT_METRICS_LABELS;
    }

    const range = getTargetRange(parcel);

    if (!range) {
        return DEFAULT_METRICS_LABELS;
    }

    const [, , acreageKey] = range;
    return getMetricsRange(county.stats, acreageKey);
}

export function getMetricsRange(stats: RegionStatistics[], acreageKey: AcreRangeKey) {
    const metrics = stats.find((stat: RegionStatistics) => stat.type === acreageKey);

    return {
        ACREAGE_RANGE: ACRE_RANGES[acreageKey],
        ACTIVE: metrics?.data["Active"],
        PENDING: metrics?.data["Pending"],
        STR_1_MONTH: metrics?.data["1mo STR"],
        STR_3_MONTHS: metrics?.data["3mo STR"],
        STR_6_MONTHS: metrics?.data["6mo STR"],
        STR_1_YEAR: metrics?.data["1yr STR"],
        SOLD_1_MONTH: metrics?.data["Sold: 1mo"],
        SOLD_3_MONTHS: metrics?.data["Sold: 3mo"],
        SOLD_6_MONTHS: metrics?.data["Sold: 6mo"],
        SOLD_1_YEAR: metrics?.data["Sold: 1yr"],
        MEDIAN_SOLD_PPA: metrics?.data["Median Sold PPA"],
        GINI_6_MONTHS: metrics?.data["Gini 6mo"],
        GINI_1_YEAR: metrics?.data["Gini 1yr"],
    };
}
