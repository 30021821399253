import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { type Location, useLocation } from "react-router-dom";
import { useHover } from "usehooks-ts";
import { Badge } from "@src/land_ui/badge/badge";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Popover } from "@src/land_ui/popover/popover";
import { Typography } from "@src/land_ui/typography/typography";
import { useCurrentUser } from "@src/hooks/useUser";

interface MenuItemProps {
    title: string;
    imageUrl: string;
    href: string;
    info: ReactNode;
    isNew?: boolean;
    isBeta?: boolean;
    isStaffOnly?: boolean;
    featureFlag?: string;
    tag?: string;
}
interface MenuSectionProps {
    sectionTitle: string;
    links: MenuItemProps[];
}

const AppMenuOptions: MenuSectionProps[] = [
    {
        sectionTitle: "Marketing",
        links: [
            {
                href: "/data",
                title: "Data Platform",
                imageUrl: "/static/images/menu/svg/data_platform2.svg",
                isNew: true,
                info: "Highest Quality Data Integrated with AI Scrubbing, Skip Tracing & Mapping Tools. Perfect for Streamlining Your Data Pulls & Due Diligence.",
            },
            {
                href: "/home/market_research",
                title: "Market Research",
                imageUrl: "/static/images/menu/svg/market_selection.svg",
                info: "Lucrative Market Data at Your Finger Tips Customizable to Your Own Strategy. Scrubbed to Include Only Land Data & Remove All Off-Market Comps.",
            },
            {
                href: "/home/my_markets",
                title: "My Markets",
                imageUrl: "/static/images/menu/svg/my_markets.svg",
                info: "View All of Your Chosen Markets Without The Noise. Analyze Pricing Metrics, or Prioritize Where You'll Target First.",
            },
            {
                href: "/home/saved_filters",
                title: "Saved Filters",
                imageUrl: "/static/images/menu/svg/saved_filters.svg",
                info: "Bookmark Your Favorite Methods to Find Your Preferred Markets in Any State, at the County or Zip Level.",
            },
            {
                href: "/home/pricing_tool",
                title: "Pricing Tool",
                imageUrl: "/static/images/menu/svg/pricing_tool.svg",
                info: "Intuitively and accurately price your marketing campaigns in under 5 minutes.",
                isStaffOnly: true,
            },
            {
                href: "/home/campaigns",
                title: "Campaigns",
                imageUrl: "/static/images/menu/svg/campaigns.svg",
                info: "Organizational Kanban Board to Track Marketing Campaigns & Store Critical Information about Your Markets.",
            },
        ],
    },
    {
        sectionTitle: "Acquisition",
        links: [
            {
                href: "/home/land_scrubbing",
                title: "Scrubbing",
                imageUrl: "/static/images/menu/svg/scrubbing.svg",
                info: "Leverage AI to Quickly Remove Properties that Don’t Fit Your Investing Strategy. Also Available when Pulling Data in the Data Platform.",
            },
            {
                href: "/home/skip_tracing",
                title: "Skip Tracing",
                imageUrl: "/static/images/menu/svg/skip_trace.svg",
                info: "Uncover Owner Contact Information, Including Owner & Relative Phone Numbers, Emails & More. This Data Can Be Used for SMS, Cold-Calling, or Neighbor Reach Out.",
            },
            {
                href: "/home/comping",
                title: "Comping Tool",
                imageUrl: "/static/images/menu/svg/comping_3.svg",
                info: "Comp a Property with Ease by Hand-Selecting Your Preferred Comps & Letting Land Insights Determine the Approximate Market Value.",
            },
        ],
    },
    {
        sectionTitle: "Disposition",
        links: [
            {
                href: "/home/realtor_report",
                title: "Realtor Report",
                imageUrl: "/static/images/menu/svg/realtor_report.svg",
                info: "Broker & Agent Score Cards at the County Level to Help You Find the Best Partner for Your New Deal. Double Close Agents also Available in Select Markets.",
            },
            {
                href: "/home/photo_editor",
                title: "Listing Photo Editor",
                imageUrl: "/static/images/menu/svg/listing_photo_editor.svg",
                info: "Make Your Listings Stand Out By Customizing Your Photos to Pop with Color or Include Approximate Property Boundaries & Captions.",
            },
        ],
    },
    {
        sectionTitle: "Others",
        links: [
            {
                href: "/home/feature_idea",
                title: "Request a Feature",
                imageUrl: "/static/images/menu/svg/request_feature.svg",
                info: "Have a Feature Idea That Would Add Massive Value to Your Business? Submit it to Our Team!",
            },
            {
                href: "https://sso.teachable.com/secure/1967990/identity/login/otp",
                title: "Land Insights Course",
                imageUrl: "/static/images/menu/svg/course.svg",
                info: "Learn How to Take Full Advantage of Land Insights and Maximize Your Competitive-Edge.",
            },
        ],
    },
];

export function AppMenu() {
    const location = useLocation();
    const [currentSelection, setCurrentSelection] = useState<MenuItemProps>(
        getActiveMenu(location),
    );
    const { user } = useCurrentUser();

    return (
        <div className="lui-flex lui-items-center lui-gap-2">
            <Popover
                trigger={
                    <Button variant="base">
                        <span className="lui-flex lui-items-center lui-gap-2">
                            <Icon name="DotGrid" />
                            <Typography size="md" weight="medium">
                                {getActiveMenu(location)?.title}
                            </Typography>
                        </span>
                    </Button>
                }
                placement="bottom-end"
            >
                <div className="lui-w-full lui-shadow-lg lui-px-4 lui-py-5 lui-bg-white lui-rounded-3xl">
                    <div className="lui-w-full lui-flex lui-gap-2">
                        <div className="lui-flex lui-flex-col lui-gap-6">
                            {AppMenuOptions.map((menu) => (
                                <AppMenuSection
                                    key={menu.sectionTitle}
                                    title={menu.sectionTitle}
                                >
                                    {menu.links.map((link) => {
                                        // Check if the link is staff only and the user is not staff
                                        if (link.isStaffOnly && !user?.is_staff) {
                                            return null;
                                        }

                                        return (
                                            <AppMenuLink
                                                key={link.title}
                                                setCurrentSelection={
                                                    setCurrentSelection
                                                }
                                                linkOption={link}
                                            />
                                        );
                                    })}
                                </AppMenuSection>
                            ))}
                        </div>
                        {currentSelection && (
                            <div className="lui-hidden sm:lui-flex lui-max-w-[450px] lui-flex-col lui-justify-between lui-bg-gray-50 lui-rounded-xl lui-border lui-border-solid lui-border-gray-100">
                                <div className="lui-h-[85%] lui-flex lui-flex-col lui-justify-between lui-gap-2">
                                    <div className="lui-p-3 lui-flex lui-flex-col lui-gap-1">
                                        <Typography weight="medium">
                                            {currentSelection?.title}
                                        </Typography>
                                        <Typography color="gray-700">
                                            {currentSelection?.info}
                                        </Typography>
                                    </div>

                                    <div className="lui-w-full lui-px-4">
                                        <img
                                            src={currentSelection?.imageUrl}
                                            className="lui-w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Popover>
        </div>
    );
}

interface AppMenuLinkProps {
    setCurrentSelection: (value: MenuItemProps) => void;
    linkOption: MenuItemProps;
}
function AppMenuLink({ setCurrentSelection, linkOption }: AppMenuLinkProps) {
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const location = useLocation();

    const isActive = useMemo(() => {
        const normalizedPath = location.pathname;

        return (
            normalizedPath.startsWith(linkOption.href) ||
            // If the current path is /home, we want to highlight the Market Research tab
            (normalizedPath === "/home" && linkOption.href === "/home/market_research")
        );
    }, [linkOption.href, location.pathname]);

    useEffect(() => {
        if (isHover) {
            setCurrentSelection(linkOption);
        }
    }, [isHover, linkOption, setCurrentSelection]);

    return (
        <Button variant="base" href={linkOption.href} ref={hoverRef}>
            <Typography
                variant="h6"
                weight="medium"
                color={isActive ? "primary-500" : "primary-1000"}
                className="hover:lui-bg-gray-50 lui-rounded-lg lui-py-1.5 lui-px-2 lui-pr-20 lui-transition-colors lui-mb-0"
            >
                {linkOption.title}{" "}
                {linkOption.isNew && (
                    <Badge variant="info" className="lui-ml-2">
                        New
                    </Badge>
                )}
                {linkOption.isBeta && (
                    <Badge variant="warning" className="lui-ml-2">
                        Beta
                    </Badge>
                )}
                {linkOption.tag && (
                    <Badge variant="warning" className="lui-ml-2">
                        {linkOption.tag}
                    </Badge>
                )}
            </Typography>
        </Button>
    );
}

function getActiveMenu(location: Location): MenuItemProps | undefined {
    const normalizedPath = location.pathname;

    const isMatchingLink = (link: { href: string }) =>
        normalizedPath.startsWith(link.href) ||
        (normalizedPath === "/home" && link.href === "/home/market_research");

    const currentMenu = AppMenuOptions.find((menu) => menu.links.some(isMatchingLink));

    return currentMenu?.links.find(isMatchingLink);
}

function AppMenuSection({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) {
    return (
        <div>
            <Typography
                variant="h5"
                size="sm"
                weight="medium"
                color="gray-600"
                className="lui-px-2 lui-mb-1"
            >
                {title}
            </Typography>{" "}
            {children}
        </div>
    );
}
