import { useCallback } from "react";
import { isNumber } from "lodash";
import clsx from "clsx";
import { type SavedComp } from "@src/orval/gen/model";
import MapboxPopup, { type MapboxPopupProps } from "@src/components/mapbox_popup";
import { Button } from "@src/land_ui/button/button";
import { Typography } from "@src/land_ui/typography/typography";
import { formatCurrency, PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";

import {
    useCreateSelectedComp,
    useDeleteSelectedComp,
    useSelectedComps,
} from "@src/pages/comping/utils";

interface CompPopupProperties extends MapboxPopupProps {
    title: string;
    properties: any;
    onClose?: () => void;
    reportID?: string;
}

export default function CompPopup({
    title,
    properties,
    onClose,
    longitude,
    latitude,
    reportID,
}: CompPopupProperties) {
    const { data: selectedComps } = useSelectedComps(reportID);
    const { mutate: deleteSelectedComp } = useDeleteSelectedComp();
    const { mutate: createSelectedComp } = useCreateSelectedComp();

    const savedComp = selectedComps?.find(
        (c: SavedComp) => c.source_comp === properties.id,
    );
    const selected = Boolean(savedComp);

    const toggleComp = useCallback(() => {
        if (!reportID) {
            return;
        }
        if (selected) {
            deleteSelectedComp({ reportId: reportID, id: savedComp.id });
        } else {
            createSelectedComp({
                reportId: reportID,
                data: { source_comp: properties.id },
            });
        }
    }, [
        reportID,
        selected,
        savedComp,
        properties.id,
        deleteSelectedComp,
        createSelectedComp,
    ]);

    const daysInMarket =
        isNumber(properties.days_on_market) && properties.days_on_market > 0
            ? `${properties.days_on_market}+`
            : PLACEHOLDER_LABEL;

    return (
        <MapboxPopup
            key={properties.id}
            longitude={longitude}
            latitude={latitude}
            onClose={onClose}
            title={title}
            offset={17}
            columns={2}
            footer={
                Boolean(reportID) ? (
                    <div className="lui-flex lui-gap-2">
                        <Button
                            href={properties.url}
                            openInNewTab={true}
                            className="w-50"
                        >
                            View
                        </Button>
                        <Button
                            className={clsx("w-50", {
                                "lui-button--danger": selected,
                            })}
                            onClick={() => toggleComp()}
                        >
                            {selected ? "Unselect" : "Select"}
                        </Button>
                    </div>
                ) : (
                    <Button href={properties.url} openInNewTab={true} fullWidth>
                        View parcel
                    </Button>
                )
            }
        >
            <RowDetail title="Lot size" value={`${properties.lot_size} acres`} />
            <RowDetail title="Price" value={formatCurrency(properties.price)} />
            <RowDetail title="PPA" value={formatCurrency(properties.ppa)} />
            <RowDetail title="Status" value={properties.status} />
            <RowDetail title="Subdivision" value={properties.subdivision || "N/A"} />
            {properties.days_on_market > 0 && (
                <RowDetail title="Days on market" value={daysInMarket} />
            )}
        </MapboxPopup>
    );
}

function RowDetail({ title, value }: { title: string; value: string }) {
    return (
        <>
            <Typography size="sm">{title}</Typography>
            <Typography size="sm" weight="medium">
                {value}
            </Typography>
        </>
    );
}
