import { isNumber } from "lodash";

const USD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const USDRounded = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export function formatMoneyCents(cents: number) {
    const dollars = cents / 100;
    return USD.format(dollars);
}

export function formatMoneyRounded(dollars: number) {
    return USDRounded.format(dollars);
}

export function formatCredits(credits: number) {
    return `${formatNumber(credits)} credit${credits !== 1 ? "s" : ""}`;
}

export function formatDate(dateString: string) {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
    });
}

export function formatDatetime(dateString: string) {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
        day: "2-digit",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

export function formatTimestamp(timestamp: string) {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
        day: "2-digit",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        month: "2-digit",
        second: "2-digit",
        year: "numeric",
    });
}

export function formatPercent(num: number) {
    return `${num}%`;
}

// Return integer percent with "+" sign if positive.
export function formatPercentWithSign(value: number) {
    return `${value > 0 ? "+" : ""}${value}%`;
}

export function formatNumber(num: any) {
    return isNumber(num) ? num.toLocaleString() : "";
}

export function formatBoolean(val: any) {
    return Boolean(val) ? "Yes" : "No";
}

export function zeroPad(num: string, places: number) {
    return String(num).padStart(places, "0");
}

const irregularNouns: Record<string, string> = {
    miss: "misses",
};

export function pluralize(word: string, count: number) {
    if (count === 1) {
        return word;
    }
    if (irregularNouns[word.toLowerCase()]) {
        return irregularNouns[word.toLowerCase()];
    }
    return `${word}s`;
}

export function capitalize(str: string) {
    if (!str) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
