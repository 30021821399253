import mapboxgl from "mapbox-gl";

export const IS_DEV = import.meta.env.VITE_APP_ENV === "dev";

// Populate API_URL even for requests on same domain because Mapbox layer
// sources with relative URLs are ignored.
export const API_URL = import.meta.env.VITE_API_URL;
export const TILER_URL = import.meta.env.VITE_TILER_URL;

// Stripe IDs
export const STRIPE_PRICING_TABLE_ID = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

// Typeform IDs
export const TYPEFORM_ONBOARDING_FORM_ID = import.meta.env
    .VITE_TYPEFORM_ONBOARDING_FORM_ID;
export const TYPEFORM_FEATURE_IDEA_FORM_ID = "grHJJIYs";

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

// NOTE: Sentry DSN is only used in production builds
export const SENTRY_DSN = import.meta.env.PROD
    ? import.meta.env.VITE_APP_SENTRY_DSN
    : "";

export const SIMPLE_FILE_UPLOAD_KEY = import.meta.env.VITE_SIMPLE_FILE_UPLOAD_KEY;

export const HEAP_ANALYTICS_ID = import.meta.env.VITE_HEAP_ANALYTICS_ID;

export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

export const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;
export const MAPRIGHT_TOKEN = import.meta.env.VITE_MAPRIGHT_TOKEN;

// Configure Mapbox GL token
mapboxgl.accessToken = MAPRIGHT_TOKEN;

export const REPORT_ALL_TOKEN = "xkVu1t45jp";
