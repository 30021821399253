import clsx from "clsx";

type DividerProps = {
    className?: string;
    vertical?: boolean;
};

export function Divider({ className, vertical = false }: DividerProps) {
    return (
        <div
            className={clsx(
                vertical ? "lui-h-full lui-w-0.5" : "lui-h-0.5 lui-w-full",
                "lui-bg-slate-100",
                className,
            )}
        />
    );
}
