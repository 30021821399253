import { first, get } from "lodash";
import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import MapboxHoverTooltip from "@src/components/mapbox_hover_tooltip";

type MouseEventType = mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData;

export function MapHoverTooltip() {
    const mapRef = useMap();
    const [tooltip, setTooltip] = useState({
        longitude: 0,
        latitude: 0,
        label: "",
        isVisible: false,
    });

    useEffect(() => {
        const map = mapRef.current.getMap();

        map.on("mousemove", function (e) {
            // The purpose of this event is to hide the tooltip when the mouse moves out
            // to a new location. We should find better way to hide the tooltip
            if (
                e.lngLat.lat !== tooltip.latitude &&
                e.lngLat.lng !== tooltip.longitude
            ) {
                setTooltip({
                    longitude: e.lngLat.lng,
                    latitude: e.lngLat.lat,
                    label: "",
                    isVisible: false,
                });
            }
        });

        const handleMouseMove = (e: MouseEventType) => {
            const feature = first(e.features);
            if (!feature) {
                return;
            }

            setTooltip({
                longitude: e.lngLat.lng, // offset from cursor
                latitude: e.lngLat.lat,
                label: mapLayerToLabel(feature),
                isVisible: true,
            });
        };

        map.on(
            "mousemove",
            [
                "floodzone_500_fill",
                "floodzone_100_fill",
                "floodzone_coastal_fill",
                "floodzone_floodway_fill",
                "parcel_land_use",
            ],
            handleMouseMove,
        );

        return () => {
            map.off("mousemove", handleMouseMove);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!tooltip.isVisible) {
        return null;
    }

    return (
        <MapboxHoverTooltip
            longitude={tooltip.longitude}
            latitude={tooltip.latitude}
            title={tooltip.label}
        />
    );
}

const floodZoneLayerMap = {
    floodzone_500_fill: "500 Year Flood Zone",
    floodzone_100_fill: "100 Year Flood Zone",
    floodzone_coastal_fill: "Coastal Flood Zone",
    floodzone_floodway_fill: "Floodway",
};
const landUseLayerMap = {
    R: "Residential",
    C: "Commercial",
    O: "Office",
    F: "Recreational",
    I: "Industrial",
    T: "Transportation",
    A: "Agricultural",
    V: "Vacant",
    E: "Exempt",
};
function mapLayerToLabel(feature: mapboxgl.MapboxGeoJSONFeature) {
    if (feature.layer.id === "parcel_land_use") {
        const land_use_code = get(feature, "properties.property_class_id", "Unknown");
        const land_use = get(landUseLayerMap, land_use_code, "Unknown");
        return land_use;
    }
    const label = get(floodZoneLayerMap, feature.layer.id);
    const sfha = get(feature, "properties.sfha");
    return `${label} ${sfha ? "(SFHA)" : ""}`;
}
