import { zodResolver } from "@hookform/resolvers/zod";
import {
    INTEGER_PERCENT_VALIDATOR,
    POSITIVE_NUMBER_VALIDATOR,
} from "@src/functions/validation_utils";
import { CopyButton } from "@src/land_ui/button/copy_button";
import { Icon } from "@src/land_ui/icon/icon";
import { Input } from "@src/land_ui/input/input";
import { Typography } from "@src/land_ui/typography/typography";
import { formatCurrency } from "@src/pages/parcel_viewer/utils";
import clsx from "clsx";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface OfferAndProfitTableProps {
    price: number;
    title: string;
    subTitle: string;
}

export function OfferAndProfitTable({
    price,
    title,
    subTitle,
}: OfferAndProfitTableProps) {
    const schema = z.object({
        offerPricePercentage: INTEGER_PERCENT_VALIDATOR,
        rangeOfferFromPercentage: INTEGER_PERCENT_VALIDATOR,
        rangeOfferToPercentage: INTEGER_PERCENT_VALIDATOR,
        closingCostInDollars: POSITIVE_NUMBER_VALIDATOR,
        realtorCommissionPercentage: POSITIVE_NUMBER_VALIDATOR,
    });

    type SchemaType = z.infer<typeof schema>;

    const {
        register,
        watch,
        formState: { errors },
    } = useForm<SchemaType>({
        resolver: zodResolver(schema),
        mode: "onChange",
        defaultValues: {
            offerPricePercentage: 50,
            rangeOfferFromPercentage: 40,
            rangeOfferToPercentage: 60,
            closingCostInDollars: 3_000,
            realtorCommissionPercentage: 6,
        },
    });

    const totalOfferPrice = useMemo(() => {
        const offerPercentageValue = watch("offerPricePercentage") ?? 0;
        const offerPrice = (price * offerPercentageValue) / 100;
        return offerPrice;
    }, [price, watch]);

    const { rangeOfferFrom, rangeOfferTo } = useMemo(() => {
        const rangeOfferFromPercentage = watch("rangeOfferFromPercentage") ?? 0;
        const rangeOfferToPercentage = watch("rangeOfferToPercentage") ?? 0;
        const rangeOfferFrom = (price * rangeOfferFromPercentage) / 100;
        const rangeOfferTo = (price * rangeOfferToPercentage) / 100;
        return {
            rangeOfferFrom,
            rangeOfferTo,
        };
    }, [price, watch]);
    const realtorCommissionInDollars = useMemo(() => {
        const realtorCommissionPercentage = watch("realtorCommissionPercentage") ?? 0;
        return (price * realtorCommissionPercentage) / 100;
    }, [price, watch]);
    const closingCostInDollars = watch("closingCostInDollars") ?? 0;
    const profit = useMemo(() => {
        return (
            price - totalOfferPrice - closingCostInDollars - realtorCommissionInDollars
        );
    }, [price, totalOfferPrice, closingCostInDollars, realtorCommissionInDollars]);

    return (
        <div>
            <Typography size="lg" color="gray-1000" weight="bold">
                {title}
            </Typography>

            <Typography size="md" color="gray-700">
                {subTitle}
            </Typography>
            <form>
                <div className="lui-table lui-mt-4">
                    <div className="lui-table__wrapper">
                        <table>
                            <thead className="lui-text-sm">
                                <tr>
                                    <th className="lui-w-[75%]">Type</th>
                                    <th className="lui-w-[15%]">Output</th>
                                    <th className="lui-w-[3%]" />
                                </tr>
                            </thead>
                            <tbody className="lui-relative">
                                <tr>
                                    <td>
                                        <OfferContainer
                                            title="Offer"
                                            className="lui-w-24"
                                        >
                                            <Input
                                                size="sm"
                                                placeholder="Offer"
                                                endAdornment={
                                                    <Icon
                                                        name="PercentageSign"
                                                        color="gray-700"
                                                    />
                                                }
                                                error={
                                                    errors.offerPricePercentage?.message
                                                }
                                                {...register("offerPricePercentage")}
                                            />
                                        </OfferContainer>
                                    </td>
                                    <td className="lui-text-end">
                                        {formatCurrency(totalOfferPrice)}
                                    </td>
                                    <td className="lui-text-center">
                                        <CopyButton
                                            color="primary-500"
                                            text={formatCurrency(totalOfferPrice)}
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <OfferContainer
                                            title="Range Offer"
                                            className="lui-w-48"
                                        >
                                            <Input
                                                size="sm"
                                                placeholder="From"
                                                endAdornment={
                                                    <Icon
                                                        name="PercentageSign"
                                                        color="gray-700"
                                                    />
                                                }
                                                error={
                                                    errors.rangeOfferFromPercentage
                                                        ?.message
                                                }
                                                {...register(
                                                    "rangeOfferFromPercentage",
                                                )}
                                            />
                                            <Input
                                                size="sm"
                                                placeholder="To"
                                                endAdornment={
                                                    <Icon
                                                        name="PercentageSign"
                                                        color="gray-700"
                                                    />
                                                }
                                                error={
                                                    errors.rangeOfferToPercentage
                                                        ?.message
                                                }
                                                {...register("rangeOfferToPercentage")}
                                            />
                                        </OfferContainer>
                                    </td>
                                    <td className="lui-text-end">
                                        {formatCurrency(rangeOfferFrom)} to{" "}
                                        {formatCurrency(rangeOfferTo)}
                                    </td>
                                    <td className="lui-text-center">
                                        <CopyButton
                                            color="primary-500"
                                            text={`${formatCurrency(
                                                rangeOfferFrom,
                                            )} to ${formatCurrency(rangeOfferTo)}`}
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <OfferContainer
                                            title="Estimated Closing Costs"
                                            className="lui-w-24"
                                        >
                                            <Input
                                                size="sm"
                                                placeholder="Closing"
                                                icon="DollarSign"
                                                error={
                                                    errors.closingCostInDollars?.message
                                                }
                                                {...register("closingCostInDollars")}
                                            />
                                        </OfferContainer>
                                    </td>
                                    <td className="lui-text-end">
                                        - {formatCurrency(closingCostInDollars)}
                                    </td>
                                    <td className="lui-text-center">
                                        <CopyButton
                                            color="primary-500"
                                            text={formatCurrency(closingCostInDollars)}
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <OfferContainer
                                            title="Realtor Commission"
                                            className="lui-w-24"
                                        >
                                            <Input
                                                size="sm"
                                                placeholder="Commission"
                                                endAdornment={
                                                    <Icon
                                                        name="PercentageSign"
                                                        color="gray-700"
                                                    />
                                                }
                                                error={
                                                    errors.realtorCommissionPercentage
                                                        ?.message
                                                }
                                                {...register(
                                                    "realtorCommissionPercentage",
                                                )}
                                            />
                                        </OfferContainer>
                                    </td>
                                    <td className="lui-text-end">
                                        - {formatCurrency(realtorCommissionInDollars)}
                                    </td>
                                    <td className="lui-text-center">
                                        <CopyButton
                                            color="primary-500"
                                            text={formatCurrency(
                                                realtorCommissionInDollars,
                                            )}
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography
                                            variant="span"
                                            size="sm"
                                            weight="bold"
                                        >
                                            Deal Profit
                                        </Typography>
                                    </td>
                                    <td className="lui-text-end">
                                        ={" "}
                                        <Typography
                                            variant="span"
                                            size="sm"
                                            weight="bold"
                                        >
                                            {formatCurrency(profit)}
                                        </Typography>
                                    </td>
                                    <td className="lui-text-center">
                                        <CopyButton
                                            color="primary-500"
                                            text={formatCurrency(profit)}
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
    );
}

function OfferContainer({
    title,
    children,
    className,
}: {
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className="lui-flex lui-items-center lui-justify-between lui-gap-2">
            <div>{title}</div>
            <div className={clsx("lui-flex lui-gap-2", className)}>{children}</div>
        </div>
    );
}
