import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { Gauge } from "@src/land_ui/charts/gauge";

interface CompingGaugeProps {
    percentage: number;
    label: string;
    title: string;
    subLabel: string;
    tooltipLabel: string;
    disabled?: boolean;
}

export function CompingGauge({
    percentage,
    label,
    subLabel,
    title,
    tooltipLabel,
    disabled,
}: CompingGaugeProps) {
    return (
        <div className="lui-relative lui-rounded-2xl lui-p-3 lui-border lui-border-solid lui-border-gray-200 ">
            <div className="lui-max-w-[375px] lui-mx-auto lui-my-0">
                <Gauge
                    percentage={percentage}
                    label={label}
                    subLabel={subLabel}
                    title={title}
                    disabled={disabled}
                />
            </div>
            <div className="lui-absolute lui-top-2 lui-right-3">
                <ButtonIcon
                    size="sm"
                    icon="InfoCircle"
                    color="gray-600"
                    tooltipLabel={tooltipLabel}
                />
            </div>
        </div>
    );
}
