import { createContext, useContext } from "react";

import {
    type ParcelSearchResponse,
    type ParcelSavedList,
    type FullParcel,
} from "@src/orval/gen/model";

import type { User, County, MapFilter, DebugPoint } from "./types";

export interface SearchResultOptions {
    disableZoom: boolean;
}

export interface OwnerPropertyState {
    ownerParcelId: number;
    ownerParcelList: number[];
}

export interface ParcelViewerContextType {
    setMapFilter: (value: MapFilter) => void;
    county: County;
    setCounty: (value: County) => void;
    savedList: ParcelSavedList;
    setSavedList: (value: ParcelSavedList) => void;
    setDebugPoints: (points: DebugPoint[]) => void;
    toggleTileBoundaries: () => void;
    searchResult: ParcelSearchResponse;
    setSearchResult: (
        value: ParcelSearchResponse,
        options?: SearchResultOptions,
    ) => void;
    setActivePolygon: (value?: boolean) => void;
    isPolygonActive: boolean;
    // Owner Property data
    ownerPropertyData?: OwnerPropertyState;
    setOwnerPropertyData: (value: OwnerPropertyState) => void;
    goToParcelInMap: (parcel: FullParcel) => void;
}

export const ParcelViewerContext = createContext<ParcelViewerContextType | null>(null);

interface UserContextType {
    user?: User;
}

export const UserContext = createContext<UserContextType | null>(null);

export function useParcelViewerContext() {
    const context = useContext(ParcelViewerContext);
    if (!context) {
        throw new Error("useParcelViewerContext must be used within a MapProvider");
    }
    return context;
}
