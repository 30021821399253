import {
    type ListItem,
    type ParcelSearchResult,
    type ParcelsListParams,
} from "@src/orval/gen/model";
import { type FeaturePolygon } from "@src/components/map_tools/map_polygon";

import { type PopupOptions } from "mapbox-gl";

export type Point = readonly number[];
export type BBox = [number, number, number, number];

export interface DebugPoint {
    point: Point;
    label: string;
    color: string;
}

export interface County {
    id: string; // FIPS code
    name: string; // County name (ex. "Kershaw County")
}

// Rename ParcelSearchResult to Parcel for brevity.
export type Parcel = ParcelSearchResult;

// Option is the official type used by react-bootstrap-typeahead. Its only
// purpose is to satisfy the TS type checker for Typeahead event handlers.
// Source: https://github.com/ericgio/react-bootstrap-typeahead/blob/main/src/types.ts#L31
export type Option = string | Record<string, any>;

// SearchOption is a more specific Option with typed fields for the typeahead control.
export interface SearchOption {
    id: string;
    label: string;
    // Optional subLabel (ex. for Mapbox search)
    subLabel: string;
    // Optional value (ex. for GPS search)
    value: any;
}

export interface CountyOption {
    id: string; // FIPS code
    label: string; // ex. "Kershaw County, SC"
}

export interface ParcelOption {
    PropertyID: number;
    readonly point: readonly number[];
}

export interface MapFilter {
    identifyLayer: string; // Target map layer for which this filter applies to
    filter: any[]; // Mapbox GL filter expression
    inverseFilter: any[]; // Mapbox GL filter expression
}

// This is the type used when saving a filter to the db
export interface SaveFilterParcelsListParams extends ParcelsListParams {
    county_label: string;
    countyOption: CountyOption;
    polygons?: FeaturePolygon[];
    landUseCodeList?: ListItem[];
    minRoadFrontage?: number;
    maxRoadFrontage?: number;
    roadFrontageIncludeHighways?: boolean;
    roadFrontageIncludeLocalRoads?: boolean;
    roadFrontageIncludeNormalRoads?: boolean;
    currentSalePriceCodeList?: ListItem[];
    currentSaleDocumentTypeList?: ListItem[];
    concurrentMtg1TypeFinancingList?: ListItem[];
    concurrentMtg1LoanTypeList?: ListItem[];
}

export interface FIPSLookupResult {
    results: CountyOption[];
}

// RegionStatistics Django model
export interface RegionStatistics {
    type: string;
    data: Record<string, any>;
}

// Region Django model
export interface Region {
    id: string;
    type: string;
    gid: string;
    name: string;
    county_name: string;
    county_fips: string;
    state: string;
    out_of_county: number;
    out_of_state: number;
    stats: RegionStatistics[];
}

// User Django model
export interface User {
    has_paid_subscription: boolean;
    has_unlimited_data: boolean;
    is_staff: boolean;
    stripe_trial_end: Date;
}

export interface ParcelExportPrice {
    price_per_row: number;
    total_price: number;
}

export interface LandScrubPrice {
    flood_zone_price: number;
    flood_zone_price_per_row: number;
    land_locked_price: number;
    land_locked_price_per_row: number;
    wetlands_price: number;
    wetlands_price_per_row: number;
    total_price: number;
}

export interface SkipTracePrice {
    price_per_row: number;
    scrub_dnc_price: number;
    scrub_dnc_price_per_row: number;
    total_price: number;
    report_type: "standard" | "premium";
}

export interface RowSummary {
    total_rows: number;
}

export interface ParcelExportPriceBreakdown {
    parcel_export_price: ParcelExportPrice;
    land_scrub_price: LandScrubPrice;
    skip_trace_price?: SkipTracePrice; // Null if Skip Trace not enabled
    row_summary: RowSummary;
    total_price: number;
}

export interface PopupInfo extends PopupOptions {
    title: string;
    type: "mls_comp" | "parcel_chooser";
    properties: any;
    latitude: number;
    longitude: number;
}

export type ResultValue<T> = { ok: true; value: T };
export type ResultError = { ok: false; error: string };
export type Result<T> = ResultValue<T> | ResultError;

export function isResultValue<T>(res: Result<T>): res is ResultValue<T> {
    return res != null && res.ok;
}

export function isResultError<T>(res: Result<T>): res is ResultError {
    return res != null && !res.ok;
}
