import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import {
    first,
    get,
    isNumber,
    last,
    lowerCase,
    round,
    toNumber,
    toString,
} from "lodash";

import { Badge } from "@src/land_ui/badge/badge";
import { CopyButton } from "@src/land_ui/button/copy_button";
import { Tabs } from "@src/land_ui/tabs/tabs";
import {
    Typography,
    type TypographyWhitespace,
} from "@src/land_ui/typography/typography";

import { ACRE_RANGES } from "@src/constants";
import { type FullParcel } from "@src/orval/gen/model";
import type { Point, Region } from "@src/pages/parcel_viewer/types";
import { fetch, formatNumber } from "functions";

import { formatDate, type FormatDateType } from "@src/functions/date_utils";
import { useParcel, useParcelQueryParam } from "@src/hooks/useParcel";
import { useSidebarControl } from "@src/hooks/useSidebarControl";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import {
    formatCurrency,
    getParcelMetrics,
    getTargetRange,
    PLACEHOLDER_LABEL,
} from "@src/pages/parcel_viewer/utils";
import { Icon } from "@src/land_ui/icon/icon";
import { useParcelsOwnerPortfolioRetrieve } from "@src/orval/gen/api";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";
import { useParcelViewerContext } from "@src/pages/parcel_viewer/context";

interface ParcelDetailProps {
    parcelID: number;
    onClose: () => void;
}

export function ParcelDetail({ parcelID, onClose }: ParcelDetailProps) {
    const { setDebugPoints, setOwnerPropertyData } = useParcelViewerContext();
    const [county, setCounty] = useState<Region>();
    const [countyError, setCountyError] = useState("");
    const { removeParcelQuery } = useParcelQueryParam();
    const { parcel } = useParcel({
        parcelId: parcelID,
        enabled: true,
    });
    const { sidebarType, setIsSidebarOpen } = useSidebarControl();

    const { data: ownerPropertyData } = useParcelsOwnerPortfolioRetrieve(parcelID, {
        query: {
            enabled: Boolean(parcelID),
            queryKey: ["parcelsOwnerPortfolioRetrieve", parcelID],
        },
    });

    useEffect(() => {
        if (parcel) {
            setDebugPoints([
                { point: parcel.point as Point, label: "Calculated", color: "green" },
                {
                    point: [parcel.SitusLongitude, parcel.SitusLatitude],
                    label: "Original",
                    color: "blue",
                },
            ]);
        }
    }, [parcel, setDebugPoints]);

    useEffect(() => {
        if (!parcel) {
            return;
        }

        const fetchCounty = async (fips: string) => {
            try {
                const newCounty: Region = await fetch(
                    `/api/county_metrics/${fips}/?v=4`,
                );
                setCounty(newCounty);
            } catch (xhr) {
                setCountyError("Error loading county metrics.");
                console.error("Error loading county", xhr);
            }
        };

        fetchCounty(parcel.FIPS);
    }, [parcel]);

    return (
        <div className="lui-py-6 lui-h-[85vh] lui-z-10 lui-ring-8 ring-inset lui-absolute lui-top-24 lui-left-8 lui-bg-white lui-rounded-xl lui-w-[475px]  lui-flex lui-flex-col  ">
            <ErrorBoundary>
                {Boolean(sidebarType) && (
                    <>
                        <div className="lui-px-5  lui-flex lui-justify-between">
                            <Button
                                variant="base"
                                icon="LeftArrow"
                                onClick={() => {
                                    removeParcelQuery();
                                    setIsSidebarOpen(true);
                                    setOwnerPropertyData({
                                        ownerParcelId: parcelID,
                                        ownerParcelList: [],
                                    });
                                }}
                            >
                                Back to list
                            </Button>

                            <CloseButton onClick={onClose} />
                        </div>
                        <Divider className="lui-my-4" />
                    </>
                )}
                <div className="lui-px-6 lui-flex lui-justify-end">
                    {!sidebarType && <CloseButton onClick={onClose} />}
                </div>

                <div className="lui-px-6  lui-flex lui-justify-between">
                    <div className="lui-flex lui-flex-col gap-1">
                        <Typography
                            size="xl"
                            color="gray-1000"
                            weight="bold"
                            className="lui-capitalize"
                        >
                            {getOwnerFullName(parcel)}
                        </Typography>
                        <div className="lui-flex lui-justify-between">
                            <ParcelAcresText parcel={parcel} />
                        </div>
                    </div>
                    <div className="lui-flex lui-justify-end lui-items-end">
                        {ownerPropertyData?.results?.length > 1 && (
                            <Button
                                variant="base"
                                onClick={() => {
                                    setIsSidebarOpen(true, "owner");
                                    removeParcelQuery();
                                    setOwnerPropertyData({
                                        ownerParcelId: parcelID,
                                        ownerParcelList: [],
                                    });
                                }}
                            >
                                <span className="lui-flex lui-items-baseline lui-gap-0.5">
                                    <Typography
                                        size="sm"
                                        weight="medium"
                                        color="primary-500"
                                        className="lui-whitespace-nowrap"
                                    >
                                        {ownerPropertyData?.total_parcels} properties
                                    </Typography>
                                    <Icon
                                        name="ArrowUpRight"
                                        color="primary-500"
                                        size="sm"
                                    />
                                </span>
                            </Button>
                        )}
                    </div>
                </div>
                <div className="lui-grid lui-grid-cols-2">
                    <div className="lui-mt-4 lui-px-6">
                        <Typography size="sm" weight="medium">
                            {getValueOrPlaceholder(county, "name")},{" "}
                            {getValueOrPlaceholder(county, "state")}
                        </Typography>
                        <Typography
                            size="sm"
                            weight="medium"
                            color="gray-700"
                            className="lui-mt-0.5"
                        >
                            <span>
                                {getValueOrPlaceholder(parcel, "SitusZIP5")}-
                                {getValueOrPlaceholder(parcel, "SitusZIP4")}
                            </span>
                        </Typography>
                    </div>
                    <div className="lui-mt-4 lui-px-6 lui-flex lui-flex-wrap lui-flex-shrink lui-gap-1 lui-items-start">
                        {(parcel?.Owner1CorpInd || parcel?.Owner2CorpInd) && (
                            <Badge variant="warning">Corp Owned</Badge>
                        )}
                        {(parcel?.HOA1Type || parcel?.HOA2Type) && (
                            <Badge variant="warning">In HOA</Badge>
                        )}
                        {(parcel?.SumBuildingsNbr ||
                            parcel?.SumBuildingSqFt ||
                            parcel?.EffectiveYearBuilt ||
                            parcel?.YearBuilt) && (
                            <Badge variant="warning">Structure</Badge>
                        )}
                        {parcel?.MobileHomeInd && (
                            <Badge variant="warning">Mobile Home</Badge>
                        )}
                        {parcel?.InterFamilyFlag && (
                            <Badge variant="info">Family Transfer</Badge>
                        )}
                    </div>
                </div>
                <NewParcelAcresStats
                    parcel={parcel}
                    county={county}
                    countyError={countyError}
                />
                <ParcelDetailTabs
                    parcel={parcel}
                    county={county}
                    showToolsTab
                    showMetricsTab
                />
            </ErrorBoundary>
        </div>
    );
}

export function ParcelDetailTabs({
    parcel,
    county,
    showToolsTab,
    showMetricsTab,
}: {
    parcel: FullParcel;
    county: Region;
    showToolsTab: boolean;
    showMetricsTab: boolean;
}) {
    return (
        <div className="lui-mt-1 lui-overflow-y-auto">
            <Tabs
                // Use key to force re-render when parcel changes, this will reset the active tab
                key={get(parcel, "PropertyID")}
                options={[
                    {
                        title: "Summary",
                        content: (
                            <TabParcelSummaryDetails parcel={parcel} county={county} />
                        ),
                    },
                    {
                        title: "Parcel",

                        content: <TabParcelDetails parcel={parcel} county={county} />,
                    },
                    {
                        title: "Owner",
                        content: <TabOwnerDetails parcel={parcel} />,
                    },
                    {
                        title: "Structure",
                        content: <TabStructureDetails parcel={parcel} />,
                    },
                    showMetricsTab && {
                        title: "Metrics",
                        content: <TabMetricsDetails parcel={parcel} county={county} />,
                    },
                    showToolsTab && {
                        title: "Tools",
                        content: <TabTools parcel={parcel} />,
                    },
                ]}
            />
        </div>
    );
}

interface ParcelDetailBodyProps {
    parcel: FullParcel;
    county: Region;
    countyError: string;
}

function TabMetricsDetails({ parcel, county }: { parcel: FullParcel; county: Region }) {
    const metricsDetails = getParcelMetrics(parcel, county);
    return (
        <div>
            <div className="lui-grid lui-gap-4 lui-grid-cols-2">
                <div className="lui-col-span-2">
                    <Typography weight="bold">{county?.name} Metrics</Typography>
                    <Typography size="sm" color="gray-700">
                        {getParcelAcres(parcel)}
                    </Typography>
                </div>
            </div>
            <div className="lui-grid lui-gap-4 lui-grid-cols-4 lui-mt-6">
                <ParcelSummaryDetailsCell
                    title="Active"
                    content={metricsDetails.ACTIVE}
                />
                <ParcelSummaryDetailsCell
                    title="Pending"
                    content={metricsDetails.PENDING}
                />
                <ParcelSummaryDetailsCell
                    title="1yr STR"
                    content={
                        metricsDetails.STR_1_YEAR
                            ? `${metricsDetails.STR_1_YEAR}%`
                            : PLACEHOLDER_LABEL
                    }
                />
                <ParcelSummaryDetailsCell
                    title="Median Sold PPA"
                    content={metricsDetails.MEDIAN_SOLD_PPA}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 1mo"
                    content={metricsDetails.SOLD_1_MONTH}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 3mo"
                    content={metricsDetails.SOLD_3_MONTHS}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 6mo"
                    content={metricsDetails.SOLD_6_MONTHS}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 1yr"
                    content={metricsDetails.SOLD_1_YEAR}
                />
            </div>
        </div>
    );
}

function TabTools({ parcel }: { parcel: FullParcel }) {
    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <div className="lui-col-span-2">
                <Button
                    href={`/comping/?parcel=${get(parcel, "PropertyID")}`}
                    variant="primary"
                    openInNewTab
                >
                    <span className="lui-flex lui-gap-2 lui-items-center">
                        Comp Property{" "}
                        <Icon name="RightArrow" color="inherit" className="lui-ml-2" />
                    </span>
                </Button>
            </div>
        </div>
    );
}

function NewParcelAcresStats({ parcel, county }: ParcelDetailBodyProps) {
    const acresSizeLabel = getParcelAcres(parcel);
    const metricsDetails = getParcelMetrics(parcel, county);

    return (
        <div className="lui-mt-4  lui-grid lui-grid-cols-2 lui-justify-between lui-border-solid lui-border-gray-100">
            <div className="lui-px-6 lui-py-3 lui-border-y-0 lui-border-r lui-border-solid lui-border-gray-100">
                <Typography color="gray-700" size="sm">
                    {acresSizeLabel} 1 Yr STR
                </Typography>
                <Typography weight="medium" size="sm" className="lui-mt-2">
                    {metricsDetails.STR_1_YEAR
                        ? `${metricsDetails.STR_1_YEAR}%`
                        : PLACEHOLDER_LABEL}
                </Typography>
            </div>

            <div className="lui-px-6 lui-py-3">
                <Typography color="gray-700" size="sm">
                    {acresSizeLabel} Total Sold 1 Yr
                </Typography>
                <Typography weight="medium" size="sm" className="lui-mt-2">
                    {getValueOrPlaceholder(metricsDetails, "SOLD_1_YEAR")}
                </Typography>
            </div>
        </div>
    );
}

function CloseButton({ onClick }: { onClick: () => void }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lui-cursor-pointer"
            onClick={onClick}
        >
            <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#171717"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function TabParcelSummaryDetails({
    parcel,
    county,
}: {
    parcel: FullParcel;
    county: Region;
}) {
    if (!parcel) {
        return null;
    }

    const lng = first(parcel.point) || PLACEHOLDER_LABEL;
    const lat = last(parcel.point) || PLACEHOLDER_LABEL;

    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Owner Mailing Address"
                    content={getParcelMailingAddress(parcel)}
                />
            </div>
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Property Address"
                    content={getPropertyAddress(parcel)}
                />
            </div>

            <ParcelSummaryDetailsCell
                title="County"
                content={get(county, "name", PLACEHOLDER_LABEL)}
            />
            <ParcelSummaryDetailsCell
                title="Acres"
                content={getDecimalValue(get(parcel, "LotSizeAcres"), 3)}
            />
            <ParcelSummaryDetailsCell
                title="Last Purchase Price"
                content={formatCurrency(get(parcel, "CurrentSalesPrice"))}
            />
            <ParcelSummaryDetailsCell
                title="Last Purchase Type"
                content={getValueOrPlaceholder(parcel, "CurrentSalesPriceCode")}
            />
            <ParcelSummaryDetailsCell
                title="Last Purchase Date"
                content={
                    <RelativeDate
                        date={formatDate(get(parcel, "CurrentSaleRecordingDate"))}
                    />
                }
            />
            <ParcelSummaryDetailsCell
                title="Deed Type"
                content={getValueOrPlaceholder(parcel, "CurrentSaleDocumentType")}
            />
            <ParcelSummaryDetailsCell
                title="APN"
                content={getValueOrPlaceholder(parcel, "APN")}
                whitespace={"pre-wrap"}
            />
            <ParcelSummaryDetailsCell
                title="Zoning"
                content={getValueOrPlaceholder(parcel, "Zoning")}
            />
            <ParcelSummaryDetailsCell title="GPS" content={`${lat}, ${lng}`} />
            <ParcelSummaryDetailsCell
                title="Property Tax"
                content={formatCurrency(get(parcel, "TaxAmt", 0) / 100)}
            />
            <ParcelSummaryDetailsCell
                title="Tax Delinquent For"
                content={getValueOrPlaceholder(parcel, "TaxDeliquentYear")}
            />
            <ParcelSummaryDetailsCell
                title="In HOA"
                content={parcel?.HOA1Type ? "Yes" : "No"}
            />

            <ParcelSummaryDetailsCell
                title="Family Transfer"
                content={parcel?.InterFamilyFlag ? "Yes" : "No"}
            />

            <ParcelSummaryDetailsCell
                title="Mobile Home"
                content={parcel?.MobileHomeInd ? "Yes" : "No"}
            />
            <ParcelSummaryDetailsCell
                title="Google Maps"
                content={
                    parcel ? (
                        <Button
                            href={`https://www.google.com/maps/place/${lat},${lng}`}
                            variant="base"
                            openInNewTab
                            className="lui-underline"
                        >
                            Link
                        </Button>
                    ) : (
                        PLACEHOLDER_LABEL
                    )
                }
            />
            <ParcelSummaryDetailsCell
                title="Google Earth"
                content={
                    parcel ? (
                        <Button
                            href={`https://earth.google.com/web/@${lat},${lng},1000a,35y,0h,0t,0r`}
                            variant="base"
                            openInNewTab
                            className="lui-underline"
                        >
                            Link
                        </Button>
                    ) : (
                        PLACEHOLDER_LABEL
                    )
                }
            />

            <ParcelSummaryDetailsCell
                title="Assessed Value"
                content={getCurrencyOrPlaceholder(parcel, "AssdTotalValue")}
            />
            <ParcelSummaryDetailsCell
                title="Assessed Land Value"
                content={getCurrencyOrPlaceholder(parcel, "AssdLandValue")}
            />
            <ParcelSummaryDetailsCell
                title="Assessed Improvement Value"
                content={getCurrencyOrPlaceholder(parcel, "AssdImprovementValue")}
            />
            <ParcelSummaryDetailsCell
                title="Market Total Value"
                content={getCurrencyOrPlaceholder(parcel, "MarketTotalValue")}
            />
            <ParcelSummaryDetailsCell
                title="Market Land Value"
                content={getCurrencyOrPlaceholder(parcel, "MarketValueLand")}
            />
            <ParcelSummaryDetailsCell
                title="Market Improvement Value"
                content={getCurrencyOrPlaceholder(parcel, "MarketValueImprovement")}
            />

            {/*<ParcelSummaryDetailsCell*/}
            {/*    title="Street View"*/}
            {/*    content={*/}
            {/*        parcel ? (*/}
            {/*            <Button*/}
            {/*                href={`https://www.google.com/maps/@${lat},${lng},3a,75y,0h,90t/data=!3m6!1e1!3m4!1s!2e0!7i16384!8i8192`}*/}
            {/*                variant="base"*/}
            {/*                openInNewTab*/}
            {/*                className="lui-underline"*/}
            {/*            >*/}
            {/*                Link*/}
            {/*            </Button>*/}
            {/*        ) : (*/}
            {/*            PLACEHOLDER_LABEL*/}
            {/*        )*/}
            {/*    }*/}
            {/*/>*/}

            <ParcelSummaryDetailsCell
                title="Legal Description"
                content={getValueOrPlaceholder(parcel, "LegalDescription")}
            />
            <ParcelSummaryDetailsCell
                title="Calculated Acres"
                content={getDecimalValue(get(parcel, "CalculatedAcres"))}
            />
        </div>
    );
}

interface RelativeDateProps {
    date: FormatDateType;
}

function RelativeDate({ date }: RelativeDateProps) {
    return <span title={formatDate(date)}>{formatDate(date, "RELATIVE")}</span>;
}

function TabParcelDetails({ parcel, county }: { parcel: FullParcel; county: Region }) {
    if (!parcel) {
        return null;
    }

    const lng = parcel.point[0];
    const lat = parcel.point[1];

    const mortgageMatureDate = dayjs(get(parcel, "ConcurrentMtg1RecordingDate")).add(
        toNumber(get(parcel, "ConcurrentMtg1Term")),
        "months",
    );

    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <ParcelSummaryDetailsCell title="County" content={county?.name} />
            <ParcelSummaryDetailsCell
                title="Acres"
                content={getDecimalValue(get(parcel, "LotSizeAcres"), 3)}
            />
            <ParcelSummaryDetailsCell
                title="APN"
                content={getValueOrPlaceholder(parcel, "APN")}
            />

            <ParcelSummaryDetailsCell
                title="Calculated Acres"
                content={getDecimalValue(get(parcel, "CalculatedAcres"))}
            />
            <ParcelSummaryDetailsCell title="GPS" content={`${lat}, ${lng}`} />
            <ParcelSummaryDetailsCell
                title="Improvement %"
                content={
                    isNumber(parcel?.ImprovementPercentage)
                        ? `${parcel.ImprovementPercentage}%`
                        : PLACEHOLDER_LABEL
                }
            />
            <ParcelSummaryDetailsCell
                title="Zoning"
                content={getValueOrPlaceholder(parcel, "Zoning")}
            />

            <ParcelSummaryDetailsCell
                title="Current Land Use"
                content={getValueOrPlaceholder(parcel, "LandUseCode")}
            />
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Subdivision"
                    content={getValueOrPlaceholder(parcel, "SubdivisionName")}
                />
            </div>
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Legal Description"
                    content={getValueOrPlaceholder(parcel, "LegalDescription")}
                />
            </div>
            <ParcelSummaryDetailsCell
                title="Mortgage Amount"
                content={formatCurrency(get(parcel, "ConcurrentMtg1LoanAmt"))}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Length"
                content={getTermsLabel(get(parcel, "ConcurrentMtg1Term"))}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Lender"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1Lender")}
            />

            <ParcelSummaryDetailsCell
                title="Mortgage Matures In"
                content={<RelativeDate date={mortgageMatureDate} />}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Type"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1TypeFinancing")}
            />
            <ParcelSummaryDetailsCell
                title="Loan Type"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1LoanType")}
            />
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Mortgage Interest"
                    content={getInterestRate(parcel?.ConcurrentMtg1InterestRate)}
                />
            </div>
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="School District"
                    content={getValueOrPlaceholder(parcel, "SchoolDistrictName")}
                />
            </div>
        </div>
    );
}

function TabOwnerDetails({ parcel }: { parcel: FullParcel }) {
    if (!parcel) {
        return null;
    }

    return (
        <>
            <div className="lui-grid lui-gap-4 lui-grid-cols-2">
                <div className="lui-col-span-2 lui-p-2">
                    <Typography size="sm" weight="medium" className="lui-capitalize">
                        {getOwnerFullName(parcel)}
                    </Typography>
                    <Typography size="sm" color="gray-700" className="lui-mt-0.5">
                        {getParcelMailingAddress(parcel)}
                    </Typography>
                </div>

                <ParcelSummaryDetailsCell
                    title="Out of state"
                    content={parcel.OutOfStateOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Out of County"
                    content={parcel.OutOfCountyOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Out of ZIP"
                    content={parcel.OutOfZipOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Ownership Length"
                    content={
                        <RelativeDate date={get(parcel, "CurrentSaleRecordingDate")} />
                    }
                />
                <div className="lui-col-span-2">
                    <ParcelSummaryDetailsCell
                        title="Prior Owner Name"
                        content={getValueOrPlaceholder(
                            parcel,
                            "CurrentSaleSeller1FullName",
                        )}
                    />
                </div>
            </div>
            <div className="lui-col-span-2 lui-mt-8">
                <Typography color="black" weight="bold" size="xl">
                    Chain of Ownership
                </Typography>
            </div>
            <div className="lui-mt-3 lui-flex lui-flex-col lui-gap-3">
                <ParcelOwnerDetailsCard
                    date={get(parcel, "CurrentSaleRecordingDate")}
                    isFamilyTransfer={parcel?.InterFamilyFlag}
                    hasMortgage={Boolean(parcel?.ConcurrentMtg1LoanAmt)}
                    name={getOwnerFullName(parcel)}
                    amount={get(parcel, "CurrentSalesPrice")}
                />
                <ParcelOwnerDetailsCard
                    date={formatDate(get(parcel, "PrevSaleRecordingDate"))}
                    amount={get(parcel, "PrevSalesPrice")}
                    name={getValueOrPlaceholder(parcel, "CurrentSaleSeller1FullName")}
                />
            </div>
        </>
    );
}

function TabStructureDetails({ parcel }: { parcel: FullParcel }) {
    if (!parcel) {
        return null;
    }

    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <ParcelSummaryDetailsCell
                title="Structures"
                content={`${formatNumber(parcel.SumBuildingSqFt)} sqft`}
            />
            <ParcelSummaryDetailsCell
                title="Structure count"
                content={parcel.SumBuildingsNbr}
            />
            <ParcelSummaryDetailsCell
                title="Structure year built"
                content={getValueOrPlaceholder(parcel, "EffectiveYearBuilt")}
            />
        </div>
    );
}

function getParcelMailingAddress(parcel: FullParcel) {
    const fullAddress = get(parcel, "MailingFullStreetAddress");
    const city = get(parcel, "MailingCity");
    const state = get(parcel, "MailingState");
    const zip5 = get(parcel, "MailingZIP5");

    if (!fullAddress) {
        return "Unknown Mailing Address";
    }

    return `${fullAddress}, ${city}, ${state} ${zip5}`;
}

function getPropertyAddress(parcel: FullParcel) {
    const fullAddress = get(parcel, "SitusFullStreetAddress");
    const city = get(parcel, "SitusCity");
    const state = get(parcel, "SitusState");
    const zip5 = get(parcel, "SitusZIP5");

    if (!fullAddress) {
        return PLACEHOLDER_LABEL;
    }

    return `${fullAddress}, ${city}, ${state} ${zip5}`;
}

export function ParcelSummaryDetailsCell({
    title,
    content,
    whitespace,
}: {
    title: React.ReactNode;
    content: React.ReactNode;
    whitespace?: TypographyWhitespace;
}) {
    if (!content) {
        content = PLACEHOLDER_LABEL;
    }
    return (
        <div className="lui-p-2 lui-group hover:lui-bg-gray-100 lui-rounded-md">
            <div className="lui-flex lui-justify-between lui-items-center">
                <div>
                    <Typography size="sm" color="gray-700">
                        {title}
                    </Typography>
                    <Typography
                        size="sm"
                        weight="medium"
                        className="lui-mt-0.5"
                        whitespace={whitespace}
                    >
                        {content}
                    </Typography>
                </div>
                {Boolean(content) && content !== PLACEHOLDER_LABEL && (
                    <span>
                        <CopyButton
                            text={toString(content)}
                            className="lui-hidden group-hover:lui-block "
                        />
                    </span>
                )}
            </div>
        </div>
    );
}

export function ParcelAcresText({ parcel }: { parcel: FullParcel }) {
    const parcelAcres = getDecimalValue(get(parcel, "LotSizeAcres"), 3);

    return (
        <Typography color="primary-500" weight="bold">
            {parcelAcres} acres
        </Typography>
    );
}

interface ParcelOwnerDetailsCardProps {
    date: string;
    isFamilyTransfer?: boolean;
    hasMortgage?: boolean;
    name: string;
    amount: number;
}

function ParcelOwnerDetailsCard({
    date,
    isFamilyTransfer,
    hasMortgage,
    name,
    amount,
}: ParcelOwnerDetailsCardProps) {
    return (
        <div className="lui-p-4 lui-border-solid lui-border-gray-200 lui-rounded-xl">
            <div className="lui-flex lui-justify-between">
                <Typography color="gray-700" size="sm">
                    {formatDate(date, "LONG_DATE")}
                </Typography>
                <div className="lui-flex lui-gap-2">
                    {isFamilyTransfer && (
                        <Badge variant="warning">Family Transfer</Badge>
                    )}
                    {hasMortgage && <Badge variant="danger">Mortgage</Badge>}
                </div>
            </div>

            <Typography weight="bold" className="lui-mt-1 lui-capitalize">
                {name}
            </Typography>
            <Typography color="primary-500" className="lui-mt-1">
                {amount ? formatCurrency(amount) : PLACEHOLDER_LABEL}
            </Typography>
        </div>
    );
}

export function ParcelApnLabel({ parcel }: { parcel: FullParcel }) {
    const { goToParcelInMap } = useParcelViewerContext();
    return (
        <Button variant="base" onClick={() => goToParcelInMap(parcel)}>
            <span className="lui-flex lui-items-center lui-gap-1 lui-justify-between">
                <Icon name="TargetIcon" size="sm" color="gray-700" />{" "}
                <Typography size="sm" color="gray-700">
                    {getValueOrPlaceholder(parcel, "APN")}
                </Typography>
            </span>
        </Button>
    );
}

// TODO: Could not Fast Refresh ("getOwnerFullName" export is incompatible).
// Learn more at https://github.com/vitejs/vite-plugin-react/tree/main/packages/plugin-react#consistent-components-exports
export function getOwnerFullName(parcel: FullParcel) {
    if (!parcel) {
        return "";
    }

    if (parcel.Owner1CorpInd === "T") {
        return parcel.OwnerNAME1FULL;
    }

    if (!parcel.Owner1FirstName && !parcel.Owner1LastName) {
        return "Unknown Owner";
    }

    return lowerCase(
        `${parcel?.Owner1FirstName ?? ""} ${parcel?.Owner1MiddleName ?? ""} ${
            parcel?.Owner1LastName ?? ""
        }`,
    );
}

function getParcelAcres(parcel: FullParcel) {
    if (!parcel) {
        return PLACEHOLDER_LABEL;
    }

    const lotSize = getDecimalValue(parcel?.LotSizeAcres, 3);
    const range = getTargetRange(parcel);

    if (!range) {
        return "Unknown";
    }

    const [, , acreageKey] = range;

    let acresSizeLabel = ACRE_RANGES[acreageKey] || "Unknown";

    if (acresSizeLabel === "Unknown" && lotSize >= 100) {
        acresSizeLabel = "100+ Acres";
    }

    return acresSizeLabel;
}

function getInterestRate(rate: number) {
    if (!isNumber(rate)) {
        return PLACEHOLDER_LABEL;
    }
    const rateValue = rate / 100;

    return `${rateValue}%`;
}

function getTermsLabel(term: string) {
    if (!term) {
        return PLACEHOLDER_LABEL;
    }

    const termsNumber = parseInt(term, 10);

    if (termsNumber >= 12) {
        return `${round(termsNumber / 12)} years`;
    }

    return `${termsNumber} months`;
}

/**
 * Converts a value to a decimal string with an implied decimal place.
 *
 * @param {number} [value] - The value to be converted.
 * @param {number} [impliedDecimal=2] - The number of implied decimal places.
 * @returns {string|number} The value as a decimal string or a placeholder label if the value is not provided.
 */
export function getDecimalValue(value?: number, impliedDecimal = 0) {
    if (!value) {
        return 0;
    }

    const result = (value / Math.pow(10, impliedDecimal)).toFixed(1);
    return parseFloat(result) % 1 === 0 ? parseInt(result, 10) : toNumber(result);
}

export function getValueOrPlaceholder(obj: unknown, key: string): string {
    const value = get(obj, key);

    if (isNumber(value)) {
        return toString(value);
    }

    if (Boolean(value)) {
        return value;
    }

    return PLACEHOLDER_LABEL;
}

export function getCurrencyOrPlaceholder(obj: unknown, key: string): string {
    const value = get(obj, key);
    if (value !== undefined && value !== null) {
        return formatCurrency(value);
    }
    return PLACEHOLDER_LABEL;
}

export function formatCurrencyInKs(value?: number, threshold: number = 1000) {
    // Handle edge cases
    if (value === null || value === undefined || isNaN(value)) {
        return PLACEHOLDER_LABEL;
    }

    const isNegative: boolean = value < 0;
    const absValue: number = Math.abs(value);

    // Determine if we should use "k" suffix
    if (absValue >= threshold) {
        // Convert to thousands and format with decimal places
        const valueInK: string = (absValue / 1000).toFixed(0);
        // Remove trailing zeros after decimal point but keep the formatted precision
        const trimmedValue: string = parseFloat(valueInK).toString();
        // Add the sign and "k" suffix
        return `${isNegative ? "-" : ""}${trimmedValue}k`;
    }

    return `${isNegative ? "-" : ""}${absValue.toFixed(2).toString()}`;
}
