import type { InsightRulesResponse } from "@src/orval/gen/model";
import { Typography } from "@src/land_ui/typography/typography";
import { Icon } from "@src/land_ui/icon/icon";
import { CompingSection } from "./comping_section";

export function CompingReportInsights({
    header,
    insights,
}: {
    header: string;
    insights: InsightRulesResponse;
}) {
    return (
        <CompingSection>
            <Typography color="gray-1000" weight="bold" size="md" className="lui-mb-5">
                {header}
            </Typography>
            <div className="lui-flex lui-flex-col lui-gap-2">
                {insights?.green &&
                    insights.green.map((text, i) => (
                        <div
                            className="lui-flex lui-flex-row lui-gap-2 lui-items-center"
                            key={`green-${i}`}
                        >
                            <Icon name="Check" color="primary-400" size="md" />
                            <Typography color="primary-500" weight="medium" size="sm">
                                {text}
                            </Typography>
                        </div>
                    ))}
                {insights?.orange &&
                    insights.orange.map((text, i) => (
                        <div
                            className="lui-flex lui-flex-row lui-gap-2 lui-items-center"
                            key={`orange-${i}`}
                        >
                            <Icon name="AlertTriangle" color="orange-400" size="md" />
                            <Typography color="orange-800" weight="medium" size="sm">
                                {text}
                            </Typography>
                        </div>
                    ))}
                {insights?.red &&
                    insights.red.map((text, i) => (
                        <div
                            className="lui-flex lui-flex-row lui-gap-2 lui-items-center"
                            key={`red-${i}`}
                        >
                            <Icon name="Close" color="red-600" size="md" />
                            <Typography color="red-700" weight="medium" size="sm">
                                {text}
                            </Typography>
                        </div>
                    ))}
            </div>
        </CompingSection>
    );
}
