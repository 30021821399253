import { Typography } from "@src/land_ui/typography/typography";
import { useReportsAutoSelectCompsRetrieve } from "@src/orval/gen/api";
import { formatCurrency, PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import { ButtonIcon } from "@src/land_ui/button/button_icon";
import { LoadingSpinner } from "@src/land_ui/loading/loading";
import { TutorialButton } from "@src/land_ui/tutorial_button/tutorial_button";
import { Button } from "@src/land_ui/button/button";
import { useState } from "react";
import { OfferAndProfitTable } from "./tables/offer_and_profit";

interface CompingEstimatedPriceProps {
    reportID: string;
}

export function CompingEstimatedPrice({ reportID }: CompingEstimatedPriceProps) {
    const { data, error, isLoading } = useReportsAutoSelectCompsRetrieve(reportID);
    const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

    const errorMessage = (error && (error as any).response?.data?.error) || "";

    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-between">
                <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center">
                    <Typography color="gray-1000" size="lg" weight="bold">
                        Land Insights Comp Value{" "}
                        <Typography
                            variant="span"
                            color="gray-900"
                            size="lg"
                            weight="bold"
                        >
                            (Beta)
                        </Typography>
                    </Typography>
                    <ButtonIcon
                        icon="InfoCircle"
                        color="gray-600"
                        size="sm"
                        tooltipLabel="The predicted market value of the property based on Land Insight’s internal algorithm. Do not rely on this value alone to make a final purchase decision."
                    />
                </div>
                <TutorialButton youtubeId="ivzDkVTGBLA" />
            </div>

            <div className="lui-flex lui-flex-col lui-items-center lui-justify-center lui-w-full lui-gap-2 lui-rounded-xl lui-border lui-border-gray-100 lui-border-solid lui-p-3">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <Typography color="red-700" size="lg" weight="bold">
                        Error loading estimated price
                        {errorMessage && `: ${errorMessage}`}
                    </Typography>
                ) : (
                    <>
                        <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-center">
                            <Typography
                                color="primary-500"
                                size="4xl"
                                weight="bold"
                                className="lui-tracking-wide"
                            >
                                {data?.mean_adjusted_price
                                    ? formatCurrency(data.mean_adjusted_price)
                                    : PLACEHOLDER_LABEL}
                            </Typography>
                            <ButtonIcon
                                icon="InfoCircle"
                                size="sm"
                                color="gray-600"
                                tooltipLabel="The predicted market value of the property based on Land Insight's internal algorithm. Do not rely on this value alone to make a final purchase decision."
                            />
                        </div>
                        <div className="lui-flex lui-flex-row lui-gap-2 lui-items-center lui-justify-center">
                            <Typography color="gray-1000" size="md" weight="medium">
                                {`${formatCurrency(data?.mean_adjusted_ppa)} / ac`}
                            </Typography>
                            <ButtonIcon
                                icon="InfoCircle"
                                size="sm"
                                color="gray-600"
                                tooltipLabel="The predicted price per acre of the property based on Land Insight's internal algorithm. Do not rely on this value alone to make a final purchase decision."
                            />
                        </div>
                        {!isCalculatorOpen && (
                            <Button
                                variant="inline"
                                icon="Calculator"
                                onClick={() => setIsCalculatorOpen(true)}
                            >
                                Show Offer & Profit Calculator
                            </Button>
                        )}
                    </>
                )}
            </div>

            {isCalculatorOpen && (
                <OfferAndProfitTable
                    price={data?.mean_adjusted_price}
                    title="Automatic Offer and Profit"
                    subTitle="Use this calculator to determine an offer price and the potential of the deal based on the Land Insight's Comp Value. You can copy any of the values by clicking on the green button to the right of the value."
                />
            )}
        </div>
    );
}
