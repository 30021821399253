import React, { forwardRef, memo } from "react";
import clsx from "clsx";
import { Typography } from "@src/land_ui/typography/typography";
import { Icon, type IconNameType } from "@src/land_ui/icon/icon";
import { ButtonIcon } from "@src/land_ui/button/button_icon";

import "./input.css";

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
    label?: React.ReactNode;
    srLabel?: string; // Used for screen readers to describe the input
    tooltip?: string;
    info?: React.ReactNode;
    placeholder?: string;
    error?: React.ReactNode;
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
    step?: React.InputHTMLAttributes<HTMLInputElement>["step"];
    icon?: IconNameType;
    action?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onEnter?: (value: string) => void;
    autoSelectionOnFocus?: boolean;
    size?: "sm" | "md";
}

export const Input = memo(
    forwardRef<HTMLInputElement, InputProps>(
        (
            {
                label,
                srLabel,
                tooltip,
                info,
                icon,
                placeholder,
                error,
                type = "text",
                action,
                endAdornment,
                onEnter,
                autoSelectionOnFocus,
                size = "md",
                ...props
            },
            ref,
        ) => {
            const { className, ...rest } = props;

            return (
                <span className="lui-input__container">
                    {(label || action || srLabel) && (
                        <span className="lui-flex lui-justify-between">
                            {label && (
                                <div className="lui-flex lui-gap-2">
                                    <Typography variant="label" weight="medium">
                                        {label}
                                    </Typography>
                                    {tooltip && (
                                        <ButtonIcon
                                            icon="InfoCircle"
                                            size="sm"
                                            color="gray-600"
                                            tooltipLabel={tooltip}
                                        />
                                    )}
                                </div>
                            )}

                            {action && (
                                <Typography variant="span" color="primary-500">
                                    {action}
                                </Typography>
                            )}

                            {srLabel && (
                                // Hack to align the input fields &nbsp; but also announce the label to screen readers
                                <span>
                                    &nbsp;
                                    <span className="lui-sr-only">{srLabel}</span>
                                </span>
                            )}
                        </span>
                    )}

                    <span
                        className={clsx(
                            "lui-px-4 lui-input__border lui-rounded-[100px]",
                            {
                                "lui-pl-11": icon && size === "md",
                                "lui-pl-7": icon && size === "sm",
                                "lui-border-red-700": error,
                                "lui-border-gray-300 focus-within:lui-border-gray-700 hover:lui-border-gray-700":
                                    !error,
                                "lui-py-3": size === "md",
                                "lui-py-2": size === "sm",
                            },
                        )}
                    >
                        {icon && (
                            <Icon
                                name={icon}
                                className="lui-absolute lui-left-2"
                                color="gray-900"
                                size={size}
                            />
                        )}
                        <input
                            ref={ref}
                            className={clsx("lui-input__input-field", className)}
                            type={type}
                            placeholder={placeholder}
                            autoComplete="off"
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && onEnter) {
                                    e.preventDefault();
                                    onEnter(e.currentTarget.value);
                                }
                            }}
                            onFocus={(e) => {
                                if (autoSelectionOnFocus) {
                                    setTimeout(() => {
                                        e.target.select();
                                    }, 1);
                                }
                            }}
                            {...rest}
                        />
                        {endAdornment}
                    </span>
                    {(Boolean(error) || Boolean(info)) && (
                        <span>
                            {error && (
                                <Typography
                                    size={size === "md" ? "sm" : "xs"}
                                    variant="span"
                                    color="red-700"
                                    className="lui-flex lui-gap-1.5 lui-items-center"
                                    weight="medium"
                                >
                                    <Icon
                                        name="Announcement"
                                        color="inherit"
                                        size="sm"
                                        className="lui-shrink-0 lui-self-start"
                                    />
                                    {error}
                                </Typography>
                            )}
                            {/* Hide the extra info if there is an error message */}
                            {info && !error && (
                                <Typography
                                    size="sm"
                                    variant="span"
                                    color="gray-700"
                                    className="lui-flex lui-gap-1.5 lui-items-center"
                                >
                                    {info}
                                </Typography>
                            )}
                        </span>
                    )}
                </span>
            );
        },
    ),
);
