import { type Control, Controller, type FieldValues } from "react-hook-form";
import { Icon } from "@src/land_ui/icon/icon";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";
import { Typography } from "@src/land_ui/typography/typography";
import clsx from "clsx";
import { useCallback } from "react";

interface Option {
    id: number | string;
    title: string;
    subtitle?: React.ReactNode;
    info?: string;
    children?: React.ReactNode;
}

interface SelectionGroupProps {
    options: Option[];
    selected?: number | string;
    onSelect?: (id: number | string, selectedOption: Option) => void;
    horizontal?: boolean;
    label?: React.ReactNode;
}

export function SelectionGroup({
    options = [],
    selected,
    onSelect,
    horizontal = false,
    label,
}: SelectionGroupProps) {
    const handleClick = useCallback(
        (item: Option) => {
            if (selected === item.id) {
                onSelect(null, null);
            } else {
                onSelect(item.id, item);
            }
        },
        [selected, onSelect],
    );

    return (
        <div>
            {label && <Typography weight="medium">{label}</Typography>}

            <div
                className={clsx("lui-flex lui-gap-3", {
                    "lui-flex-col": !horizontal,
                    "lui-mt-2": Boolean(label),
                })}
            >
                {options.map((option) => {
                    const isSelected = selected === option.id;

                    return (
                        <div
                            key={option.id}
                            className={clsx(
                                `lui-group  lui-rounded-full lui-border lui-border-solid lui-border-gray-300 hover:lui-bg-gray-50 lui-transition-colors lui-duration-300`,
                                "focus-visible:lui-outline-none focus:lui-outline-none",
                                {
                                    "lui-border-2 lui-border-primary-500 lui-bg-primary-50 hover:lui-bg-primary-50":
                                        isSelected,
                                    "lui-bg-white": !isSelected,
                                    "lui-w-full": !horizontal,
                                    "": !isSelected && !option.children,
                                    "lui-rounded-xl": option.children && isSelected,
                                },
                            )}
                        >
                            <button
                                type="button"
                                className="lui-w-full lui-bg-transparent lui-cursor-pointer lui-border-none"
                                onClick={() => handleClick(option)}
                            >
                                <span className="lui-flex lui-gap-4 lui-justify-between lui-items-center lui-px-5 lui-py-2 lui-text-start focus-visible:lui-outline-none focus:lui-outline-none">
                                    <span className="lui-flex lui-flex-col">
                                        <Typography
                                            variant="span"
                                            weight="medium"
                                            size="md"
                                            color={
                                                isSelected ? "primary-500" : "gray-700"
                                            }
                                        >
                                            {option.title}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            size="xs"
                                            color="gray-700"
                                        >
                                            {option.subtitle}
                                        </Typography>
                                    </span>
                                    {Boolean(option.info) && (
                                        <span
                                            className={clsx(
                                                `lui-flex lui-items-center lui-text-gray-400 group-hover:lui-text-gray-700`,
                                                {
                                                    "lui-text-primary-200 group-hover:lui-text-primary-200":
                                                        isSelected,
                                                },
                                            )}
                                        >
                                            <Tooltip
                                                trigger={
                                                    <Icon
                                                        name="InfoCircle"
                                                        size="md"
                                                        color="inherit"
                                                    />
                                                }
                                                content={option.info}
                                                showArrow
                                            />
                                        </span>
                                    )}
                                </span>
                            </button>
                            {option.children && isSelected && (
                                <div className="lui-border-t lui-rounded-b-inherit lui-border-b-0 lui-border-r-0 lui-border-l-0 lui-border-solid lui-border-primary-100 lui-py-3 lui-px-5 lui-bg-primary-0">
                                    {option.children}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

interface SelectionGroupFormControllerProps extends SelectionGroupProps {
    control: Control<FieldValues, any>;
    name: string;
}

export function SelectionGroupFormController({
    control,
    name,
    ...props
}: SelectionGroupFormControllerProps) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
                <SelectionGroup
                    {...props}
                    selected={value}
                    onSelect={(selectedItemId) => {
                        onChange(selectedItemId);
                    }}
                />
            )}
        />
    );
}
