import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import "./css/map.css";
import "./css/base.css";
import "./css/kanban.css";
import "./css/parcel_viewer.css";

import { SENTRY_DSN } from "settings";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";
import "./land_ui/color/color.css";
import type { PostHogConfig } from "posthog-js";

// Load external libraries
import "./external_libraries";

Sentry.init({
    dsn: SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture all transactions for performance monitoring.
    // Currently set to 0 to disable performance monitoring.
    tracesSampleRate: 0,
});

// Load routes into browser
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);
const queryClient = new QueryClient();
const posthogOptions: Partial<PostHogConfig> = {
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_API_HOST,
    ui_host: "https://us.posthog.com",
    person_profiles: "identified_only",
    session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: true,
        },
    },
};

const PostHogProviderWrapper = ({ children }: { children: React.ReactNode }) =>
    import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY ? (
        <PostHogProvider
            apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions}
        >
            {children}
        </PostHogProvider>
    ) : (
        <>{children}</>
    );
// Initializing React DOM management
ReactDOM.createRoot(document.getElementById("root")).render(
    <PostHogProviderWrapper>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    </PostHogProviderWrapper>,
);
